import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CommonCollections } from '../../App';

const SecretaryNominationCell = ({ row }) => {
  const { setNominationId } = useContext(CommonCollections);
  const { nominationStatusTypeId, nominationId, candidate } = row.original;

  const handleClick = () => {
    setNominationId(nominationId);
  };
  return (
    nominationStatusTypeId !== 1 && (
      <Link to="/form-view" onClick={handleClick}>
        <span title={`View ${candidate}`}>
          <i className="bi bi-eye view-button" />
        </span>
      </Link>
    )
  );
};

SecretaryNominationCell.propTypes = {
  row: PropTypes.object,
};

export default SecretaryNominationCell;
