import React, { useContext, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { ThemeContext } from '../../context/ThemeContext';
import AdminUserSelect from './AdminUserSelect';
import PreNominationSteps from './PreNominationSteps';

const Settings = () => {
  const { background, changeBackground } = useContext(ThemeContext);

  const [selectedOption, setSelectedOption] = useState(background.label);
  const [notification, setNotification] = useState('');

  const handleSave = () => {
    changeBackground({
      value: selectedOption.toLowerCase(),
      label: selectedOption,
    });
  };

  const handleThemeChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const setNotificationMessage = (notificationMessage) => {
    setNotification(notificationMessage);
  };
  return (
    <>
      <PageTitle
        motherMenu="Settings"
        activeMenu="Settings"
        notificationMessage={notification}
      />
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Theme switcher</h5>
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="themeSelect">Select Theme:</label>
                <select
                  className="form-control"
                  id="themeSelect"
                  value={selectedOption}
                  onChange={handleThemeChange}
                >
                  <option value="Dark">Dark</option>
                  <option value="Light">Light</option>
                </select>
              </div>
              <div className="row justify-content-end">
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-success mt-3"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AdminUserSelect
        setNotificationMessage={setNotificationMessage}
        type="super-admins"
      />
      <AdminUserSelect
        setNotificationMessage={setNotificationMessage}
        type="admins"
      />
      <PreNominationSteps />
    </>
  );
};

export default Settings;
