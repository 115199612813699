import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';

import CustomIcon from '../Elements/CustomIcon';
import Label from '../Elements/Label';
import Span from '../Elements/Span';
import Markdown from '../Elements/Markdown';
import InputNumber from '../Elements/InputNumber';

const PhdAwardedYear = ({
  item,
  classNameMarginTop,
  divClassName,
  isSubmitted,
}) => {
  const { watch, errors } = useContext(Context);

  return (
    <div className={`${divClassName} ${classNameMarginTop} phd-awarded-year`}>
      <div className="form-group">
        <Label label={item.label} name={item.name} required={item.required} />

        <CustomIcon
          isSubmitted={isSubmitted}
          fieldError={errors[item.name]}
          required={item.required}
        />

        <InputNumber name={item.name} disabled={watch('noPhd') === true} />

        <Span
          text={
            errors[item.name] && watch('noPhd') !== true
              ? errors[item.name].message
              : 'No error'
          }
        />

        <Markdown
          className="col-xl-12 col-lg-12 mt-3"
          text={item.description}
        />
      </div>
    </div>
  );
};

PhdAwardedYear.propTypes = {
  item: PropTypes.object,
  classNameMarginTop: PropTypes.string,
  divClassName: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default PhdAwardedYear;
