import React, { useContext, useEffect, useState } from 'react';

import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';

import { tableColumnNamesNominationAdministration } from '../../utils/tableColumnNamesNominationAdministration';
import { tableRowsValuesNominationAdministration } from '../../utils/tableRowsValuesNominationAdministration';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';
import SecretaryNominationCell from './SecretaryNominationCell';

import { CommonCollections } from '../../App';

import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { filteringTableFilterUniqueCandidates } from '../../utils/filteringTableFilterUniqueCandidates';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { getNominationRoundYear } from '../../utils/getNominationRoundYear';
import { useAdminActionCollections } from '../../context/AdminActionCollections';

const SecretaryNomination = () => {
  const {
    electionTypes,
    fellows,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    usersArray,
  } = useStaticCollections();
  const { disableNewSubmission } = useAdminActionCollections();

  const { candidates, nominationArray, nominationState, proposerSeconder } =
    useAdminCollections();

  const { setNominationId } = useContext(CommonCollections);

  const [nominationsData, setNominationsData] = useState([]);
  const [nominationsColumns, setNominationsColumns] = useState([]);
  const [urlString, setUrlString] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('formNomination') !== undefined) {
      sessionStorage.removeItem('formNomination');
    }

    const tableColumns = filteringTableGetTableColumns(
      tableColumnNamesNominationAdministration,
      ColumnFilter,
      SecretaryNominationCell,
    );

    const proposers = proposerSeconder.map((item) => ({
      [item.attributes.nominationId]: item.attributes.proposerFellowId,
    }));

    const proposerSeconderObj = arrayToFlatObject(proposers);
    const currentYear = getNominationRoundYear();

    const filteredNomination = nominationArray.filter((item) => {
      const publishedAtDate = item.attributes.publishedAt.slice(0, 10);
      const publishedAt = new Date(publishedAtDate);
      const publishedRoundYear = getNominationRoundYear(publishedAt);

      return (
        (item.attributes.nominationStatusTypeId === 2 ||
          item.attributes.nominationStatusTypeId === 3) &&
        publishedRoundYear === currentYear
      );
    });

    const tableData = tableRowsValuesNominationAdministration(
      filteredNomination,
      proposerSeconderObj,
      electionTypes,
      nominationStatusTypes,
      sectionalCommitteesWithCmse,
      fellows,
      nominationState,
      usersArray,
    );

    setNominationsColumns(tableColumns);

    const tableDataTwo = Object.values(tableData).map((nomination) => {
      if (candidates[nomination.candidateId] !== undefined) {
        nomination.candidate = candidates[nomination.candidateId];
      }

      return nomination;
    });

    const filteredData = filteringTableFilterUniqueCandidates(tableDataTwo);

    setNominationsData(filteredData);
    setNominationId(null);
    if (disableNewSubmission === false) {
      setUrlString('application-administration');
    } else {
      setUrlString('');
    }
  }, [
    electionTypes,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    fellows,
    candidates,
    proposerSeconder,
    nominationArray,
    nominationState,
    setNominationId,
    usersArray,
    disableNewSubmission,
  ]);

  return (
    <>
      <PageTitle activeMenu="Nomination" motherMenu="Home" />
      <FilteringTable
        componentName="AdminNomination"
        columns={nominationsColumns}
        data={nominationsData}
        urlString={urlString}
        buttonText="New Nomination"
      />
    </>
  );
};

export default SecretaryNomination;
