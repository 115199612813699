import React from 'react';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const ChartPie = ({
  colorOne = 'rgba(153, 218, 255, 0.7)',
  colorTwo = 'rgba(0, 162, 255, 1)',
  colorThree = 'rgba(258, 128, 25,0.5)',
  colorFour = 'rgba(0, 0, 0, 0.07)',
  pieData,
  height = 300,
  labels,
  legend = true,
}) => {
  const processedLabels = labels.map((item) => `${item}`);
  const data = {
    datasets: [
      {
        data: pieData,
        borderWidth: 0,
        backgroundColor: [colorOne, colorTwo, colorThree, colorFour],
        hoverBackgroundColor: [colorOne, colorTwo, colorThree, colorFour],
      },
    ],
    labels: processedLabels,
  };

  const options = {
    plugins: {
      legend,
      responsive: true,
      tooltip: {
        enabled: true,
        callbacks: {
          footer: (ttItem) => {
            let sum = 0;
            const dataArr = ttItem[0].dataset.data;
            dataArr.map((item) => (sum += Number(item)));
            const percentageNumber = Math.round((ttItem[0].parsed * 100) / sum);
            const percentage = `${percentageNumber}%`;
            return `Total: ${percentage}`;
          },
        },
      },
    },

    maintainAspectRatio: false,
  };

  return <Pie data={data} height={height} options={options} />;
};

ChartPie.propTypes = {
  colorOne: PropTypes.array,
  colorTwo: PropTypes.string,
  colorThree: PropTypes.string,
  colorFour: PropTypes.string,
  height: PropTypes.string,
  pieData: PropTypes.array,
  labels: PropTypes.array,
  legend: PropTypes.bool,
};

export default ChartPie;
