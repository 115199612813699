import React, { useContext, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';

const CountrySelector = ({ name }) => {
  const { setValue, watch, control } = useContext(Context);

  const options = useMemo(() => countryList().getData(), []);
  const handleChange = (event, fieldName) => {
    const eventTargetName =
      event.target !== undefined ? event.target.name : fieldName;

    const eventTargetValue =
      event.target !== undefined ? parseInt(event.target.value) : event;

    setValue(eventTargetName, eventTargetValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          options={options}
          onChange={(e) => {
            field.onChange(e);
            // custom change event
            setValue(name, e, { shouldValidate: true });
            handleChange(e, name);
          }}
          value={watch(name)}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              height: '3rem',
              borderRadius: '0.5rem',
              borderColor: '#B3B3B3',
            }),
          }}
          className={`mb-3 ${name}`}
        />
      )}
    />
  );
};

CountrySelector.propTypes = {
  name: PropTypes.string,
};

export default CountrySelector;
