// eslint-disable-next-line no-unused-vars
import React from 'react';

import {
  formatError,
  login,
  sendMail,
  runLogoutTimer,
  saveUserDetails,
  signUp,
} from '../../services/AuthService';
import { axiosGet } from '../../utils/axiosGet';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveUserDetails(response.data);
        runLogoutTimer(dispatch, response.data.expiresIn * 1000);
        dispatch(confirmedSignupAction(response.data));
        navigate('/nominations');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate, refreshUserDetails, setRefreshUserDetails) {
  axiosGet('jwt-remove-cookie').then((response) => {
    localStorage.clear();
    sessionStorage.clear();
    if (typeof setRefreshUserDetails === 'function') {
      setRefreshUserDetails(!refreshUserDetails);
    }
  });

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, setSentStatus) {
  return () => {
    sendMail(email);
    setSentStatus(true);
  };
}

export function loginWithToken(
  loginToken,
  navigate,
  refreshUserDetails,
  setRefreshUserDetails,
) {
  return (dispatch) => {
    login(loginToken)
      .then((response) => {
        dispatch(loginConfirmedAction(response.data));
        setRefreshUserDetails(!refreshUserDetails);
        navigate('/nominations');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
        navigate('/login');
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
