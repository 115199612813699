import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';
import Markdown from './Markdown';

const DescriptionSet = ({ descriptionSet = [] }) => {
  const { watch } = useContext(Context);

  const electionTypeId = parseInt(watch('electionTypeId'));

  if (
    electionTypeId &&
    Array.isArray(descriptionSet) &&
    descriptionSet.length > 0
  ) {
    return (
      <Markdown
        className="col-xl-12 col-lg-12 mt-3"
        text={descriptionSet[electionTypeId - 1]?.description}
      />
    );
  }

  return '';
};

DescriptionSet.propTypes = {
  descriptionSet: PropTypes.array,
};

export default DescriptionSet;
