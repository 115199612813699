import React, { useContext, useEffect, useMemo, useState } from 'react';

import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';

import { tableColumnNamesNomination } from '../../utils/tableColumnNamesNomination';
import { tableRowsValuesNomination } from '../../utils/tableRowsValuesNomination';
import { CommonCollections } from '../../App';
import { useStaticCollections } from '../../context/StaticCollections';
import NominationCell from './NominationCell';

import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { filteringTableFilterUniqueCandidates } from '../../utils/filteringTableFilterUniqueCandidates';
import { useProposerCollections } from '../../context/ProposerCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { useAdminActionCollections } from '../../context/AdminActionCollections';

const Nomination = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const {
    electionTypes,
    nonAdminNominationStatusTypes,
    sectionalCommitteesWithCmse,
  } = useStaticCollections();
  const { disableNewSubmission } = useAdminActionCollections();

  const { candidates, userDraftNominationArray, userNominationArray } =
    useProposerCollections();

  const { refreshDynamicCollections, setNominationId } =
    useContext(CommonCollections);

  const updatedUserDraftNominationArray = useMemo(
    () =>
      userDraftNominationArray.map((item) => ({
        ...item,
        id: `${item.id}d`,
      })),
    [userDraftNominationArray],
  );

  const [nominationsData, setNominationsData] = useState([]);
  const [nominationsColumns, setNominationsColumns] = useState([]);
  const [urlString, setUrlString] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem('formNomination') !== undefined) {
      sessionStorage.removeItem('formNomination');
    }

    if (sessionStorage.getItem('draftId') !== undefined) {
      sessionStorage.removeItem('draftId');
    }

    const tableColumns = filteringTableGetTableColumns(
      tableColumnNamesNomination,
      ColumnFilter,
      NominationCell,
    );

    const mergedData = [
      ...userNominationArray,
      ...updatedUserDraftNominationArray,
    ];

    const tableData = tableRowsValuesNomination(
      mergedData,
      electionTypes,
      nonAdminNominationStatusTypes,
      sectionalCommitteesWithCmse,
    );

    setNominationsColumns(tableColumns);

    const candidatesData = [
      tableData.map((item) => item.candidateId),
      tableData,
    ];

    const tableDataTwo = Object.values(candidatesData[1]).map((candidate) => {
      if (candidates[candidate.candidateId] !== undefined) {
        candidate.candidate = candidates[candidate.candidateId];
      }

      return candidate;
    });

    const filteredData = filteringTableFilterUniqueCandidates(tableDataTwo);

    setNominationsData(filteredData);
    setNominationId(null);

    if (disableNewSubmission === false) {
      setUrlString('application');
    } else {
      setUrlString('');
    }
  }, [
    userDetails,
    electionTypes,
    nonAdminNominationStatusTypes,
    sectionalCommitteesWithCmse,

    candidates,
    userDraftNominationArray,
    updatedUserDraftNominationArray,
    userNominationArray,

    refreshDynamicCollections,
    setNominationId,
    disableNewSubmission,
  ]);

  return (
    <>
      <PageTitle activeMenu="Nomination" motherMenu="Home" />
      <FilteringTable
        componentName="Nomination"
        columns={nominationsColumns}
        data={nominationsData}
        urlString={urlString}
        buttonText="New Nomination"
      />
    </>
  );
};

export default Nomination;
