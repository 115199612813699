import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ textContent = '' }) => {
  if (textContent !== null) {
    return <p>{textContent}</p>;
  }

  return '';
};

Paragraph.propTypes = {
  textContent: PropTypes.string,
};

export default Paragraph;
