import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';

const InputText = ({ name, disabled = false }) => {
  const { register } = useContext(Context);

  return (
    <input
      name={name}
      type="text"
      className="form-control mb-3"
      {...register(name)}
      disabled={disabled}
    />
  );
};

InputText.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.string,
};

export default InputText;
