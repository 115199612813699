import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Label from '../Elements/Label';
import CustomIcon from '../Elements/CustomIcon';
import Select from '../Elements/Select';
import InputText from '../Elements/InputText';
import Span from '../Elements/Span';
import Markdown from '../Elements/Markdown';

import { Context } from '../Wizard/WizardWrapper';

const Organisations = ({ item }) => {
  const { watch, errors, isSubmitted } = useContext(Context);

  return (
    <React.Fragment key={`organisation-select-${item}`}>
      <div className="col-lg-6 mt-5">
        <div className="form-group">
          <Label
            className="mb-3"
            label={item.label}
            name={item.name}
            errors={errors}
            required={item.required}
          />
          <CustomIcon
            isSubmitted={isSubmitted}
            fieldError={errors[item.name]}
            required={item.required}
          />
          <Select options="organisations" name={item.name} />
          <Markdown
            className="col-xl-12 col-lg-12 mt-3"
            text={item.description ?? ''}
          />
          {typeof watch(item.name) === 'object' &&
            watch(item.name)?.value === 0 && (
              <>
                <InputText name="otherOrganisation" />
                <Span
                  text={
                    errors.otherOrganisation
                      ? errors.otherOrganisation.message
                      : ''
                  }
                />
              </>
            )}
          <Span text={errors[item.name] ? errors[item.name].message : ''} />
        </div>
      </div>
    </React.Fragment>
  );
};

Organisations.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Organisations;
