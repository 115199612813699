import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';

const InputCheckbox = ({ name }) => {
  const { register } = useContext(Context);

  return (
    <input
      name={name}
      type="checkbox"
      className="form-check-input"
      {...register(name)}
    />
  );
};

InputCheckbox.propTypes = {
  name: PropTypes.string,
};

export default InputCheckbox;
