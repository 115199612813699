import { formatDateDDMMYYYY } from './formatDateDDMMYYYY';

export const tableRowsValuesRefereeAdministration = (
  resultData,
  refereeStatuses,
  declineReasons,
) =>
  resultData.map((item) => {
    const formattedDate =
      formatDateDDMMYYYY(item.attributes.requestEmailDate) === '01/01/1824'
        ? 'Pending approval'
        : formatDateDDMMYYYY(item.attributes.requestEmailDate);

    return {
      id: item.id,
      nominationId: item.attributes.nominationId,
      refereeStatus: refereeStatuses[item.attributes.refereeStatusId],
      refereeStatusId: item.attributes.refereeStatusId,
      independentAssessor: item.attributes.independentAssessor
        ? 'Independent Assessor'
        : 'Referee',
      declineReason: declineReasons[item.attributes.declineReasonId],
      refereeUserId: item.attributes.refereeUserId,
      refereeFellowId: item.attributes.refereeFellowId,
      nonAasRefereeId: item.attributes.nonAasRefereeId,
      requestExtension: item.attributes.requestExtension,
      declineReasonId: item.attributes.declineReasonId,
      declineReasonOther: item.attributes.declineReasonOther,
      extensionReason: item.attributes.extensionReason,
      extensionReasonApprovalNote: item.attributes.extensionReasonApprovalNote,
      requestEmailDate: formattedDate,
      reminderEmailDate: formatDateDDMMYYYY(item.attributes.reminderEmailDate),
      refereeArchived: item.attributes.refereeArchived,
      refereeReportUploadedRound: item.attributes.refereeReportUploadedRound,
      createdAt: item.attributes.createdAt,
      updatedAt: item.attributes.updatedAt,
      publishedAt: item.attributes.publishedAt,
      report: item.attributes.report,
    };
  });
