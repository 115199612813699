import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkAutolinkHeadings from 'remark-autolink-headings';

import PropTypes from 'prop-types';
import rehypeRaw from 'rehype-raw';
import DOMpurify from 'dompurify';

const CustomAnchor = ({ href, children, ...props }) => (
  <button
    type="button"
    className="btn btn-outline-primary"
    onClick={() => window.open(href, '_blank')}
    {...props}
  >
    <i className="bi bi-link" /> {children}
  </button>
);

const Markdown = ({ text, className }) => {
  if (text !== null) {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkAutolinkHeadings]}
        components={{
          a: CustomAnchor,
        }}
        rehypePlugins={[rehypeRaw]}
        className={className}
        linkTarget="_blank"
      >
        {DOMpurify.sanitize(text)}
      </ReactMarkdown>
    );
  }

  return '';
};

CustomAnchor.propTypes = {
  node: PropTypes.object.isRequired,
  children: PropTypes.node,
  href: PropTypes.string,
};

Markdown.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Markdown;
