export const formatDateDDMMYYYY = (dateString) => {
  if (dateString && dateString.trim().length > 1) {
    const date = new Date(dateString);
    if (Number.isNaN(date)) return '';

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return null;
};
