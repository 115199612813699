const isEmptyObject = (obj) => {
  if (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
};

export const objectGetNonEmptyCount = (formObject) =>
  Object.values(formObject).filter(
    (item) =>
      (typeof item !== 'string' || item.trim() !== '') && !isEmptyObject(item),
  ).length;
