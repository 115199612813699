export const tableColumnNamesNominationAdministration = {
  'Nomination Id': 'nominationId',
  Candidate: 'candidate',
  Proposer: 'proposerName',
  'Election Type': 'electionTypeId',
  Round: 'roundYear',
  'Last Considered': 'lastConsidered',
  Status: 'nominationStatusType',
  nominationStatusTypeId: 'nominationStatusTypeId',
  'Sectional Committee': 'sectionalCommitteeId',
  Actions: 'actions',

  'Created at': 'createdAt',
  'Updated at': 'updatedAt',
  'Published at': 'publishedAt',
};
