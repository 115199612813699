import * as yup from 'yup';
import { trimString } from '../../../../utils/trimString';

const currentDate = new Date();
const phdAwardedYearMaxValue = currentDate.getFullYear();
const pdfFileInputMessage = 'PDF files of up to 2mb are permitted';
const pdfFileSize = 2000000;
const minimumYear = 1960;
const descriptorErrorMessage =
  'Please choose at least one discipline descriptor';
const interdisciplinaryErrorMessage =
  'Please choose at least two sectional committees';
const defaultMessage = 'Required Field';
const notAPdfFile = 'Not a pdf. Please upload a valid file.';

yup.setLocale({
  mixed: {
    required: defaultMessage,
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Must be at least ${min} characters',
    email: 'Invalid email',
  },
  number: {
    integer: 'Must be an integer',
  },
});

const schema = yup.object().shape({
  // STEP ONE
  electionTypeId: yup.number().required().typeError(defaultMessage),
  titleId: yup.number().required().typeError(defaultMessage),

  name: yup.string().transform(trimString).min(2).required(),
  lastName: yup.string().transform(trimString).min(2).required(),

  phone: yup
    .string()
    .transform((value) =>
      typeof value === 'string' ? value.replace(/\s/g, '') : value,
    )
    .matches(
      /^\+?[0-9]{8,}$/,
      'Must be at least 8 digits and only valid phone numbers',
    )
    .required('Phone number is required'),
  address: yup.string().transform(trimString).min(10).required(),

  email: yup.string().email().required(),
  stateTerritoryId: yup.number().when('electionTypeId', {
    is: (value) =>
      value && typeof value === 'number' && (value === 2 || value === 3),
    then: yup.number().required().nullable().typeError(defaultMessage),
    otherwise: yup
      .number()
      .notRequired()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),

  genderId: yup.number().required().typeError(defaultMessage),
  otherGender: yup.string().when('genderId', {
    is: (value) => value && typeof value === 'number' && value === 1,
    then: yup
      .string()
      .transform(trimString)
      .min(2)
      .required()
      .nullable()
      .typeError(defaultMessage),
    otherwise: yup.string().transform(trimString).notRequired().nullable(),
  }),
  dob: yup.date().required().typeError(defaultMessage),

  countryOfBirthId: yup.object().required().nullable(),

  australianStatusId: yup.number().when('electionTypeId', {
    is: (val) => val === 2 || val === 3,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup.number().notRequired().nullable().typeError(defaultMessage),
  }),
  arrivalDate: yup.date().when('australianStatusId', {
    is: 3,
    then: yup.date().required().typeError(defaultMessage),
    otherwise: yup.date().notRequired(),
  }),
  nationalityId: yup.array().when('electionTypeId', {
    is: 1,
    then: yup.array().required().typeError(defaultMessage),
    otherwise: yup.array().notRequired().nullable(),
  }),

  postNominal: yup.string().transform(trimString).required(),

  jobTitle: yup.string().transform(trimString).required(),
  organisationId: yup.object().required().nullable(),
  otherOrganisation: yup.string().when('organisationId', {
    is: (value) => value && typeof value === 'object' && value.value === 0,
    then: yup
      .string()
      .transform(trimString)
      .min(2)
      .required()
      .nullable()
      .typeError(defaultMessage),
    otherwise: yup.string().transform(trimString).notRequired().nullable(),
  }),

  phdAwardedYear: yup
    .number()
    .integer()
    .when('noPhd', {
      is: (noPhd) => noPhd === undefined || noPhd === false,
      then: yup
        .number()
        .integer()
        .min(minimumYear, `Year must be greater than ${minimumYear}`)
        .max(
          phdAwardedYearMaxValue,
          `Year must be less than ${phdAwardedYearMaxValue}`,
        )
        .required()
        .typeError('Must be a valid year'),
      otherwise: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
    }),

  diverseBackground: yup.bool().required().typeError(defaultMessage),

  nominationConsent: yup.mixed().when('nominationConsentFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),
  retractedPapers: yup.bool(),
  correctedPapers: yup.bool(),
  publicationConcerns: yup.bool(),

  disclosureDeclaration: yup.mixed().when('disclosureDeclarationFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  // STEP TWO
  careerInterruption: yup.bool().required(),
  scientificExcellence: yup.string().transform(trimString).max(1500).required(),

  // STEP THREE
  citation: yup.string().transform(trimString).min(20).required(),

  // STEP FOUR
  sectionalCommitteeId: yup
    .mixed()
    .transform((value) => (value === 'none' ? null : Number(value))) // Transform "none" to null and cast other values to numbers
    .when('electionTypeId', {
      is: 2,
      then: yup.number().min(1).required().typeError(defaultMessage),
      otherwise: yup.number().notRequired().nullable(),
    }),

  disciplineDescriptor: yup
    .array()
    .when(['electionTypeId', 'sectionalCommitteeId'], {
      is: (electionTypeId, sectionalCommitteeId) =>
        electionTypeId !== 2 ||
        sectionalCommitteeId === 13 ||
        sectionalCommitteeId === null,
      then: yup.array().notRequired().nullable(),
      otherwise: yup.array().min(1, descriptorErrorMessage).required(),
    }),

  overlapCommittee: yup
    .mixed()
    .when(['electionTypeId', 'sectionalCommitteeId'], {
      is: (electionTypeId, sectionalCommitteeId) =>
        electionTypeId !== 2 ||
        sectionalCommitteeId === 13 ||
        sectionalCommitteeId === null,
      then: yup.string().notRequired(),
      otherwise: yup.bool().required().typeError(defaultMessage),
    }),

  overlapSectionalCommitteeId: yup.number().when('overlapCommittee', {
    is: true,
    then: yup.number().min(1).required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  overlapDisciplineDescriptor: yup.array().when('overlapCommittee', {
    is: true,
    then: yup.array().min(1, descriptorErrorMessage).required(),
    otherwise: yup.array().notRequired(),
  }),
  interdisciplinaryCommittee: yup.array().when('sectionalCommitteeId', {
    is: 13,
    then: yup.array().min(2, interdisciplinaryErrorMessage).required(),
    otherwise: yup.array().notRequired(),
  }),

  // STEP FIVE
  proposerFellowId: yup.object().required().nullable(),
  proposerSignature: yup.mixed().when('proposerSignatureFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  seconderFellowId: yup.object().required().nullable(),
  seconderSignature: yup.mixed().when('seconderSignatureFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  supporterOneTypeId: yup.number().required().typeError(defaultMessage),
  supporterOneFellowId: yup.object().required().nullable(),
  supporterOneSignature: yup.mixed().when('supporterOneSignatureFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  supporterTwoTypeId: yup.number().required().typeError(defaultMessage),
  supporterTwoFellowId: yup.object().required().nullable(),
  supporterTwoSignature: yup.mixed().when('supporterTwoSignatureFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  supporterThreeTypeId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  supporterThreeFellowId: yup.object().when('supporterThreeTypeId', {
    is: (val) => val && typeof val === 'number',
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),

  supporterThreeSignature: yup
    .mixed()
    .when(['supporterThreeTypeId', 'supporterThreeSignatureFileId'], {
      is: (supporterThreeTypeId, supporterThreeSignatureFileId) =>
        supporterThreeTypeId === undefined ||
        (supporterThreeSignatureFileId &&
          typeof supporterThreeSignatureFileId.id === 'number'),
      then: yup.mixed().notRequired().nullable(),
      otherwise: yup
        .mixed()
        .required(defaultMessage)
        .test(
          'is-valid-type',
          notAPdfFile,
          (file) => file && file[0] && file[0].type === 'application/pdf',
        )
        .test(
          'is-valid-size',
          pdfFileInputMessage,
          (file) =>
            file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
        ),
    })
    .when(['supporterThreeTypeId', 'supporterThreeSignatureFileId'], {
      is: (supporterThreeTypeId, supporterThreeSignatureFileId) =>
        supporterThreeTypeId &&
        typeof supporterThreeTypeId === 'number' &&
        supporterThreeSignatureFileId === undefined,
      then: yup
        .mixed()
        .required(defaultMessage)
        .test(
          'fileSize',
          pdfFileInputMessage,
          (file) =>
            file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
        ),
      otherwise: yup.mixed().notRequired(),
    }),

  supporterFourTypeId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  supporterFourFellowId: yup.object().when('supporterFourTypeId', {
    is: (val) => val && typeof val === 'number',
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  supporterFourSignature: yup
    .mixed()
    .when(['supporterFourTypeId', 'supporterFourSignatureFileId'], {
      is: (supporterFourTypeId, supporterFourSignatureFileId) =>
        supporterFourTypeId === undefined ||
        (supporterFourSignatureFileId &&
          typeof supporterFourSignatureFileId.id === 'number'),
      then: yup.mixed().notRequired().nullable(),
      otherwise: yup
        .mixed()
        .required(defaultMessage)
        .test(
          'is-valid-type',
          notAPdfFile,
          (file) => file && file[0] && file[0].type === 'application/pdf',
        )
        .test(
          'is-valid-size',
          pdfFileInputMessage,
          (file) =>
            file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
        ),
    })
    .when(['supporterFourTypeId', 'supporterFourSignatureFileId'], {
      is: (supporterFourTypeId, supporterFourSignatureFileId) =>
        supporterFourTypeId &&
        typeof supporterFourTypeId === 'number' &&
        supporterFourSignatureFileId === undefined,
      then: yup
        .mixed()
        .required(defaultMessage)
        .test(
          'fileSize',
          pdfFileInputMessage,
          (file) =>
            file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
        ),
      otherwise: yup.mixed().notRequired(),
    }),

  meetsConduct: yup.bool().required().typeError(defaultMessage),

  // STEP SIX
  extendedCitation: yup.mixed().when('extendedCitationFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  // STEP SEVEN
  curriculumVitae: yup.mixed().when('curriculumVitaeFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  // STEP EIGHT
  significantPublications: yup.mixed().when('significantPublicationsFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  // STEP NINE
  publications: yup.mixed().when('publicationsFileId', {
    is: (val) => val && typeof val.id === 'number',
    then: yup.object().notRequired().nullable(),
    otherwise: yup
      .mixed()
      .required(defaultMessage)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
  }),

  // STEP TEN
  refereeOne: yup.number().required().typeError(defaultMessage),
  refereeOneFellowId: yup.object().when('refereeOne', {
    is: (val) => val && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeOneId: yup.object().when('refereeOne', {
    is: (val) =>
      val !== null &&
      typeof val !== 'undefined' &&
      typeof val === 'number' &&
      val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeOneTitleId: yup.number().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeOneName: yup.string().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeOneLastName: yup.string().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeOneEmail: yup.string().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeOneCountryId: yup.object().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeOneOrganisation: yup
    .string()
    .when(['nonAasRefereeOneId', 'refereeOneEmailEducationalInstitution'], {
      is: (nonAasRefereeOneId, refereeOneEmailEducationalInstitution) =>
        nonAasRefereeOneId &&
        typeof nonAasRefereeOneId.value === 'number' &&
        nonAasRefereeOneId.value === 0 &&
        !refereeOneEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeOneMemberships: yup.string().when('nonAasRefereeOneId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  refereeTwo: yup.number().required().typeError(defaultMessage),
  refereeTwoFellowId: yup.object().when('refereeTwo', {
    is: (val) => val && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeTwoId: yup.object().when('refereeTwo', {
    is: (val) =>
      val !== null &&
      typeof val !== 'undefined' &&
      typeof val === 'number' &&
      val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeTwoTitleId: yup.number().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeTwoName: yup.string().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeTwoLastName: yup.string().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeTwoEmail: yup.string().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeTwoCountryId: yup.object().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeTwoOrganisation: yup
    .string()
    .when(['nonAasRefereeTwoId', 'refereeTwoEmailEducationalInstitution'], {
      is: (nonAasRefereeTwoId, refereeTwoEmailEducationalInstitution) =>
        nonAasRefereeTwoId &&
        typeof nonAasRefereeTwoId.value === 'number' &&
        nonAasRefereeTwoId.value === 0 &&
        !refereeTwoEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeTwoMemberships: yup.string().when('nonAasRefereeTwoId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  refereeThree: yup.number().required().typeError(defaultMessage),
  refereeThreeFellowId: yup.object().when('refereeThree', {
    is: (val) => val && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeThreeId: yup.object().when('refereeThree', {
    is: (val) =>
      val !== null &&
      typeof val !== 'undefined' &&
      typeof val === 'number' &&
      val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeThreeTitleId: yup.number().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeThreeName: yup.string().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeThreeLastName: yup.string().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeThreeEmail: yup.string().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeThreeCountryId: yup.object().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeThreeOrganisation: yup
    .string()
    .when(['nonAasRefereeThreeId', 'refereeThreeEmailEducationalInstitution'], {
      is: (nonAasRefereeThreeId, refereeThreeEmailEducationalInstitution) =>
        nonAasRefereeThreeId &&
        typeof nonAasRefereeThreeId.value === 'number' &&
        nonAasRefereeThreeId.value === 0 &&
        !refereeThreeEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeThreeMemberships: yup.string().when('nonAasRefereeThreeId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  refereeFour: yup.number().required().typeError(defaultMessage),
  refereeFourFellowId: yup.object().when('refereeFour', {
    is: (val) => val && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeFourId: yup.object().when('refereeFour', {
    is: (val) =>
      val !== null &&
      typeof val !== 'undefined' &&
      typeof val === 'number' &&
      val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeFourName: yup.string().when('nonAasRefereeFourId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeFourLastName: yup.string().when('nonAasRefereeFourId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeFourEmail: yup.string().when('nonAasRefereeFourId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeFourCountryId: yup.object().when('nonAasRefereeFourId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeFourOrganisation: yup
    .string()
    .when(['nonAasRefereeFourId', 'refereeFourEmailEducationalInstitution'], {
      is: (nonAasRefereeFourId, refereeFourEmailEducationalInstitution) =>
        nonAasRefereeFourId &&
        typeof nonAasRefereeFourId.value === 'number' &&
        nonAasRefereeFourId.value === 0 &&
        !refereeFourEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeFourMemberships: yup.string().when('nonAasRefereeFourId', {
    is: (item) => item && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  refereeFive: yup
    .mixed()
    .transform((value) => (value === 'none' ? null : Number(value)))
    .when('electionTypeId', {
      is: (val) => val && typeof val === 'number' && val === 2,
      then: yup.number().required().nullable(),
      otherwise: yup.number().notRequired().nullable(),
    }),

  refereeFiveFellowId: yup.object().when(['refereeFive', 'electionTypeId'], {
    is: (refereeFive, electionTypeId) =>
      refereeFive &&
      typeof refereeFive === 'number' &&
      refereeFive === 1 &&
      electionTypeId &&
      typeof electionTypeId === 'number' &&
      electionTypeId === 2,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeFiveId: yup.object().when(['refereeFive', 'electionTypeId'], {
    is: (refereeFive, electionTypeId) =>
      refereeFive !== null &&
      typeof refereeFive !== 'undefined' &&
      typeof refereeFive === 'number' &&
      refereeFive === 0 &&
      electionTypeId &&
      typeof electionTypeId === 'number' &&
      electionTypeId === 2,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeFiveTitleId: yup
    .number()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.number().required().typeError(defaultMessage),
      otherwise: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
    }),
  refereeFiveName: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.string().transform(trimString).min(2).max(40).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeFiveLastName: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.string().transform(trimString).min(2).max(40).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeFiveEmail: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.string().transform(trimString).max(254).email().required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeFiveCountryId: yup
    .object()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.object().required(),
      otherwise: yup.object().notRequired().nullable(),
    }),
  refereeFiveOrganisation: yup
    .string()
    .when(
      [
        'electionTypeId',
        'nonAasRefereeFiveId',
        'refereeFiveEmailEducationalInstitution',
      ],
      {
        is: (
          electionTypeId,
          nonAasRefereeFiveId,
          refereeFiveEmailEducationalInstitution,
        ) =>
          electionTypeId === 2 &&
          nonAasRefereeFiveId &&
          typeof nonAasRefereeFiveId.value === 'number' &&
          nonAasRefereeFiveId.value === 0 &&
          !refereeFiveEmailEducationalInstitution,
        then: yup.string().transform(trimString).required(),
        otherwise: yup.string().notRequired().nullable(),
      },
    ),
  refereeFiveMemberships: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeFiveId'], {
      is: (electionTypeId, nonAasRefereeFiveId) =>
        electionTypeId === 2 &&
        nonAasRefereeFiveId &&
        typeof nonAasRefereeFiveId.value === 'number' &&
        nonAasRefereeFiveId.value === 0,
      then: yup.string().transform(trimString).max(1500).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),

  refereeSix: yup
    .mixed()
    .transform((value) => (value === 'none' ? null : Number(value)))
    .when('electionTypeId', {
      is: (val) => val && typeof val === 'number' && val === 2,
      then: yup.number().required().nullable(),
      otherwise: yup.number().notRequired().nullable(),
    }),
  refereeSixFellowId: yup.object().when(['refereeSix', 'electionTypeId'], {
    is: (refereeSix, electionTypeId) =>
      refereeSix &&
      typeof refereeSix === 'number' &&
      refereeSix === 1 &&
      electionTypeId &&
      typeof electionTypeId === 'number' &&
      electionTypeId === 2,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeSixId: yup.object().when(['refereeSix', 'electionTypeId'], {
    is: (refereeSix, electionTypeId) =>
      refereeSix !== null &&
      typeof refereeSix !== 'undefined' &&
      typeof refereeSix === 'number' &&
      refereeSix === 0 &&
      electionTypeId &&
      typeof electionTypeId === 'number' &&
      electionTypeId === 2,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeSixTitleId: yup
    .number()
    .when(['electionTypeId', 'nonAasRefereeSixId'], {
      is: (electionTypeId, nonAasRefereeSixId) =>
        electionTypeId === 2 &&
        nonAasRefereeSixId &&
        typeof nonAasRefereeSixId.value === 'number' &&
        nonAasRefereeSixId.value === 0,
      then: yup.number().required().typeError(defaultMessage),
      otherwise: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
    }),
  refereeSixName: yup.string().when(['electionTypeId', 'nonAasRefereeSixId'], {
    is: (electionTypeId, nonAasRefereeSixId) =>
      electionTypeId === 2 &&
      nonAasRefereeSixId &&
      typeof nonAasRefereeSixId.value === 'number' &&
      nonAasRefereeSixId.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeSixLastName: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeSixId'], {
      is: (electionTypeId, nonAasRefereeSixId) =>
        electionTypeId === 2 &&
        nonAasRefereeSixId &&
        typeof nonAasRefereeSixId.value === 'number' &&
        nonAasRefereeSixId.value === 0,
      then: yup.string().transform(trimString).min(2).max(40).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeSixEmail: yup.string().when(['electionTypeId', 'nonAasRefereeSixId'], {
    is: (electionTypeId, nonAasRefereeSixId) =>
      electionTypeId === 2 &&
      nonAasRefereeSixId &&
      typeof nonAasRefereeSixId.value === 'number' &&
      nonAasRefereeSixId.value === 0,
    then: yup.string().transform(trimString).max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeSixCountryId: yup
    .object()
    .when(['electionTypeId', 'nonAasRefereeSixId'], {
      is: (electionTypeId, nonAasRefereeSixId) =>
        electionTypeId === 2 &&
        nonAasRefereeSixId &&
        typeof nonAasRefereeSixId.value === 'number' &&
        nonAasRefereeSixId.value === 0,
      then: yup.object().required(),
      otherwise: yup.object().notRequired().nullable(),
    }),
  refereeSixOrganisation: yup
    .string()
    .when(
      [
        'electionTypeId',
        'nonAasRefereeSixId',
        'refereeSixEmailEducationalInstitution',
      ],
      {
        is: (
          electionTypeId,
          nonAasRefereeSixId,
          refereeSixEmailEducationalInstitution,
        ) =>
          electionTypeId === 2 &&
          nonAasRefereeSixId &&
          typeof nonAasRefereeSixId.value === 'number' &&
          nonAasRefereeSixId.value === 0 &&
          !refereeSixEmailEducationalInstitution,
        then: yup.string().transform(trimString).required().nullable(),
        otherwise: yup.string().notRequired().nullable(),
      },
    ),
  refereeSixMemberships: yup
    .string()
    .when(['electionTypeId', 'nonAasRefereeSixId'], {
      is: (electionTypeId, nonAasRefereeSixId) =>
        electionTypeId === 2 &&
        nonAasRefereeSixId &&
        typeof nonAasRefereeSixId.value === 'number' &&
        nonAasRefereeSixId.value === 0,
      then: yup.string().transform(trimString).max(1500).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
});

export default schema;
