import countryList from 'react-select-country-list';
import { strapiUrl } from './constants';
import { axiosGet } from './axiosGet';

export const getAllNominationData = async (
  nominationData,
  nominationStatusTypes,
  electionTypes,
  organisations,
  titlesTypes,
  stateTerritories,
  genderTypes,
  australianStatus,
  sectionalCommitteesWithCmse,
) => {
  try {
    const response = await axiosGet('get-nomination-form-data', {
      data: nominationData,
    });

    if (response?.data) {
      const {
        nomination,
        candidate,
        nominationDeclaration,
        candidateState,
        nominationState,
        proposerSeconder,
        nominationSupporter,
        nominationReferee,
      } = response.data;
      const countryLabel = candidate.countryOfBirthId
        ? countryList().getLabel(candidate.countryOfBirthId)
        : '';

      const getSupporterType = (supporterTypeId) => {
        if (!supporterTypeId) {
          return '';
        }

        return supporterTypeId === 1 ? 'Personal' : 'General';
      };

      const getLabelFromJson = (jsonData) => {
        if (jsonData !== null && jsonData !== undefined) {
          if (Array.isArray(jsonData)) {
            const labelsString = jsonData.map((item) => item.label).join(', ');
            return labelsString;
          }
        }
        return null;
      };

      const nominationFormData = {
        id: nomination.id,
        createdAt: nomination.attributes.createdAt,
        updatedAt: nomination.attributes.updatedAt,
        nominationRoundYear: nominationState.roundYear,
        nominationStatus:
          nominationStatusTypes[nomination?.attributes?.nominationStatusTypeId],
        electionType: electionTypes[nomination?.attributes?.electionTypeId],

        consideredYears: candidateState?.consideredYears || null,

        lastConsideredDate: nominationState.lastConsideredDate,

        title: titlesTypes[candidate?.titleId],

        name: typeof candidate?.name === 'string' ? candidate.name : null,

        lastName:
          typeof candidate?.lastName === 'string' ? candidate.lastName : null,

        phone: typeof candidate?.phone === 'string' ? candidate.phone : null,

        address:
          typeof candidate?.address === 'string' ? candidate.address : null,

        email: typeof candidate?.email === 'string' ? candidate.email : null,

        stateTerritory: stateTerritories[candidate?.stateTerritoryId],

        gender: genderTypes[candidate?.genderId],

        otherGender:
          typeof candidate?.otherGender === 'string'
            ? candidate.otherGender
            : null,

        dob: typeof candidate?.dob === 'string' ? candidate.dob : null,

        countryOfBirth: countryLabel,

        australianStatus: australianStatus[candidate.australianStatusId],

        postNominal:
          typeof candidate?.postNominal === 'string'
            ? candidate.postNominal
            : null,

        jobTitle:
          typeof candidate?.jobTitle === 'string' ? candidate.jobTitle : null,

        organisation: organisations[candidate?.organisationId],
        otherOrganisation:
          typeof candidate?.otherOrganisation === 'string'
            ? candidate.otherOrganisation
            : null,

        phdAwardedYear:
          typeof candidate?.phdAwardedYear === 'number'
            ? candidate.phdAwardedYear
            : null,

        noPhd: !candidate.phdAwardedYear,

        personalInformation:
          typeof candidate?.personalInformation === 'string'
            ? candidate.personalInformation
            : null,

        diverseBackground:
          typeof candidate?.diverseBackground === 'boolean' &&
          candidate?.diverseBackground
            ? 1
            : 0,

        nominationConsentFilePath: nominationDeclaration?.nominationConsent
          ? `${strapiUrl}${nominationDeclaration.nominationConsent.url}`
          : '',

        retractedPapers: !!candidateState.retractedPapers,

        correctedPapers: !!candidateState.correctedPapers,

        publicationConcerns: !!candidateState.publicationConcerns,

        disclosureDeclarationFilePath:
          nominationDeclaration?.disclosureDeclaration
            ? `${strapiUrl}${nominationDeclaration.disclosureDeclaration.url}`
            : '',

        nationalityId: getLabelFromJson(candidate?.nationalityId),

        emailEducationalInstitution: '',

        arrivalDate: candidate?.arrivalDate,

        careerInterruption: !!candidateState.careerInterruption,

        scientificExcellence: nominationState.scientificExcellence,

        citation: nominationState?.citation ? nominationState.citation : '',

        sectionalCommittee:
          sectionalCommitteesWithCmse[
            nomination.attributes.sectionalCommitteeId
          ],

        deprecatedSectionalCommitteeLabel:
          nomination.attributes.deprecatedSectionalCommitteeLabel ?? null,

        disciplineDescriptor: getLabelFromJson(
          nomination?.attributes?.disciplineDescriptor,
        ),
        overlapCommittee: !!nomination?.attributes?.overlapCommittee,

        overlapSectionalCommitteeId:
          sectionalCommitteesWithCmse[
            nomination.attributes.overlapSectionalCommitteeId
          ],

        overlapDisciplineDescriptor: getLabelFromJson(
          nomination?.attributes?.overlapDisciplineDescriptor,
        ),

        interdisciplinaryCommittee: getLabelFromJson(
          nomination?.attributes?.interdisciplinaryCommittee,
        ),

        proposerFellow: proposerSeconder?.proposerFellowLabel,

        proposerSignatureFilePath: proposerSeconder.proposerSignature
          ? `${strapiUrl}${proposerSeconder.proposerSignature.url}`
          : '',

        seconderFellow: proposerSeconder?.seconderFellowLabel,

        seconderSignatureFilePath:
          typeof proposerSeconder?.seconderSignature === 'object' &&
          proposerSeconder?.seconderSignature?.url
            ? `${strapiUrl}${proposerSeconder.seconderSignature.url}`
            : '',

        supporterOneTypeId: getSupporterType(
          nominationSupporter?.supporterOneTypeId,
        ),
        supporterOneFellow: nominationSupporter?.supporterOneFellow?.label,

        supporterOneSignatureFilePath:
          typeof nominationSupporter?.supporterOneSignature === 'object' &&
          nominationSupporter?.supporterOneSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterOneSignature.url}`
            : '',

        supporterTwoTypeId: getSupporterType(
          nominationSupporter?.supporterTwoTypeId,
        ),
        supporterTwoFellow: nominationSupporter?.supporterTwoFellow?.label,

        supporterTwoSignatureFileName:
          typeof nominationSupporter?.supporterTwoSignature === 'object' &&
          nominationSupporter?.supporterTwoSignature?.name
            ? nominationSupporter.supporterTwoSignature.name
            : '',

        supporterThreeTypeId: getSupporterType(
          nominationSupporter?.supporterThreeTypeId,
        ),

        supporterThreeFellow: nominationSupporter?.supporterThreeFellow?.label,

        supporterThreeSignatureFilePath:
          typeof nominationSupporter?.supporterThreeSignature === 'object' &&
          nominationSupporter?.supporterThreeSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterThreeSignature.url}`
            : '',

        supporterFourTypeId: getSupporterType(
          nominationSupporter?.supporterFourTypeId,
        ),

        supporterFourFellow: nominationSupporter?.supporterFourFellow?.label,

        supporterFourSignatureFilePath:
          typeof nominationSupporter?.supporterFourSignature === 'object' &&
          nominationSupporter?.supporterFourSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterFourSignature.url}`
            : '',

        meetsConduct: !!candidateState?.meetsConduct,

        extendedCitationFilePath:
          typeof nominationState?.extendedCitation === 'object' &&
          nominationState?.extendedCitation?.url
            ? `${strapiUrl}${nominationState.extendedCitation.url}`
            : '',

        curriculumVitaeFilePath:
          typeof nominationState?.curriculumVitae === 'object' &&
          nominationState?.curriculumVitae?.url
            ? `${strapiUrl}${nominationState.curriculumVitae.url}`
            : '',

        significantPublicationsFilePath:
          typeof nominationState?.significantPublications === 'object' &&
          nominationState?.significantPublications?.url
            ? `${strapiUrl}${nominationState.significantPublications.url}`
            : '',

        publicationsFilePath:
          typeof nominationState?.publications === 'object' &&
          nominationState?.publications?.url
            ? `${strapiUrl}${nominationState.publications.url}`
            : '',

        refereeOne:
          typeof nominationReferee?.refereeOneTypeId === 'number'
            ? nominationReferee.refereeOneTypeId
            : null,

        refereeTwo:
          typeof nominationReferee?.refereeTwoTypeId === 'number'
            ? nominationReferee.refereeTwoTypeId
            : null,

        refereeThree:
          typeof nominationReferee?.refereeThreeTypeId === 'number'
            ? nominationReferee.refereeThreeTypeId
            : null,

        refereeFour:
          typeof nominationReferee?.refereeFourTypeId === 'number'
            ? nominationReferee.refereeFourTypeId
            : null,

        refereeFive:
          typeof nominationReferee?.refereeFiveTypeId === 'number'
            ? nominationReferee.refereeFiveTypeId
            : null,

        refereeSix:
          typeof nominationReferee?.refereeSixTypeId === 'number'
            ? nominationReferee.refereeSixTypeId
            : null,

        refereeSeven:
          typeof nominationReferee?.refereeSevenTypeId === 'number'
            ? nominationReferee.refereeSevenTypeId
            : null,

        refereeEight:
          typeof nominationReferee?.refereeEightTypeId === 'number'
            ? nominationReferee.refereeEightTypeId
            : null,

        refereeNine:
          typeof nominationReferee?.refereeNineTypeId === 'number'
            ? nominationReferee.refereeNineTypeId
            : null,
        refereeOneFellow: nominationReferee?.refereeOneFellow?.label,
        refereeTwoFellow: nominationReferee?.refereeTwoFellow?.label,
        refereeThreeFellow: nominationReferee?.refereeThreeFellow?.label,
        refereeFourFellow: nominationReferee?.refereeFourFellow?.label,
        refereeFiveFellow: nominationReferee?.refereeFiveFellow?.label,
        refereeSixFellow: nominationReferee?.refereeSixFellow?.label,
        refereeSevenFellow: nominationReferee?.refereeSevenFellow?.label,
        refereeEightFellow: nominationReferee?.refereeEightFellow?.label,
        refereeNineFellow: nominationReferee?.refereeNineFellow?.label,

        nonAasRefereeOne: nominationReferee.nonAasRefereeOne?.label,
        nonAasRefereeTwo: nominationReferee.nonAasRefereeTwo?.label,
        nonAasRefereeThree: nominationReferee.nonAasRefereeThree?.label,
        nonAasRefereeFour: nominationReferee.nonAasRefereeFour?.label,
        nonAasRefereeFive: nominationReferee.nonAasRefereeFive?.label,
        nonAasRefereeSix: nominationReferee.nonAasRefereeSix?.label,
        nonAasRefereeSeven: nominationReferee.nonAasRefereeSeven?.label,
        nonAasRefereeEight: nominationReferee.nonAasRefereeEight?.label,
        nonAasRefereeNine: nominationReferee.nonAasRefereeNine?.label,

        nonPreferredReferee: nominationReferee?.nonPreferredReferee ?? null,
      };
      return nominationFormData;
    }
    console.log('no response');
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
