import React from 'react';
import { Row } from 'react-bootstrap';

import PageTitle from '../../layouts/PageTitle';

import CardSummary from '../CardSummary';

const calculatePercentage = (total, number) =>
  `${Math.round((number * 100) / total)}%`;

const CorrespondingSpecialElections = () => {
  const totalCorresponding = 3;
  const femaleSpecialElection = 3;

  return (
    <div className="h-80">
      <PageTitle
        motherMenu="Corresponding Membership and Special Election"
        activeMenu="Report 2023"
      />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Corresponding Membership and Special Election"
          progressBarNumber={totalCorresponding}
          description={`Total: ${totalCorresponding}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Female Special Election"
          spanText={calculatePercentage(femaleSpecialElection, 1)}
          progressBarNumber={1}
          totalNumber={femaleSpecialElection}
          description={`Total: ${femaleSpecialElection}`}
          numberOfRows={6}
        />
      </Row>
    </div>
  );
};

export default CorrespondingSpecialElections;
