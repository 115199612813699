import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';

const InputNumber = ({ name, disabled = false }) => {
  const { register } = useContext(Context);

  return (
    <input
      name={name}
      className="form-control mb-3"
      type="number"
      disabled={disabled}
      {...register(name)}
    />
  );
};

InputNumber.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputNumber;
