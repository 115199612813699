import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import { Context } from '../Wizard/WizardWrapper';
import { axiosGet } from '../../../../utils/axiosGet';
import { isValidEmail } from '../../../../utils/isValidEmail';

const InputEmail = ({ name = {} }) => {
  const { register, setValue, setError, watch, errors } = useContext(Context);
  const watchEmail = watch(name);

  useEffect(() => {
    if (/\breferee.*email\b/i.test(name)) {
      if (watchEmail && isValidEmail(watchEmail)) {
        axiosGet(`fellows?filters[email][$eq]=${watchEmail}`).then(
          (response) => {
            if (response?.status === 200 && response?.data?.data?.length > 0) {
              const fellowLabel = response?.data?.data?.[0]?.attributes?.label;
              setError(name, {
                type: 'required',
                message: `Please select existing AAS referee${fellowLabel ? `: ${fellowLabel}` : ''}`,
              });
            }
          },
        );

        axiosGet(
          `non-aas-referees?filters[email][$eq]=${watchEmail}&filters[isActive][$eq]=true`,
        ).then((response) => {
          if (response?.status === 200 && response?.data?.data?.length > 0) {
            const fellowLabel = response?.data?.data?.[0]?.attributes?.label;

            const updatedAt = response?.data?.data?.[0]?.attributes?.updatedAt;
            const updatedAtTime = dayjs(updatedAt);
            const currentTime = dayjs();
            const differenceInSeconds = currentTime.diff(
              updatedAtTime,
              'second',
            );

            if (differenceInSeconds > 50) {
              console.log(`Updated at: ${updatedAt}`);
              setError(name, {
                type: 'required',
                message: `Please select existing non AAS referee${fellowLabel ? `: ${fellowLabel}` : ''}`,
              });
            }
          }
        });
      }
    }
  }, [name, errors, setError, watchEmail]);

  const getEducationalInstitution = (userEmailAddress, fieldName) => {
    if (
      userEmailAddress.indexOf('@') !== -1 &&
      userEmailAddress.indexOf('.') !== -1
    ) {
      const userEmailAddressArray = userEmailAddress.split('@');
      const academicInstitutionDomain = userEmailAddressArray[1];
      axiosGet(
        `educational-institution?filters[email][$eq]=${academicInstitutionDomain}`,
      )
        .then((result) => {
          const educationalInstitutionObjectKey = `${fieldName}EducationalInstitution`;

          if (
            result.data.message[0] !== undefined &&
            result.data.message[1] === undefined
          ) {
            const [educationalInstitution] = result.data.message;

            setValue(educationalInstitutionObjectKey, educationalInstitution);
          } else {
            setValue(educationalInstitutionObjectKey, '');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (event) => {
    if (
      name.toLowerCase().includes('email') &&
      isValidEmail(event.target.value)
    ) {
      getEducationalInstitution(event.target.value, name);
    }
  };

  return (
    <>
      <input
        name={name}
        className="form-control mb-3"
        type="email"
        {...register(name, {
          onChange: (event) => {
            handleChange(event, errors);
          },
        })}
      />

      {watch(`${name}EducationalInstitution`) !== undefined &&
      watch(`${name}EducationalInstitution`) !== '' &&
      !errors[name] ? (
        <input
          className="form-control mt-3"
          type="text"
          name={`${name}EducationalInstitution`}
          {...register(`${name}EducationalInstitution`)}
          disabled
        />
      ) : (
        ''
      )}
    </>
  );
};

InputEmail.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
};

export default InputEmail;
