import React from 'react';

import PropTypes from 'prop-types';
import { strapiUrl } from '../../utils/constants';
import DownloadFile from './Forms/Elements/DownloadFile';

const ActionButtons = ({ row }) => {
  const { reportFile, reportUploadedYear } = row.original;

  return (
    reportFile && (
      <DownloadFile
        text={`Report Round Year : ${reportUploadedYear}`}
        url={`${strapiUrl}${reportFile}`}
        name=""
        buttonClassName="action-button-two"
        iconClassName="action-button action-button-alt"
        showFileName={false}
        showText={false}
      />
    )
  );
};

ActionButtons.propTypes = {
  row: PropTypes.object,
};

export default ActionButtons;
