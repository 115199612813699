import React from 'react';
import PropTypes from 'prop-types';

import { stringCamelCaseToKebab } from '../../../../utils/stringCamelCaseToKebab';

import { getNominationRoundYear } from '../../../../utils/getNominationRoundYear';

const DownloadFile = ({
  text = '',
  url = '',
  name = '',
  buttonClassName = '',
  iconClassName = '',
  showFileName = true,
  showText = true,
}) => {
  const fetchFile = async () => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = '';

      if (name !== '') {
        link.download = stringCamelCaseToKebab(
          `${name}_${getNominationRoundYear()}.pdf`,
        );
      }

      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <>
      {showFileName && (
        <p
          className="mt-3"
          style={{ margin: 0, marginRight: '20px', display: 'inline-block' }}
        >
          <span className="uploaded-file">
            {text?.length > 0 && 'Uploaded: '}
          </span>
          {text}
        </p>
      )}

      <button
        type="button"
        className={`${buttonClassName || 'btn btn-outline-primary'}`}
        onClick={fetchFile}
      >
        <span title={text}>
          <i className={`bi bi-file-earmark-arrow-down ${iconClassName}`} />{' '}
          {!showFileName && showText && text}
        </span>
      </button>
    </>
  );
};

DownloadFile.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showFileName: PropTypes.bool,
  showText: PropTypes.bool,
};

export default DownloadFile;
