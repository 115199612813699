import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CommonCollections } from '../../App';
import ModalConfirmation from './ModalConfirmation';
import { axiosPost } from '../../utils/axiosPost';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { useAdminCollections } from '../../context/AdminCollections';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';
import { useRefereeCollections } from '../../context/RefereeReportCollections';

const ActionButtons = ({ row }) => {
  const {
    setRefereeReportId,
    refreshRefereeReports,
    setRefreshRefereeReports,
    refreshDynamicCollections,
    setRefreshDynamicCollections,
  } = useContext(CommonCollections);

  const { candidates, nominationArray } = useAdminCollections();
  const { userDetails } = useLoggedInUserContextProvider();
  const [showModal, setShowModal] = useState(false);
  const [selectedRefereeId, setSelectedRefereeId] = useState(null);
  const [selectedRefereeName, setSelectedRefereeName] = useState(null);
  const [approveExtensionRequest, setApproveExtensionRequest] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { id, refereeUser, nominationId } = row.original;
  const {
    setNonAdminRefereeReportId,
    refreshNonAdminRefereeReport,
    setRefreshNonAdminRefereeReport,
  } = useRefereeCollections();

  const handleClick = () => {
    setRefereeReportId(id);
    setNonAdminRefereeReportId(id);
  };

  const handleSelectClick = (refereeId, refereeName, approve) => {
    setApproveExtensionRequest(approve);
    setSelectedRefereeId(refereeId.toString());
    setSelectedRefereeName(refereeName);
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  const handleConfirmSelect = async (refereeId) => {
    try {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const timestamp = new Date().toLocaleDateString(undefined, options);
      const reason = `${inputValue} [${approveExtensionRequest ? 'Approved' : 'Declined'} by ${userDetails.username} (${timestamp})]`;

      // get candidate value
      const nominationArr = nominationArray.map((item) => ({
        [item.id]: item.attributes,
      }));

      const nominationsObj = arrayToFlatObject(nominationArr);

      const candidate = candidates[nominationsObj[nominationId].candidateId];
      const response = await axiosPost(
        `referee-report-extension-request-${approveExtensionRequest ? 'approve' : 'reject'}`,
        {
          data: { refereeId, reason, candidate },
        },
      );
      if (response.status === 200 && response.data) {
        setRefereeReportId(id);
        setRefreshRefereeReports(!refreshRefereeReports);
        setRefreshNonAdminRefereeReport(!refreshNonAdminRefereeReport);
        setNonAdminRefereeReportId(id);
        setRefreshDynamicCollections(!refreshDynamicCollections);
        setShowModal(false);
      } else {
        console.error(
          'Response data is missing or not in the expected format.',
        );
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  return (
    <>
      <Link to="/referee-report-administration" onClick={handleClick}>
        <span title="View">
          <i className="bi bi-eye view-button" />
        </span>
      </Link>
      <Link onClick={() => handleSelectClick(id, refereeUser, true)}>
        <span title="Approve Extension">
          <i className="bi bi-check action-button" />
        </span>
      </Link>
      <Link onClick={() => handleSelectClick(id, refereeUser, false)}>
        <span title="Reject Extension">
          <i className="bi bi-x action-button bg-danger" />
        </span>
      </Link>

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={`Are you sure you want to **${approveExtensionRequest ? 'approve' : 'reject'}** the extension request of **${selectedRefereeName}?**`}
        handleConfirmSelect={handleConfirmSelect}
        rowId={selectedRefereeId}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setRefreshDynamicCollections={setRefreshDynamicCollections}
        refreshDynamicCollections={refreshDynamicCollections}
        showForm
      />
    </>
  );
};

ActionButtons.propTypes = {
  row: PropTypes.object,
};

export default ActionButtons;
