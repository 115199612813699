import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { fetchData } from '../../../../utils/fetchData';

import CountrySelector from './CountrySelector';
import CustomIcon from './CustomIcon';
import Label from './Label';
import Markdown from './Markdown';
import Select from './Select';
import Span from './Span';
import TextArea from './TextArea';

import Collection from '../Collections/Collection';
import InputCheckbox from './InputCheckbox';
import InputFile from './InputFile';
import InputNumber from './InputNumber';
import InputEmail from './InputEmail';
import InputText from './InputText';
import InputDate from './InputDate';
import DescriptionSet from './DescriptionSet';

import { Context } from '../Wizard/WizardWrapper';
import ButtonIcon from './ButtonIcon';

const Elements = ({
  formName,
  stepNumber,
  setStepHeading,
  setNumberOfSteps,
  isSubmitted,
  setFormFieldSet,
  setStepLabel,
  errors,
}) => {
  const [formFields, setFormFields] = useState([]);
  const { setValue } = useContext(Context);
  const spanDefaultMessage = '';

  useEffect(() => {
    fetchData(
      `form-steps?filters[formName][$eq]=${formName}&populate[formField][populate][0]=selectOption&populate[formField][populate][1]=fileExtension&populate[formField][populate][2]=description`,
      setFormFields,
      setFormFieldSet,
      setStepLabel,
      setStepHeading,
      setNumberOfSteps,
      stepNumber,
    );
  }, [
    formName,
    setFormFieldSet,
    setStepLabel,
    setStepHeading,
    setNumberOfSteps,
    stepNumber,
  ]);

  const classNameMarginTop = 'mt-5';

  const descriptionSetObject = formFields.find(
    (obj) => obj.__component === 'description-set.description-set',
  );

  return (
    <section key={`form-section-${stepNumber}`}>
      <div className="row">
        {formFields.map((item, index) => {
          // Description component
          if (item.__component === 'description.description') {
            return (
              <Markdown
                key={`elements-description-markdown-${index}`}
                className="col-xl-12 col-lg-12 mt-3"
                text={item.description}
              />
            );
          }

          if (item.__component === 'description-set.description-set') {
            return (
              <DescriptionSet
                key={`elements-description-markdown-${index}`}
                className="col-xl-12 col-lg-12 mt-3"
                descriptionSet={item.description}
              />
            );
          }

          // Fieldset description component
          if (
            item.__component === 'fieldset-description.fieldset-description'
          ) {
            const reactMarkdownClassName =
              item.fieldsetDescription === null ||
              item.fieldsetDescription === ''
                ? 'mb-5'
                : 'mt-xl-5';

            return (
              <Markdown
                key={`elements-description-markdown-${index}`}
                className={`col-xl-12 col-lg-12 ${reactMarkdownClassName} card-header`}
                text={item.fieldsetDescription}
              />
            );
          }

          // Input components
          if (item.__component === 'input.input') {
            const divClassName =
              item.label.length > 100 ? 'col-lg-12' : 'col-lg-6';

            const marginClassName = item.type === 'file' ? '' : '';

            return (
              <div
                key={
                  item.type === 'checkbox'
                    ? `elements-checkbox-input-${index}`
                    : `elements-input-${index}`
                }
                className={
                  item.type === 'checkbox'
                    ? ''
                    : `${divClassName} ${classNameMarginTop}`
                }
              >
                {item.type === 'checkbox' &&
                item.description !== null &&
                item.description !== undefined &&
                item.description !== '' ? (
                  <Markdown
                    key={`elements-description-markdown-${index}`}
                    text={item.description}
                  />
                ) : (
                  ''
                )}
                <div
                  className={
                    item.type === 'checkbox'
                      ? 'form-check mt-4'
                      : `form-group ${marginClassName}`
                  }
                >
                  <Label
                    label={item.label}
                    name={item.name}
                    errors={errors}
                    required={item.required}
                  />
                  <CustomIcon
                    isSubmitted={isSubmitted}
                    fieldError={errors[item.name]}
                    required={item.required}
                  />

                  {item.type === 'email' ? <InputEmail name={item.name} /> : ''}

                  {item.type === 'checkbox' ? (
                    <InputCheckbox name={item.name} />
                  ) : (
                    ''
                  )}

                  {item.type === 'file' ? (
                    <InputFile
                      name={item.name}
                      fileExtension={
                        item.fileExtension ? item.fileExtension.data : []
                      }
                    />
                  ) : (
                    ''
                  )}

                  {item.type === 'number' ? (
                    <InputNumber name={item.name} />
                  ) : (
                    ''
                  )}

                  {item.type === 'text' ? <InputText name={item.name} /> : ''}

                  {item.type === 'date' ? <InputDate name={item.name} /> : ''}

                  <Span
                    text={
                      errors[item.name]
                        ? errors[item.name].message
                        : spanDefaultMessage
                    }
                  />

                  {item.type !== 'checkbox' ? (
                    <Markdown
                      className="col-xl-12 col-lg-12 mt-3"
                      text={item.description}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          }

          // Select components
          if (
            item.__component === 'select.select' &&
            item.selectOption !== null &&
            item.selectOption.data !== null &&
            item.selectOption.data.attributes.name !== 'countries'
          ) {
            const htmlSelectOptions = item.selectOption.data.attributes.name;
            const divClassName =
              item.label !== null && item.label.length > 100
                ? 'col-lg-12'
                : 'col-lg-6';

            const labelClassName =
              item.label !== null && item.label.length > 100 ? 'mb-3' : '';

            return (
              <React.Fragment key={`elements-select-${index}`}>
                <div className={`${divClassName} ${classNameMarginTop}`}>
                  <div className="form-group">
                    <Label
                      className={labelClassName}
                      label={item.label}
                      name={item.name}
                      errors={errors}
                      required={item.required}
                    />{' '}
                    {!item.required ? (
                      <ButtonIcon
                        bootstrapIcon="bi-arrow-counterclockwise"
                        backgroundColor="bg-success"
                        handleClick={() => {
                          setValue(item.name, '');
                        }}
                        handleKeyPress={() => {
                          setValue(item.name, '');
                        }}
                        tooltipText="Reset field"
                      />
                    ) : null}
                    <CustomIcon
                      isSubmitted={isSubmitted}
                      fieldError={errors[item.name]}
                      required={item.required}
                    />
                    <Select options={htmlSelectOptions} name={item.name} />
                    <Span
                      text={
                        errors[item.name]
                          ? errors[item.name].message
                          : spanDefaultMessage
                      }
                    />
                  </div>

                  <Markdown
                    className="col-xl-12 col-lg-12 mt-3"
                    text={item.description}
                  />
                </div>
              </React.Fragment>
            );
          }

          // Collection components
          if (item.__component === 'field-collection.field-collection') {
            const divClassName =
              item.label && item.label.length > 100 ? 'col-lg-12' : 'col-lg-6';

            return (
              <Collection
                key={`elements-field-collection-${index}`}
                collectionName={item.collection}
                item={item}
                descriptionSet={
                  descriptionSetObject !== undefined
                    ? descriptionSetObject.description
                    : []
                }
                className="col-lg-6"
                classNameMarginTop={classNameMarginTop}
                divClassName={divClassName}
                isSubmitted={isSubmitted}
              />
            );
          }

          // Empty div
          if (item.__component === 'empty-div.empty-div') {
            return <div key={`empty-div-${index}`} />;
          }

          // Select countries components
          if (
            item.__component === 'select.select' &&
            item.selectOption !== null &&
            item.selectOption.data !== null &&
            item.selectOption.data.attributes.name === 'countries'
          ) {
            return (
              <div
                key={`elements-country-selector-${index}`}
                className={`col-lg-6 ${classNameMarginTop}`}
              >
                <div className="form-group">
                  <Label
                    label={item.label}
                    name={item.name}
                    errors={errors}
                    required={item.required}
                  />

                  <CustomIcon
                    isSubmitted={isSubmitted}
                    fieldError={errors[item.name]}
                    required={item.required}
                  />

                  <CountrySelector name={item.name} />

                  <Span
                    text={
                      errors[item.name]
                        ? errors[item.name].message
                        : spanDefaultMessage
                    }
                  />

                  <Markdown
                    className="col-xl-12 col-lg-12 mt-3"
                    text={item.description}
                  />
                </div>
              </div>
            );
          }

          // Textarea components
          if (item.__component === 'text-area.text-area') {
            return (
              <div
                key={`elements-text-area-${index}`}
                className={`col-lg-12 ${classNameMarginTop}`}
              >
                <div className="form-group">
                  <Label
                    label={item.label}
                    name={item.name}
                    errors={errors}
                    required={item.required}
                  />

                  <CustomIcon
                    isSubmitted={isSubmitted}
                    fieldError={errors[item.name]}
                    required={item.required}
                  />

                  <Markdown
                    lassName="col-xl-12 col-lg-12 mt-3"
                    text={item.description}
                  />

                  <TextArea
                    name={item.name}
                    numberOfWords={
                      item.wordLimit !== null ? item.wordLimit : undefined
                    }
                    numberOfRows={8}
                    placeholder={item.placeholder}
                  />

                  <Span
                    text={
                      errors[item.name]
                        ? errors[item.name].message
                        : spanDefaultMessage
                    }
                  />
                </div>
              </div>
            );
          }

          return '';
        })}
      </div>
    </section>
  );
};

Elements.propTypes = {
  formName: PropTypes.string,
  stepNumber: PropTypes.number,
  setStepHeading: PropTypes.func,
  setNumberOfSteps: PropTypes.func,
  errors: PropTypes.object,
  isSubmitted: PropTypes.bool,
  setFormFieldSet: PropTypes.func,
  setStepLabel: PropTypes.func,
};

export default Elements;
