import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Label from '../Elements/Label';
import CustomIcon from '../Elements/CustomIcon';
import Select from '../Elements/Select';
import InputText from '../Elements/InputText';
import Span from '../Elements/Span';

import { Context } from '../Wizard/WizardWrapper';

const Genders = ({ item }) => {
  const { watch, errors, isSubmitted } = useContext(Context);
  const genderId = item.name ? parseInt(watch(item.name)) : null;

  return (
    <React.Fragment key={`gender-select-${item}`}>
      <div className="col-lg-6 mt-5">
        <div className="form-group">
          <Label
            className="mb-3"
            label={item.label}
            name={item.name}
            errors={errors}
            required={item.required}
          />
          <CustomIcon
            isSubmitted={isSubmitted}
            fieldError={errors[item.name]}
            required={item.required}
          />
          <Select options="genders" name={item.name} />

          {typeof genderId === 'number' && genderId === 1 && (
            <>
              <InputText name="otherGender" />
              <Span
                text={errors.otherGender ? errors.otherGender.message : ''}
              />
            </>
          )}
          <Span text={errors[item.name] ? errors[item.name].message : ''} />
        </div>
      </div>
    </React.Fragment>
  );
};

Genders.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Genders;
