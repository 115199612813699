import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

const ColumnCardHolder = ({ title, component, numberOfRows = 6 }) => (
  <Col xl={numberOfRows} lg={6}>
    <Card>
      <Card.Header>
        <h4 className="card-title">{title}</h4>
      </Card.Header>
      <Card.Body>{component}</Card.Body>
    </Card>
  </Col>
);

ColumnCardHolder.propTypes = {
  title: PropTypes.string,
  component: PropTypes.object,
  numberOfRows: PropTypes.number,
};

export default ColumnCardHolder;
