import axios from 'axios';
import { strapiApiUrl } from './constants';

export const axiosGet = async (urlParameters, configParameters = {}) => {
  try {
    return await axios.get(`${strapiApiUrl}${urlParameters}`, {
      params: configParameters,
      withCredentials: true,
    });
  } catch (error) {
    console.log(`Error in: ${urlParameters}`);
  }
};
