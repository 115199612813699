import React from 'react';
import PropTypes from 'prop-types';

const GlobalFilter = ({ filter, setFilter }) => (
  <div className="col-md-6">
    Search :{' '}
    <input
      className="ml-2 input-search form-control"
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      style={{ width: '40%' }}
    />
  </div>
);

GlobalFilter.propTypes = {
  filter: PropTypes.string,
  setFilter: PropTypes.func,
};

export default GlobalFilter;
