export const stringUcFirst = (str) => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length === 0) {
    return str; // Return unchanged for an empty string
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};
