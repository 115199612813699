export const filteringTableFilterUniqueCandidates = (tableData) =>
  tableData.filter((itemOne, index, self) => {
    if (!itemOne?.candidate) return false;

    const sameCandidates = self.filter(
      (itemTwo) =>
        itemTwo?.candidate?.toLowerCase().replace(/\s+/g, ' ').trim() ===
        itemOne.candidate.toLowerCase().replace(/\s+/g, ' ').trim(),
    );

    const itemWithNominationStatusTypeIdOne = sameCandidates.find(
      (item) =>
        item?.nominationStatusTypeId === 1 &&
        typeof item.nominationId === 'string' &&
        item.nominationId.includes('d'),
    );

    if (itemWithNominationStatusTypeIdOne) {
      itemWithNominationStatusTypeIdOne.roundYear = itemOne.roundYear;
      itemWithNominationStatusTypeIdOne.lastConsidered = itemOne.lastConsidered;
    }

    // if itemOne has nominationStatusTypeId === 1, or no other item has it, return true
    return (
      itemOne === itemWithNominationStatusTypeIdOne ||
      !itemWithNominationStatusTypeIdOne ||
      itemOne.nominationStatusTypeId !== 1
    );
  });
