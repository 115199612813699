import React from 'react';
import PropTypes from 'prop-types';

import Span from './Span';

const Label = ({ label = '', name, className = '', errors, required }) => {
  if (label !== '') {
    return (
      <label className={`text-label ${className}`} htmlFor={name}>
        {label}
        <Span required={required} name={name} errors={errors} />
      </label>
    );
  }

  return null;
};

Label.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
};

export default Label;
