import { getNominationRoundYear } from './getNominationRoundYear';

export const getReportUploadedYear = (report, refereeReportUploadedRound) => {
  if (refereeReportUploadedRound) {
    const date = new Date(refereeReportUploadedRound);
    const nominationRoundYear = getNominationRoundYear(date);
    return nominationRoundYear;
  }

  const yearFromName = report?.data?.attributes?.url
    ? report.data.attributes.url.match(/_(2\d{3})_/)
    : null;

  if (yearFromName) {
    return yearFromName[1];
  }
  if (report?.data?.attributes.createdAt) {
    const date = new Date(report.data.attributes.createdAt);
    const nominationRoundYear = getNominationRoundYear(date);
    return nominationRoundYear;
  }
};
