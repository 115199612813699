import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { getCollectionRowNames } from '../utils/getCollectionRowNames';
import { useLoggedInUserContextProvider } from './LoggedInUserContext';
import { filteringTableFormatDraftNomination } from '../utils/filteringTableFormatDraftNomination';

const ProposerCollections = createContext();

export const ProposerCollectionsProvider = ({ children }) => {
  const [candidates, setCandidates] = useState([]);
  const [proposerNominationIdArray, setProposerNominationIdArray] = useState(
    [],
  );
  const [userNominationArray, setUserNominationArray] = useState([]);
  const [userDraftNominationArray, setUserDraftNominationArray] = useState([]);
  const [refreshProposerCollections, setRefreshProposerCollections] =
    useState(false);

  const { userDetails } = useLoggedInUserContextProvider();

  useEffect(() => {
    if (userDetails) {
      axiosGet(
        `proposer-seconders?filters[proposerUserId][$eq]=${userDetails.id}`,
      ).then((response) => {
        if (response && response?.data?.data?.length > 0) {
          if (response.data.data.length > 1) {
            const proposerUserIds = response.data.data.map(
              (item) => item.attributes.nominationId,
            );
            setProposerNominationIdArray(proposerUserIds);
          } else {
            const proposerSingleNominationId =
              response.data.data[0].attributes.nominationId;

            setProposerNominationIdArray([proposerSingleNominationId]);
          }
        }
      });

      axiosGet(`nomination-drafts?filters[userId][$eq]=${userDetails.id}`).then(
        (response) => {
          const formattedDraftNomination = filteringTableFormatDraftNomination(
            response.data.data,
          );
          setUserDraftNominationArray(formattedDraftNomination);
        },
      );
    }
  }, [userDetails, refreshProposerCollections]);

  useEffect(() => {
    if (proposerNominationIdArray && proposerNominationIdArray?.length > 0) {
      let filteringQuery = '';

      if (proposerNominationIdArray?.length > 0) {
        filteringQuery = proposerNominationIdArray
          .map((id) => `filters[id][$in]=${id}`)
          .join('&');
      }

      axiosGet(`nominations?${filteringQuery}`).then((response) => {
        if (response?.data?.data && response.data.data.length !== 0) {
          setUserNominationArray(response.data.data);

          const candidateIds = response.data.data.map(
            (item) => item.attributes.candidateId,
          );
          const candidateQueryString = candidateIds
            .map((id) => `filters[id][$in]=${id}`)
            .join('&');

          axiosGet(`candidates?${candidateQueryString}`).then(
            (candidatesResponse) => {
              setCandidates(
                getCollectionRowNames(candidatesResponse.data.data),
              );
            },
          );
        } else if (userDetails) {
          axiosGet(
            `nomination-drafts?filters[userId][$eq]=${userDetails.id}`,
          ).then((nominationDraftResponse) => {
            if (!nominationDraftResponse?.data?.data) {
              axiosGet(`nominations?${filteringQuery}`).then(
                (secondResponse) => {
                  if (
                    secondResponse?.data?.data &&
                    secondResponse.data.data.length !== 0
                  ) {
                    setUserNominationArray(secondResponse.data.data);

                    const candidateIds = secondResponse.data.data.map(
                      (item) => item.attributes.candidateId,
                    );
                    const candidateQueryString = candidateIds
                      .map((id) => `filters[id][$in]=${id}`)
                      .join('&');

                    axiosGet(`candidates?${candidateQueryString}`).then(
                      (candidatesResponse) => {
                        setCandidates(
                          getCollectionRowNames(candidatesResponse.data.data),
                        );
                      },
                    );
                  }
                },
              );
            }
          });
        }
      });
    }
  }, [proposerNominationIdArray, userDetails]);

  return (
    <ProposerCollections.Provider
      value={useMemo(
        () => ({
          candidates,
          userNominationArray,
          userDraftNominationArray,
          refreshProposerCollections,
          setRefreshProposerCollections,
        }),
        [
          candidates,
          userNominationArray,
          userDraftNominationArray,
          refreshProposerCollections,
          setRefreshProposerCollections,
        ],
      )}
    >
      {children}
    </ProposerCollections.Provider>
  );
};

ProposerCollectionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useProposerCollections = () => useContext(ProposerCollections);
