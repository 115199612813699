import React, { useContext, useEffect } from 'react';

/// React router dom
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';

/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';

import ScrollToTop from './layouts/ScrollToTop';

/// Form
import WizardNomination from './components/Forms/Wizard/WizardNomination';
import WizardRefereeReport from './components/Forms/Wizard/WizardRefereeReport';

/// Charts
import ChartJs from './components/charts/Chartjs';
import NominationReports from './components/Reports/NominationReports';
import ShortListedCandidates from './components/Reports/ShortListedCandidates';
import CorrespondingSpecialElections from './components/Reports/CorrespondingSpecialElections';

/// Nomination Administration
import AdminNomination from './components/AdminNomination';
import SecretaryNomination from './components/SecretaryNomination';
import SecretaryRefereeReport from './components/SecretaryRefereeReport';
import RefereeReport from './components/RefereeReport';
import AdminRefereeReport from './components/AdminRefereeReport';
import Nomination from './components/Nomination';
import NominationViewProposer from './components/NominationViewProposer';
import NominationShortlist from './components/NominationShortlist';
import ChairCommittee from './components/ChairCommittee';
import AdminIndependentAssessor from './components/AdminIndependentAssessor';
import AdminActions from './components/AdminActions';
import SecretaryActions from './components/SecretaryActions';
import RefereeReportExtensionRequest from './components/RefereeReportExtensionRequest';
import WizardIndependentAssessor from './components/Forms/Wizard/WizardIndependentAssessor';
import PreNominationSteps from './components/PreNominationSteps';
import { ThemeContext } from '../context/ThemeContext';
import NominationElect from './components/NominationElect';
import Settings from './components/Settings';
import PermissionDenied from './pages/PermissionDenied';

import { checkPermissionAndNavigate } from '../utils/checkPermissionAndNavigate';

/// Common Collections
import { CommonCollections } from '../App';
import { useLoggedInUserContextProvider } from '../context/LoggedInUserContext';
import { useProposerCollections } from '../context/ProposerCollections';
import { useAdminCollections } from '../context/AdminCollections';

const Markup = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { refreshProposerCollections, setRefreshProposerCollections } =
    useProposerCollections();
  const { refreshAdminCollections, setRefreshAdminCollections } =
    useAdminCollections();

  const {
    nominationArray,
    nominationId,
    refreshDynamicCollections,
    setRefreshDynamicCollections,

    isFormSubmitted,
    setIsFormSubmitted,
  } = useContext(CommonCollections);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if ((pathname === '/login' || pathname === '/') && userDetails) {
      navigate('nominations');
    }

    if (pathname === '/application' && isFormSubmitted) {
      setTimeout(() => {
        setIsFormSubmitted(false);
        setRefreshProposerCollections(!refreshProposerCollections);
        navigate('/nominations');
      }, 2000);
    }

    if (pathname === '/application-administration' && isFormSubmitted) {
      setTimeout(() => {
        setIsFormSubmitted(false);
        setRefreshAdminCollections(!refreshAdminCollections);
        navigate('/nomination-administration');
      }, 2000);
    }

    if (pathname === '/committees' && isFormSubmitted) {
      setTimeout(() => {
        setIsFormSubmitted(false);
        setRefreshAdminCollections(!refreshAdminCollections);
      }, 2000);
    }

    if (pathname === '/form-view' && !nominationId) {
      navigate('/nominations');
    }

    if (pathname === '/independent-assessor' && !nominationId) {
      setIsFormSubmitted(false);
      setRefreshDynamicCollections(!refreshDynamicCollections);
      navigate('/assessor-administration');
    }

    if (pathname === '/independent-assessor' && !nominationId) {
      navigate('/assessor-administration');
    }

    if (pathname === '/referee-report' && isFormSubmitted) {
      setIsFormSubmitted(false);
      setRefreshDynamicCollections(!refreshDynamicCollections);
      navigate('/referee');
    }

    if (pathname === '/referee-report-administration' && isFormSubmitted) {
      setIsFormSubmitted(false);
      setRefreshDynamicCollections(!refreshDynamicCollections);
      navigate('/referee-administration');
    }

    if (userDetails) {
      checkPermissionAndNavigate(pathname, userDetails, navigate);
    }
  }, [
    location,
    nominationId,
    navigate,
    nominationArray,
    userDetails,

    isFormSubmitted,
    setIsFormSubmitted,

    refreshProposerCollections,
    setRefreshProposerCollections,
    refreshDynamicCollections,
    setRefreshDynamicCollections,
    refreshAdminCollections,
    setRefreshAdminCollections,
  ]);

  const allRoutes = [
    /// // Form
    {
      url: 'application',
      component: <WizardNomination />,
    },
    {
      url: 'application-administration',
      component: <WizardNomination />,
    },
    {
      url: 'nominations',
      component: <Nomination />,
    },

    {
      url: 'nomination-shortlisting',
      component: <NominationShortlist />,
    },
    {
      url: 'form-view',
      component: <NominationViewProposer />,
    },

    /// // Chart
    { url: 'chart-chartjs', component: <ChartJs /> },
    { url: 'nomination-reports', component: <NominationReports /> },
    { url: 'short-listed-candidates', component: <ShortListedCandidates /> },
    {
      url: 'corresponding-and-special-elections',
      component: <CorrespondingSpecialElections />,
    },
    {
      url: 'nomination-administration',
      component: <AdminNomination />,
    },
    {
      url: 'nomination-secretary',
      component: <SecretaryNomination />,
    },
    {
      url: 'referee-report-secretary',
      component: <SecretaryRefereeReport />,
    },
    {
      url: 'referee',
      component: <RefereeReport />,
    },
    {
      url: 'referee-administration',
      component: <AdminRefereeReport />,
    },
    {
      url: 'referee-report-extension-requests',
      component: <RefereeReportExtensionRequest />,
    },
    {
      url: 'referee-report',
      component: <WizardRefereeReport />,
    },
    {
      url: 'referee-report-administration',
      component: <WizardRefereeReport />,
    },
    {
      url: 'committees',
      component: <ChairCommittee />,
    },
    {
      url: 'elect-candidate',
      component: <NominationElect />,
    },
    {
      url: 'assessor-administration',
      component: <AdminIndependentAssessor />,
    },
    {
      url: 'independent-assessor',
      component: <WizardIndependentAssessor />,
    },
    {
      url: 'admin-actions',
      component: <AdminActions />,
    },
    {
      url: 'secretary-actions',
      component: <SecretaryActions />,
    },
    {
      url: 'pre-nomination-steps',
      component: <PreNominationSteps />,
    },
    {
      url: 'settings',
      component: <Settings />,
    },
    {
      url: 'permission-denied',
      component: <PermissionDenied />,
    },
  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {allRoutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${menuToggle ? 'menu-toggle' : ''}`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
