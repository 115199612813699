import React from 'react';
import PropTypes from 'prop-types';

import { windowScrollToTop } from '../../../../utils/windowScrollToTop';

const Button = ({
  cssClass = '',
  setStepNumber,
  stepNumber,
  textContent,
  scrollToTop = false,
  next = false,
  isDisabled = false,
}) => {
  const previousNextStepNumber = next ? stepNumber + 1 : stepNumber - 1;

  return (
    <button
      type="button"
      className={cssClass}
      onClick={() => {
        if (scrollToTop) {
          windowScrollToTop();
        }

        setStepNumber(previousNextStepNumber);
      }}
      disabled={isDisabled}
    >
      {textContent}
    </button>
  );
};

Button.propTypes = {
  cssClass: PropTypes.string,
  setStepNumber: PropTypes.func,
  stepNumber: PropTypes.number,
  textContent: PropTypes.string,
  scrollToTop: PropTypes.bool,
  next: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Button;
