import { arrayToFlatObject } from './arrayToFlatObject';

export const getCollectionRowNames = (collectionArray) => {
  const rowNames = collectionArray.map((item) => ({
    [item.id]: `${item.attributes?.name ?? ''} ${
      item.attributes?.lastName ?? ''
    } ${item.username ?? ''}`.trim(),
  }));

  return arrayToFlatObject(rowNames);
};
