/* eslint-disable */
/// Menu
import React, { Component, useContext, useEffect, useReducer, useState } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';

/// Link
import { Link, NavLink } from "react-router-dom";
import { MenuList } from './Menu';
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { useLoggedInUserContextProvider } from '../../../context/LoggedInUserContext';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}

const handleMailto = (email) => {
  window.open(`mailto:${email}`, '_blank');
};

const SideBar = () => {
   const { userDetails } = useLoggedInUserContextProvider();

	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
	} = useContext(ThemeContext);


  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector('.heart');
  function heartBlast(){
    return handleheartBlast.classList.toggle("heart-blast");
  }

	useEffect(() => {

	}, []);
 //For scroll
 	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

  const handleMenuActive = status => {

    setState({active : status});
		//console.log(state.active);
		if(state.active === status){
			//setActive('');
      setState({active : ""});
    }

	}
	const handleSubmenuActive = (status) => {

    setState({activeSubmenu : status})
		if(state.activeSubmenu === status){
      setState({activeSubmenu : ""})

		}
    //status.preventDefault();
	}

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu

  return (
    <div
      className={`dlabnav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
          <ul className="metismenu" id="menu">
			      <li className="menu-title"></li>
              {userDetails && MenuList.map((data, index)=>{

                const listItemClass = data.content && data.content.length > 0 ? '': 'no-over-flow';
                const hasAccess = Array.isArray(userDetails.roles) && data.access.some(role => userDetails.roles.includes(role));

                if(!hasAccess){
                  return null;
                }
                let menuClass = data.classChange;
                  if(menuClass === "menu-title"){
                    return(
                        <li className={menuClass}>{data.title}</li>
                    )
                  }else{
                    return(
                      <li className={`${ state.active === data.title ? 'mm-active' : `${listItemClass}`}`}
                        key={index}
                      >

                        {  data.content && data.content.length > 0 ?
                            <Link to={"#"}
                              className="has-arrow"
                              onClick={() => {handleMenuActive(data.title)}}
                            >
                                <i className={data.iconStyle}></i>
                                <span className="nav-text">{data.title}</span>
                            </Link>
                        :
                          <NavLink to={data.to} className="custom-hover">
                              <i className={data.iconStyle}></i>
                              <span className="nav-text">{data.title}</span>
                          </NavLink>
                        }
                        <Collapse in={state.active === data.title ? true :false}>
                          <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                            {data.content && data.content.map((data,index) => {
                              return(

                                  <li key={index}
                                    className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}`}
                                  >
                                    { data.content && data.content.length > 0 ?
                                      <NavLink to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                        onClick={() => { handleSubmenuActive(data.title)}}
                                      >
                                        {data.title}
                                      </NavLink>
                                      :
                                      <Link to={data.to}
                                      >
                                        {data.title}
                                      </Link>
                                    }
                                  </li>

                              )
                            })}
                          </ul>
                        </Collapse>
                      </li>
                    )
                }
              })}

          </ul>
          <div className="plus-box">
              <div className="d-flex align-items-center">
                <h5>Need Help?</h5>
              </div>
              <Link to={"#"} className="btn bg-white btn-sm" onClick={() => handleMailto('ict@science.org.au')}>Contact us</Link>
          </div>
          <div className="copyright mt-0">
              <p><strong>Fellowship Nomination System</strong> © {new Date().getFullYear()} All Rights Reserved</p>
              <p className="fs-12">Made with
                <span className="heart" onClick={()=>heartBlast()}></span> by AAS
              </p>
          </div>
        </PerfectScrollbar>
      </div>
    );
};

export default SideBar;
