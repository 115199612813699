import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';

import PropTypes from 'prop-types';
import PageTitle from '../layouts/PageTitle';
import ModalConfirmation from './ModalConfirmation';
import { handleConfirmSelect } from '../../utils/handleConfirmSelect';

import { arrayToString } from '../../utils/arrayToString';
import SectionalCommittees from './SectionalCommittees';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';

const TableNominationElect = ({
  nominationsInSectionalCommittee,
  handleSelectClick,
}) => {
  const { electionTypes, nominationStatusTypes } = useStaticCollections();
  const { candidates } = useAdminCollections();

  return (
    <Card className="card-two mb-xl-5 table-responsive">
      <Card.Body>
        <Table bordered hover striped className="dataTable">
          <thead className="thead-dark">
            <tr>
              <th className="d-none">
                <b>Nomination Id</b>
              </th>
              <th>
                <b>Name</b>
              </th>
              <th>
                <b>Type</b>
              </th>
              <th>
                <b>Descriptor</b>
              </th>
              <th>
                <b>Status</b>
              </th>
              <th aria-label="Actions">
                <b />
              </th>
            </tr>
          </thead>
          <tbody>
            {nominationsInSectionalCommittee.map((nomination, index) => {
              const { nominationStatusTypeId } = nomination.attributes;

              return (
                <tr key={index}>
                  <td className="d-none">{nomination.id}</td>
                  <td>{candidates[nomination.attributes.candidateId]}</td>
                  <td>{electionTypes[nomination.attributes.electionTypeId]}</td>
                  <td className="max-width-column">{`${
                    nomination.attributes.disciplineDescriptor !== null
                      ? arrayToString(
                          nomination.attributes.disciplineDescriptor,
                        )
                      : ''
                  }`}</td>
                  <td>{nominationStatusTypes[nominationStatusTypeId]}</td>

                  <td className="text-center">
                    {nomination.attributes.nominationStatusTypeId !== 3 ? (
                      <i className="bi bi-check-square view-button" />
                    ) : (
                      <button
                        className="button-reset"
                        type="button"
                        aria-label="Select Candidate"
                        onClick={() =>
                          handleSelectClick(
                            `${nomination.id}`,
                            candidates[nomination.attributes.candidateId],
                          )
                        }
                      >
                        <span title="Elect Candidate">
                          <i className="bi bi-square action-button" />
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const NominationElect = () => {
  const { refreshNominations, setRefreshNominations } = useAdminCollections();

  const [showModal, setShowModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedNominationId, setSelectedNominationId] = useState(null);

  const handleSelectClick = (nominationId, candidate) => {
    setSelectedCandidate(candidate);
    setSelectedNominationId(nominationId);
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageTitle motherMenu="Nominations" activeMenu="Elect candidate" />
      <SectionalCommittees
        TableComponent={TableNominationElect}
        statusTypes={[3, 4]}
        handleSelectClick={handleSelectClick}
      />

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={`Are you sure you want to **elect** ${selectedCandidate}?`}
        handleConfirmSelect={handleConfirmSelect}
        rowId={`${selectedNominationId}`}
        apiIdentifier="nomination-elect"
        refreshCollections={refreshNominations}
        setRefreshCollections={setRefreshNominations}
        setShowModal={setShowModal}
      />
    </>
  );
};

TableNominationElect.propTypes = {
  nominationsInSectionalCommittee: PropTypes.array,
  handleSelectClick: PropTypes.func,
};

export default NominationElect;
