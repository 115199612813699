import React from 'react';
import PropTypes from 'prop-types';

const RefereeStatus = ({ row }) => {
  const {
    refereeStatus,
    declineReason,
    extensionReason,
    declineReasonId,
    declineReasonOther,
    refereeStatusId,
    extensionReasonApprovalNote,
  } = row.original;
  let reason = '';
  if (refereeStatusId === 3) {
    reason = declineReasonId === 5 ? declineReasonOther : declineReason;
  } else if (refereeStatusId === 5) {
    reason = extensionReason;
  } else if (refereeStatusId === 6) {
    reason = extensionReasonApprovalNote;
  }
  if (reason && reason !== '') {
    return (
      <div>
        <b>{refereeStatus} : </b>
        {refereeStatusId === 6 ? (
          <>
            <br />
            <span>Reason: {extensionReason}</span>
            <br />
            <span>Approval Note: {extensionReasonApprovalNote}</span>
          </>
        ) : (
          <span>{reason}</span>
        )}
      </div>
    );
  }
  return <div>{refereeStatus}</div>;
};

RefereeStatus.propTypes = {
  row: PropTypes.object,
};

export default RefereeStatus;
