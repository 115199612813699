import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { windowScrollToTop } from '../../../../utils/windowScrollToTop';
import { saveNominationDraft } from '../../../../utils/saveNominationDraft';

import { CommonCollections } from '../../../../App';
import { useProposerCollections } from '../../../../context/ProposerCollections';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { useAdminCollections } from '../../../../context/AdminCollections';
import { useStaticCollections } from '../../../../context/StaticCollections';

const ButtonSubmit = ({
  formName = '',
  textContent,
  cssClass = '',
  setFormStatus = null,
  setVisibleNotification = false,
  scrollToTop = false,
  isDisabled = false,
  isDraftButton = false,
  numberOfErrors = null,
  isHidden = false,
}) => {
  const { refreshProposerCollections, setRefreshProposerCollections } =
    useProposerCollections();
  const { usersArray } = useStaticCollections();
  const { nominationId, setIsFormSubmitted } = useContext(CommonCollections);
  const { userDetails } = useLoggedInUserContextProvider();
  const { refreshNominations, setRefreshNominations } = useAdminCollections();
  if (isHidden) {
    return null;
  }

  return (
    <button
      type="submit"
      className={cssClass}
      onClick={() => {
        if (scrollToTop) {
          windowScrollToTop();

          if (isDraftButton && formName === 'nomination') {
            saveNominationDraft(
              refreshProposerCollections,
              setRefreshProposerCollections,
              userDetails,
              nominationId,
              refreshNominations,
              setRefreshNominations,
              usersArray,
              numberOfErrors,
            );
          }
        }
        if (setFormStatus) {
          setFormStatus(true);
          setVisibleNotification(true);
        }

        if (!isDraftButton && numberOfErrors === 0) {
          setIsFormSubmitted(true);
        }
      }}
      disabled={isDisabled}
    >
      {textContent}
    </button>
  );
};

ButtonSubmit.propTypes = {
  formName: PropTypes.string,
  textContent: PropTypes.string,
  cssClass: PropTypes.string,
  setFormStatus: PropTypes.func,
  setVisibleNotification: PropTypes.func,
  scrollToTop: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDraftButton: PropTypes.bool,
  isHidden: PropTypes.bool,
  numberOfErrors: PropTypes.number,
};

export default ButtonSubmit;
