import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { Context } from '../Wizard/WizardWrapper';

const InputDate = ({ name }) => {
  const { watch, control, setValue } = useContext(Context);
  const watchedValue = watch(name);

  // Ensure that the watched value is not null and is a valid Date object
  const selectedDate =
    watchedValue && !Number.isNaN(new Date(watchedValue))
      ? new Date(watchedValue)
      : null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div>
          <DatePicker
            id={name}
            className="form-control"
            selected={selectedDate}
            onChange={(date) => {
              field.onChange(date);
              // Format the date in ISO 8601 format (YYYY-MM-DD) before setting it in Strapi
              const isoDate = format(date, 'yyyy-MM-dd');
              setValue(name, isoDate, { shouldValidate: true });
            }}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100} // Set the number of years visible in the dropdown
            maxDate={new Date()}
          />
        </div>
      )}
    />
  );
};

InputDate.propTypes = {
  name: PropTypes.string,
};

export default InputDate;
