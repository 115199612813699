import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CommonCollections } from '../../App';
import { filteringTableGetButtonIcon } from '../../utils/filteringTableGetButtonIcon';
import { filteringTableGetButtonToolTipText } from '../../utils/filteringTableGetButtonToolTipText';
import { useAdminActionCollections } from '../../context/AdminActionCollections';

const NominationCell = ({ row }) => {
  const { setNominationId } = useContext(CommonCollections);
  const { nominationStatusTypeId, nominationId } = row.original;
  const { disableSubmissionEdit } = useAdminActionCollections();

  const buttonIcon = filteringTableGetButtonIcon(nominationStatusTypeId);
  const buttonToolTipText = filteringTableGetButtonToolTipText(
    nominationStatusTypeId,
  );

  if (disableSubmissionEdit && nominationStatusTypeId === 1) {
    return '';
  }

  return (
    <Link
      to={nominationStatusTypeId === 1 ? '/application' : '/form-view'}
      onClick={() => {
        setNominationId(nominationId);
      }}
    >
      <span title={buttonToolTipText}>
        <i className={buttonIcon} />
      </span>
    </Link>
  );
};

NominationCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default NominationCell;
