import React, { useEffect, useState, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Step, Stepper } from 'react-form-stepper';
import PageTitle from '../../../layouts/PageTitle';
import Elements from '../Elements/Elements';
import Button from '../Elements/Button';
import ButtonSubmit from '../Elements/ButtonSubmit';

import { setNotificationMessage } from '../../../../utils/setNotificationMessage';
import { getStepError } from '../../../../utils/getStepError';

export const Context = createContext();

const WizardWrapper = ({
  formName,
  activeMenu,
  motherMenu,
  handleSubmit,
  onSubmit,
  register,
  watch,
  setValue,
  resetField,
  errors,
  setError,
  control,
  formState,
  setFormState,
  formStatusId = 0,
  trigger,
  hideButtons,
}) => {
  const [numberOfSteps, setNumberOfSteps] = useState(1);
  const [stepNumber, setStepNumber] = useState(0);
  const [stepHeading, setStepHeading] = useState('Home');
  const [infoMessage, setInfoMessage] = useState('');

  const [formFieldSet, setFormFieldSet] = useState([]);
  const [stepLabel, setStepLabel] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [visibleNotification, setVisibleNotification] = useState(false);

  const stepsArray = Array.from(Array(numberOfSteps).keys());
  const getLabelText = (currentStepNumber) => {
    const textLabel = stepLabel.filter(
      (item) => Object.keys(item)[0] === `${currentStepNumber}`,
    )[0];

    if (textLabel !== undefined) {
      return textLabel[currentStepNumber];
    }
  };
  const numberOfErrors = Object.values(errors).length;
  useEffect(() => {
    setTimeout(() => {
      if (visibleNotification && (formStatusId === 1 || formStatusId === 0)) {
        setIsDraft(false);
        setVisibleNotification(false);
      }
    }, 10000);

    if (
      watch('nominationStatusTypeId') === undefined ||
      watch('nominationStatusTypeId') === null
    ) {
      setValue('nominationStatusTypeId', 1);
    }

    if (formStatusId === 1 && numberOfErrors === 0) {
      if (parseInt(watch('nominationStatusTypeId')) === 2) {
        setValue('nominationStatusTypeId', 1);
      }
    }

    if (isSubmitted && visibleNotification) {
      if (parseInt(watch('nominationStatusTypeId')) === 1) {
        setValue('nominationStatusTypeId', 2);
      }
    }
    if (formName === 'nomination') {
      setInfoMessage(
        setNotificationMessage(
          isDraft,
          numberOfErrors,
          formStatusId,
          isSubmitted,
          visibleNotification,
          formName,
          watch,
        ),
      );
    }

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null;
    };
  }, [
    formName,
    visibleNotification,
    setVisibleNotification,
    formStatusId,
    isDraft,
    isSubmitted,
    watch,
    setValue,
    numberOfErrors,
  ]);

  return (
    <>
      <PageTitle
        activeMenu={activeMenu}
        motherMenu={motherMenu}
        notificationMessage={infoMessage}
      />

      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{stepHeading}</h4>
            </div>

            <div className="card-body">
              <div className="form-wizard">
                <div className="stepper-wrapper">
                  {stepsArray.length > 1 && (
                    <Stepper
                      className="nav-wizard stepper"
                      activeStep={stepNumber}
                      nonLinear
                    >
                      {stepsArray.map((itemStepNumber, index) => {
                        let className = 'nav-link-inactive';
                        if (
                          (index !== stepsArray.length - 1 &&
                            index === stepNumber) ||
                          (index === stepsArray.length - 1 &&
                            stepNumber === stepsArray.length - 1)
                        ) {
                          className = 'nav-link';
                        }

                        if (
                          index === stepsArray.length - 1 &&
                          stepNumber !== stepsArray.length - 1
                        ) {
                          className = 'nav-link-inactive-last-step';
                        }
                        return (
                          <Step
                            key={itemStepNumber}
                            className={`${className} ${
                              getStepError(errors, formFieldSet).includes(
                                itemStepNumber + 1,
                              )
                                ? 'nav-link-error'
                                : ''
                            }`}
                            label={getLabelText(itemStepNumber + 1)}
                            styleConfig={{
                              labelFontSize: '1rem',
                            }}
                            onClick={() => setStepNumber(itemStepNumber)}
                          >
                            {(formStatusId === 1 || isDraft) &&
                            !getStepError(errors, formFieldSet).includes(
                              itemStepNumber + 1,
                            ) ? (
                              <i className="bi bi-check2" />
                            ) : (
                              ''
                            )}
                          </Step>
                        );
                      })}
                    </Stepper>
                  )}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Context.Provider
                    value={useMemo(
                      () => ({
                        formName,
                        register,
                        watch,
                        setValue,
                        resetField,
                        errors,
                        setError,
                        control,
                        formState,
                        setFormState,
                        trigger,
                      }),
                      [
                        formName,
                        register,
                        watch,
                        setValue,
                        resetField,
                        errors,
                        setError,
                        control,
                        formState,
                        setFormState,
                        trigger,
                      ],
                    )}
                  >
                    <Elements
                      formName={formName}
                      stepNumber={stepNumber + 1}
                      setStepHeading={setStepHeading}
                      setNumberOfSteps={setNumberOfSteps}
                      isSubmitted={isDraft}
                      setFormFieldSet={setFormFieldSet}
                      setStepLabel={setStepLabel}
                      errors={errors}
                    />
                  </Context.Provider>

                  {stepsArray.length > 1 && (
                    <div className="text-end toolbar toolbar-bottom p-2 mt-xl-5">
                      <Button
                        cssClass="btn btn-dark sw-btn-prev me-1"
                        setStepNumber={setStepNumber}
                        stepNumber={stepNumber}
                        textContent="Prev"
                        scrollToTop
                        isDisabled={stepNumber === 0}
                      />

                      <Button
                        cssClass="btn btn-dark sw-btn-next ms-1"
                        setStepNumber={setStepNumber}
                        stepNumber={stepNumber}
                        textContent="Next"
                        scrollToTop
                        isDisabled={stepNumber >= numberOfSteps - 1}
                        next
                      />
                    </div>
                  )}

                  <div className="text-end toolbar toolbar-bottom p-2 mt-xl-5">
                    {stepsArray.length > 1 && (
                      <ButtonSubmit
                        formName={formName}
                        textContent="Save Draft"
                        cssClass="btn btn-secondary sw-btn-next ms-1"
                        setFormStatus={setIsDraft}
                        setVisibleNotification={setVisibleNotification}
                        scrollToTop
                        isDraftButton
                        numberOfErrors={numberOfErrors}
                        isHidden={hideButtons}
                      />
                    )}
                    <ButtonSubmit
                      formName={formName}
                      textContent="Submit"
                      cssClass="btn btn-success sw-btn-next ms-1"
                      setFormStatus={setIsSubmitted}
                      setVisibleNotification={setVisibleNotification}
                      isDisabled={!(formStatusId === 1 && numberOfErrors === 0)}
                      scrollToTop={stepsArray.length > 1}
                      numberOfErrors={numberOfErrors}
                      isHidden={hideButtons}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

WizardWrapper.propTypes = {
  formName: PropTypes.string,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  register: PropTypes.func,
  trigger: PropTypes.func,
  resetField: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  setError: PropTypes.func,
  control: PropTypes.object,
  activeMenu: PropTypes.string,
  motherMenu: PropTypes.string,
  formState: PropTypes.object,
  setFormState: PropTypes.func,
  formStatusId: PropTypes.number,
  hideButtons: PropTypes.bool,
};

export default WizardWrapper;
