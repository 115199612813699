import React from 'react';
import { Row } from 'react-bootstrap';

import ChartPie from '../charts/Chartjs/pie';
import PageTitle from '../../layouts/PageTitle';

import CardSummary from '../CardSummary';
import ComponentCardHolder from '../ColumnCardHolder';
import BarCategories from '../BarCategories';

const calculatePercentage = (total, number) =>
  `${Math.round((number * 100) / total)}%`;

const ApexChart = () => {
  const shortListedCandidates = 68;
  const femaleShortListedCandidates = 14;

  return (
    <div className="h-80">
      <PageTitle
        motherMenu="Short-listed Candidates"
        activeMenu="Report 2023"
      />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Short-listed Candidates"
          progressBarNumber={shortListedCandidates}
          description={`Total: ${shortListedCandidates}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Female short-listed Candidates"
          spanText={calculatePercentage(
            shortListedCandidates,
            femaleShortListedCandidates,
          )}
          progressBarNumber={femaleShortListedCandidates}
          totalNumber={shortListedCandidates}
          description={`Total: ${shortListedCandidates}`}
          numberOfRows={6}
        />
        <ComponentCardHolder
          title="Short-listed candidates"
          component=<ChartPie pieData={[43, 40]} labels={['Male', 'Female']} />
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee"
          component=<BarCategories
            labelOne="Total"
            dataFirstRow={[
              '7',
              '15',
              '21',
              '8',
              '15',
              '14',
              '10',
              '12',
              '16',
              '8',
              '4',
              '11',
              '9',
            ]}
            colorOne="rgba(0, 113, 179, 1)"
          />
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee - Gender"
          component=<BarCategories
            labelOne="Women"
            labelTwo="Men"
            dataFirstRow={[
              '0',
              '0',
              '5',
              '1',
              '1',
              '2',
              '4',
              '6',
              '4',
              '6',
              '0',
              '2',
              '3',
            ]}
            dataSecondRow={[
              '7',
              '15',
              '16',
              '7',
              '14',
              '12',
              '6',
              '6',
              '12',
              '2',
              '4',
              '9',
              '6',
            ]}
            colorOne="rgba(0, 162, 255, 1)"
            colorTwo="rgba(153, 218, 255, 1)"
          />
          numberOfRows={12}
        />
      </Row>
    </div>
  );
};

export default ApexChart;
