export const addCmseCommitteeToSectionalCommitteeArray = (
  sectionalCommitteeArray,
  separateCommittee = false,
) => {
  if (separateCommittee) {
    return [
      ...sectionalCommitteeArray,
      {
        id: 14,
        attributes: {
          name: 'Corresponding Membership',
        },
      },
      {
        id: 15,
        attributes: {
          name: 'Special Election',
        },
      },
    ];
  }
  return [
    ...sectionalCommitteeArray,
    {
      id: 14,
      attributes: {
        name: 'CMSE: Corresponding Member and Special Election Committee',
      },
    },
  ];
};
