export const getStepError = (errors, formFieldSet) => {
  if (errors) {
    const stepsWithErrors = Object.keys(errors).map((item) =>
      formFieldSet.map((fieldName) => {
        const filteredFields = fieldName.filter(
          (field) => field[item] !== undefined,
        );

        return filteredFields.map(
          (filteredFieldName) => filteredFieldName[item],
        );
      }),
    );

    const stepsWithErrorsCollection = Object.keys(errors).map((item) => {
      if (
        item.toLowerCase().indexOf('arrivaldate') !== -1 ||
        item.toLowerCase().indexOf('nationalityid') !== -1 ||
        item.toLowerCase().indexOf('othergender') !== -1 ||
        item.toLowerCase().indexOf('otherorganisation') !== -1
      ) {
        return 1;
      }

      if (
        item.toLowerCase().indexOf('committee') !== -1 ||
        item.toLowerCase().indexOf('discipline') !== -1
      ) {
        return 4;
      }

      if (item.toLowerCase().indexOf('referee') !== -1) {
        return 10;
      }

      return '';
    });

    const filteredStepsWithErrorsCollections = stepsWithErrorsCollection.filter(
      (item) => item !== '',
    );

    return Array.from(new Set(filteredStepsWithErrorsCollections)).concat(
      Array.from(new Set(stepsWithErrors.flat().flat())),
    );
  }
};
