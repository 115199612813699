import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { getCollectionRowNames } from '../utils/getCollectionRowNames';
import { addCmseCommitteeToSectionalCommitteeArray } from '../utils/addCmseCommitteeToSectionalCommitteeArray';
import { addClassWithDelay } from '../utils/addClassWithDelay';
import { sortByCustomOrder } from '../utils/customOrder';
import { useLoggedInUserContextProvider } from './LoggedInUserContext';

const StaticCollections = createContext();

export const StaticCollectionsProvider = ({ children }) => {
  const paginationLimit = '&pagination[limit]=6000';

  const [activeFellows, setActiveFellows] = useState([]);
  const [activeFellowsArray, setActiveFellowsArray] = useState([]);
  const [australianStatus, setAustralianStatus] = useState([]);
  const [binariesTypes, setBinariesTypes] = useState([]);
  const [binariesTypesArray, setBinariesTypesArray] = useState([]);
  const [declineReasons, setDeclineReasons] = useState([]);
  const [electionTypes, setElectionTypes] = useState([]);
  const [electionTypesArray, setElectionTypesArray] = useState([]);
  const [fellows, setFellows] = useState([]);
  const [formStepsArray, setFormStepsArray] = useState([]);
  const [genderTypes, setGenderTypes] = useState([]);
  const [genderTypesArray, setGenderTypesArray] = useState([]);
  const [nonAasReferees, setNonAasReferees] = useState([]);
  const [nonAasRefereesArray, setNonAasRefereesArray] = useState([]);
  const [nominationStatusTypes, setNominationStatusTypes] = useState([]);
  const [nonAdminNominationStatusTypes, setNonAdminNominationStatusTypes] =
    useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [organisationsArray, setOrganisationsArray] = useState([]);
  const [refereeReportArchivedArray, setRefereeReportArchivedArray] = useState(
    [],
  );
  const [refereeStatuses, setRefereeStatuses] = useState([]);
  const [refereeActionsArray, setRefereeActionsArray] = useState([]);
  const [sectionalCommittees, setSectionalCommittees] = useState([]);
  const [sectionalCommitteeHistoryArray, setSectionalCommitteeHistoryArray] =
    useState([]);
  const [nominationRefereeHistoryArray, setNominationRefereeHistoryArray] =
    useState([]);
  const [sectionalCommitteesWithCmse, setSectionalCommitteesWithCmse] =
    useState([]);
  const [sectionalCommitteesArray, setSectionalCommitteesArray] = useState([]);

  const [stateTerritories, setStateTerritoriesTypes] = useState([]);
  const [supporterTypes, setSupporterTypes] = useState([]);
  const [supporterTypesArray, setSupporterTypesArray] = useState([]);
  const [titlesTypes, setTitleTypes] = useState([]);
  const [titlesTypesArray, setTitleTypesArray] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersArray, setUsersArray] = useState([]);

  const { userDetails } = useLoggedInUserContextProvider();

  useEffect(() => {
    if (userDetails) {
      axiosGet(
        'fellows?filters[active][$eq]=true&filters[doNotContact][$eq]=false&sort=lastName',
      ).then((response) => {
        setActiveFellows(getCollectionRowNames(response.data.data));
        setActiveFellowsArray(response.data.data);
      });

      axiosGet('australian-statuses').then((response) => {
        setAustralianStatus(getCollectionRowNames(response.data.data));
      });
      axiosGet('binaries').then((response) => {
        setBinariesTypes(getCollectionRowNames(response.data.data));
        setBinariesTypesArray(response.data.data);
      });
      axiosGet('election-types').then((response) => {
        setElectionTypes(getCollectionRowNames(response.data.data));
        const customOrder = [2, 3, 1];
        setElectionTypesArray(
          sortByCustomOrder(response.data.data, customOrder),
        );
      });
      axiosGet(`fellows?sort=lastName${paginationLimit}`).then((response) => {
        setFellows(getCollectionRowNames(response.data.data));
      });
      axiosGet(
        `form-steps?filters[formName][$eq]=nomination&populate[formField][populate][0]=selectOption&populate[formField][populate][1]=fileExtension`,
      ).then((response) => {
        setFormStepsArray(response.data.data);
      });
      axiosGet('genders').then((response) => {
        setGenderTypes(getCollectionRowNames(response.data.data));
        setGenderTypesArray(response.data.data);
      });
      axiosGet(`non-aas-referees?sort=lastName${paginationLimit}`).then(
        (response) => {
          setNonAasReferees(getCollectionRowNames(response.data.data || []));
          setNonAasRefereesArray(response.data.data);
        },
      );
      axiosGet('nomination-status-types').then((response) => {
        const resultGetCollectionRowNames = getCollectionRowNames(
          response.data.data,
        );

        setNominationStatusTypes(resultGetCollectionRowNames);

        const proposerNominationStatusTypes = Object.entries(
          resultGetCollectionRowNames,
        ).reduce((acc, [key, value]) => {
          acc[key] = value === 'Shortlisted' ? 'Submitted' : value;
          return acc;
        }, {});

        setNonAdminNominationStatusTypes(proposerNominationStatusTypes);
      });
      axiosGet('organisations?sort=name').then((response) => {
        const organisationsAndNotListedOption = [
          ...response.data.data,
          {
            id: 0,
            attributes: {
              name: '-- My organisation is not listed --',
            },
          },
        ];
        setOrganisations(
          getCollectionRowNames(organisationsAndNotListedOption),
        );
        setOrganisationsArray(organisationsAndNotListedOption);
      });
      axiosGet('referee-report-archives').then((response) => {
        setRefereeReportArchivedArray(response?.data?.data);
      });
      axiosGet('referee-actions').then((response) => {
        setRefereeActionsArray(response.data.data);
      });
      axiosGet('referee-statuses').then((response) => {
        setRefereeStatuses(getCollectionRowNames(response.data.data));
      });
      axiosGet('referee-report-decline-reasons').then((response) => {
        setDeclineReasons(getCollectionRowNames(response.data.data));
      });
      axiosGet('sectional-committees').then((response) => {
        setSectionalCommittees(getCollectionRowNames(response.data.data));
        setSectionalCommitteesArray(response.data.data);
        setSectionalCommitteesWithCmse(
          getCollectionRowNames(
            addCmseCommitteeToSectionalCommitteeArray(response.data.data),
          ),
        );
      });
      axiosGet('sectional-committee-histories').then((response) => {
        setSectionalCommitteeHistoryArray(response?.data.data || []);
      });
      axiosGet('nomination-referee-histories').then((response) => {
        setNominationRefereeHistoryArray(response?.data.data || []);
      });

      axiosGet('state-territories').then((response) => {
        setStateTerritoriesTypes(getCollectionRowNames(response.data.data));
      });
      axiosGet('supporter-types').then((response) => {
        setSupporterTypes(getCollectionRowNames(response.data.data));
        setSupporterTypesArray(response.data.data);
      });
      axiosGet('titles').then((response) => {
        setTitleTypes(getCollectionRowNames(response.data.data));
        setTitleTypesArray(response.data.data);
      });
      axiosGet('users').then((response) => {
        setUsers(getCollectionRowNames(response.data));
        setUsersArray(response.data);
      });

      addClassWithDelay('#main-wrapper', 'menu-toggle');
      addClassWithDelay('.hamburger', 'is-active');
    }
  }, [userDetails]);

  return (
    <StaticCollections.Provider
      value={useMemo(
        () => ({
          activeFellows,
          activeFellowsArray,
          australianStatus,
          binariesTypes,
          binariesTypesArray,
          electionTypes,
          electionTypesArray,
          formStepsArray,
          genderTypes,
          genderTypesArray,
          nonAasReferees,
          nonAasRefereesArray,
          nominationStatusTypes,
          nonAdminNominationStatusTypes,
          organisations,
          organisationsArray,
          refereeReportArchivedArray,
          refereeActionsArray,
          refereeStatuses,
          declineReasons,
          fellows,
          sectionalCommittees,
          sectionalCommitteesArray,
          sectionalCommitteeHistoryArray,
          nominationRefereeHistoryArray,
          sectionalCommitteesWithCmse,
          stateTerritories,
          supporterTypes,
          supporterTypesArray,
          titlesTypes,
          titlesTypesArray,
          users,
          usersArray,
        }),
        [
          activeFellows,
          activeFellowsArray,
          australianStatus,
          binariesTypes,
          binariesTypesArray,
          electionTypes,
          electionTypesArray,
          formStepsArray,
          genderTypes,
          genderTypesArray,
          nonAasReferees,
          nonAasRefereesArray,
          nominationStatusTypes,
          nonAdminNominationStatusTypes,
          organisations,
          organisationsArray,
          refereeReportArchivedArray,
          refereeActionsArray,
          refereeStatuses,
          declineReasons,
          fellows,
          sectionalCommittees,
          sectionalCommitteesArray,
          sectionalCommitteeHistoryArray,
          nominationRefereeHistoryArray,
          sectionalCommitteesWithCmse,
          stateTerritories,
          supporterTypes,
          supporterTypesArray,
          titlesTypes,
          titlesTypesArray,
          users,
          usersArray,
        ],
      )}
    >
      {children}
    </StaticCollections.Provider>
  );
};

StaticCollectionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useStaticCollections = () => useContext(StaticCollections);
