import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { CommonCollections } from '../../../../App';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { axiosGet } from '../../../../utils/axiosGet';

const RefereeHistory = () => {
  const { nominationId } = useContext(CommonCollections);
  const [refereeFeedbackHistory, setRefereeFeedbackHistory] = useState([]);
  useEffect(() => {
    const fetchRefereeFeedbackHistory = async () => {
      const response = await axiosGet(
        `referee-feedback-histories?filters[nominationId][$eq]=${nominationId}`,
      );

      if (response?.data?.data) {
        setRefereeFeedbackHistory(response.data.data);
      }
    };

    if (nominationId) {
      fetchRefereeFeedbackHistory();
    }
  }, [nominationId]);

  const { users, declineReasons, refereeStatuses, refereeReportArchivedArray } =
    useStaticCollections();

  const filteredRefereeReport = refereeReportArchivedArray.filter(
    (refereeReport) =>
      refereeReport.attributes.nominationId === nominationId &&
      refereeReport.attributes.independentAssessor === false,
  );

  // Determine the latest round year
  const latestRoundYear = Math.max(
    ...filteredRefereeReport.map((report) => report.attributes.roundYear),
  );

  // Filter reports by the latest round year
  const latestRoundReports = filteredRefereeReport.filter(
    (refereeReport) => refereeReport.attributes.roundYear === latestRoundYear,
  );

  const refereeTable = latestRoundReports.map((refereeReport) => {
    const {
      extensionReason,
      declineReasonId,
      declineReasonOther,
      refereeStatusId,
      refereeUserId,
      extensionReasonApprovalNote,
      roundYear,
      updatedAt,
    } = refereeReport.attributes;
    refereeReport.refereeUserId = refereeUserId;

    refereeReport.refereeUser = users[refereeUserId];
    refereeReport.refereeStatus =
      refereeStatusId === 1 ? 'No Response' : refereeStatuses[refereeStatusId];

    if (refereeStatusId === 3) {
      refereeReport.reason =
        declineReasonId === 5
          ? declineReasonOther
          : declineReasons[declineReasonId];
    } else if (refereeStatusId === 5) {
      refereeReport.reason = extensionReason;
    } else if (refereeStatusId === 6) {
      refereeReport.reason = extensionReasonApprovalNote;
    }
    refereeReport.round = roundYear;
    const date = new Date(updatedAt);
    refereeReport.year = date.getFullYear(date);
    return refereeReport;
  });

  // Get referee report archived history users and their results
  // Return a table if there is a value
  return (
    nominationId &&
    (refereeTable.length > 0 || refereeFeedbackHistory.length > 0) && (
      <>
        <div className="col-xl-12 col-lg-12 mt-xl-5 card-header">
          <p>
            <strong>Referee feedback history</strong>
          </p>
        </div>

        {refereeFeedbackHistory.length > 0 &&
          refereeFeedbackHistory[0]?.attributes?.refereeFeedback !== null && (
            <div className="col-lg-6 mt-4 mx-2">
              {refereeFeedbackHistory[0]?.attributes?.refereeFeedback.map(
                (feedback, index) => (
                  <p key={index}>
                    {feedback.children.map((child) => child.text).join(' ')}
                  </p>
                ),
              )}
            </div>
          )}
        {nominationId && refereeTable.length > 0 && (
          <Table bordered striped className="dataTable refereeHistory mx-2">
            <thead className="thead-dark">
              <tr>
                <th>
                  <b>Name</b>
                </th>
                <th>
                  <b>Status</b>
                </th>
                <th>
                  <b>Reason</b>
                </th>
                <th>
                  <b>Year</b>
                </th>
                <th>
                  <b>Round</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {refereeTable.map((referee, index) => (
                <tr key={index}>
                  <td>{referee.refereeUser}</td>
                  <td>{referee.refereeStatus}</td>
                  <td>
                    {referee.refereeStatusId === 6 ? (
                      <>
                        <span>Reason: {referee.extensionReason}</span>
                        <br />
                        <span>
                          Approval Note: {referee.extensionReasonApprovalNote}
                        </span>
                      </>
                    ) : (
                      <span>{referee.reason}</span>
                    )}
                  </td>
                  <td>{referee.year}</td>
                  <td>{referee.round}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </>
    )
  );
};

export default RefereeHistory;
