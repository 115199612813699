import * as yup from 'yup';
import { trimString } from '../../../../utils/trimString';

const pdfFileInputMessage = 'PDF files of up to 3mb are permitted';
const pdfFileSize = 3_200_000;
const defaultMessage = 'Required Field';
const notAPdfFile = 'Not a pdf. Please upload a valid file.';

yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Must be at least ${min} characters',
    email: 'Invalid email',
  },
  number: {
    integer: 'Must be an integer',
  },
});

const schema = yup.object().shape({
  refereeActions: yup
    .string()
    .nullable()
    .required('Please select an option')
    .notOneOf(['1'], 'Please select an option'),
  declineReasonId: yup
    .number()
    .nullable()
    .when('refereeActions', {
      is: '3',
      then: yup.number().nullable().required('Please select a decline reason'),
      otherwise: yup
        .number()
        .notRequired()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
    }),
  declineReasonOther: yup.string().when('declineReasonId', {
    is: '5',
    then: yup
      .string()
      .transform(trimString)
      .min(2)
      .required('Please enter your decline reason'),
    otherwise: yup.string().notRequired(),
  }),

  extensionReason: yup.string().when('refereeActions', {
    is: '5',
    then: yup
      .string()
      .transform(trimString)
      .min(2)
      .required('Please enter your reason for extension'),
    otherwise: yup.string().notRequired(),
  }),

  report: yup.mixed().when('refereeActions', {
    is: (val) => val === '2',
    then: yup
      .mixed()
      .required(defaultMessage)
      .nullable(true)
      .test(
        'is-valid-type',
        notAPdfFile,
        (file) => file && file[0] && file[0].type === 'application/pdf',
      )
      .test(
        'is-valid-size',
        pdfFileInputMessage,
        (file) =>
          file && file[0] && file[0].size <= pdfFileSize && file[0].size > 0,
      ),
    otherwise: yup.object().nullable(),
  }),
});
export default schema;
