const getStrapiUrl = () => {
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:8443';
  }
  if (window.location.href.includes('fns.dev')) {
    return 'https://strapi.dev.science.org.au';
  }
  if (window.location.href.includes('fns.test')) {
    return 'https://strapi.test.science.org.au';
  }
  return 'https://strapi.science.org.au';
};
export const strapiUrl = getStrapiUrl();

// Strapi url
const getStrapiApiUrl = () => {
  if (window.location.href.includes('localhost')) {
    return 'http://localhost:8443/api/';
  }
  if (window.location.href.includes('fns.dev')) {
    return 'https://strapi.dev.science.org.au/api/';
  }
  if (window.location.href.includes('fns.test')) {
    return 'https://strapi.test.science.org.au/api/';
  }
  return 'https://strapi.science.org.au/api/';
};
export const strapiApiUrl = getStrapiApiUrl();

// Max size of uploaded files
export const fileUploadMaxSize = 2000000;
export const refereeReportFileUploadMaxSize = 3200000;
