import React from 'react';

import { Card } from 'react-bootstrap';
import PageTitle from '../layouts/PageTitle';

function PermissionDenied() {
  return (
    <>
      <PageTitle motherMenu="Home" activeMenu="Permission Denied" />
      <Card
        key="permission-denied"
        className="card-two mb-xl-5 table-responsive"
      >
        <Card.Body>
          <h2> Access denied</h2>
          <hr />
          <p>You are not authorized access this page</p>
          <p>Please contact your Administrator(s) if you require access</p>
        </Card.Body>
      </Card>
    </>
  );
}

export default PermissionDenied;
