import { stringUcFirst } from './stringUcFirst';
import { objectGetNonEmptyCount } from './objectGetNonEmptyCount';

export const setNotificationMessage = (
  isDraft,
  numberOfErrors,
  formStatusId,
  isSubmitted,
  visibleNotification,
  formName,
  watch,
) => {
  let notificationMessage = '';
  const formattedFormName = stringUcFirst(formName);
  const name = watch('name');
  const lastName = watch('lastName');

  const nonEmptyCount = objectGetNonEmptyCount(watch());

  if (
    isDraft === false &&
    numberOfErrors === 0 &&
    formStatusId === 1 &&
    isSubmitted === false
  ) {
    notificationMessage = `${formattedFormName} is ready for submission`;
  }

  if (isDraft && numberOfErrors !== 0) {
    notificationMessage = 'Draft saved';
  }

  if (
    (isDraft && name === undefined) ||
    (name !== undefined && name.length === 0) ||
    (isDraft && lastName === undefined) ||
    (lastName !== undefined && lastName.length === 0)
  ) {
    const nameMessage =
      name === undefined || name.length === 0 ? 'Given Name' : '';

    const lastNameMessage =
      name === undefined || lastName.length === 0 ? 'Surname' : '';

    const connectorNotificationOne =
      nameMessage !== '' && lastNameMessage !== '' ? 'and' : '';

    const connectorNotificationTwo =
      nameMessage !== '' && lastNameMessage !== '' ? 'are' : 'is';

    notificationMessage = `${nameMessage} ${connectorNotificationOne} ${lastNameMessage} ${connectorNotificationTwo} required to save a draft`;
  } else if (isSubmitted) {
    if (visibleNotification) {
      notificationMessage = `${formattedFormName} submitted`;
    }
  } else if (
    (formStatusId === 1 || formStatusId === 0) &&
    numberOfErrors === 0 &&
    nonEmptyCount > 35
  ) {
    notificationMessage = `${formattedFormName} is ready for submission`;
  }

  return notificationMessage;
};
