import { axiosDelete } from './axiosDelete';
import { axiosGet } from './axiosGet';

export const formatNominationData = (data, sectionalCommittees) => {
  const date = new Date();

  data.nominationStatusTypeId =
    typeof Number.parseInt(data.nominationStatusTypeId) === 'number'
      ? Number.parseInt(data.nominationStatusTypeId)
      : null;

  data.value = sessionStorage.formNomination;

  // Step 1
  if (data.noPhd && typeof data.noPhd === 'boolean') {
    delete data.phdAwardedYear;
  }

  data.organisationId =
    typeof data?.organisationId?.value === 'number'
      ? data.organisationId.value
      : null;

  const year = data.dob.getFullYear();
  const month = String(data.dob.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
  const day = String(data.dob.getDate()).padStart(2, '0');
  data.dob = `${year}-${month}-${day}`;

  data.countryOfBirthId =
    typeof data?.countryOfBirthId?.value === 'string'
      ? data.countryOfBirthId.value
      : null;

  data.australianStatusId =
    typeof data?.australianStatusId === 'number'
      ? data?.australianStatusId
      : null;

  data.nominationConsent =
    typeof data?.nominationConsentFileId === 'object'
      ? data.nominationConsentFileId
      : null;
  delete data.nominationConsentFileId;

  data.disclosureDeclaration =
    typeof data?.disclosureDeclarationFileId === 'object'
      ? data.disclosureDeclarationFileId
      : null;
  delete data.disclosureDeclarationFileId;

  // Step 4
  delete data.overlapCommitteeNotes;
  delete data.sectionalCommitteeNotes;
  if (typeof data?.overlapSectionalCommitteeId === 'undefined') {
    data.overlapSectionalCommitteeId = null;
  }

  if (typeof data?.electionTypeId === 'number' && data.electionTypeId !== 2) {
    data.sectionalCommitteeId = null;
    data.disciplineDescriptor = null;
    data.overlapCommittee = null;
    data.overlapSectionalCommitteeId = null;
    data.overlapDisciplineDescriptor = null;
    data.interdisciplinaryCommittee = null;
    data.sectionalCommitteeLabel = null;
  } else if (
    typeof data?.electionTypeId === 'number' &&
    data.electionTypeId === 2 &&
    Object.values(sectionalCommittees).length > 0
  ) {
    data.sectionalCommitteeLabel =
      typeof data?.sectionalCommitteeId === 'number'
        ? sectionalCommittees[data.sectionalCommitteeId]
        : null;
  }

  // Step 5
  data.proposerFellowId =
    typeof data?.proposerFellowId?.value === 'number'
      ? data.proposerFellowId.value
      : null;
  data.proposerSignature =
    typeof data?.proposerSignatureFileId === 'object'
      ? data.proposerSignatureFileId
      : null;
  delete data.proposerSignatureFileId;

  data.seconderFellowId =
    typeof data?.seconderFellowId?.value === 'number'
      ? data.seconderFellowId.value
      : null;
  data.seconderSignature =
    typeof data?.seconderSignatureFileId === 'object'
      ? data.seconderSignatureFileId
      : null;
  delete data.seconderSignatureFileId;

  data.supporterOneFellowId =
    typeof data?.supporterOneFellowId?.value === 'number'
      ? data.supporterOneFellowId.value
      : null;
  data.supporterOneSignature =
    typeof data?.supporterOneSignatureFileId === 'object'
      ? data.supporterOneSignatureFileId
      : null;
  delete data.supporterOneSignatureFileId;

  data.supporterTwoFellowId =
    typeof data?.supporterTwoFellowId?.value === 'number'
      ? data.supporterTwoFellowId.value
      : null;
  data.supporterTwoSignature =
    typeof data?.supporterTwoSignatureFileId === 'object'
      ? data.supporterTwoSignatureFileId
      : null;
  delete data.supporterTwoSignatureFileId;

  if (
    data?.supporterThreeTypeId &&
    data?.supporterThreeFellowId?.value &&
    data?.supporterThreeSignatureFileId
  ) {
    data.supporterThreeFellowId = data.supporterThreeFellowId.value;
    data.supporterThreeSignature = data.supporterThreeSignatureFileId;
    delete data.supporterThreeSignatureFileId;
  } else {
    if (
      data?.supporterThreeSignatureFileId &&
      typeof data?.supporterThreeSignatureFileId === 'object' &&
      data?.supporterThreeSignatureFileId?.id
    ) {
      const fileId = data?.supporterThreeSignatureFileId?.id;
      axiosGet(`upload/files/${fileId}`).then((res) => {
        if (res?.status === 200 && res?.data?.id) {
          axiosDelete(`upload/files/${fileId}`);
        }
      });
    }

    data.supporterThreeFellowId = null;
    data.supporterThreeSignature = null;
    delete data.supporterThreeSignatureFileId;
    delete data.supporterThreeSignatureFileName;
    delete data.supporterThreeSignatureFilePath;
  }

  if (
    data?.supporterFourTypeId &&
    data?.supporterFourFellowId?.value &&
    data.supporterFourSignatureFileId
  ) {
    data.supporterFourFellowId = data.supporterFourFellowId.value;
    data.supporterFourSignature = data.supporterFourSignatureFileId;
    delete data.supporterFourSignatureFileId;
  } else {
    if (
      data?.supporterFourSignatureFileId &&
      typeof data?.supporterFourSignatureFileId === 'object' &&
      data?.supporterFourSignatureFileId?.id
    ) {
      const fileId = data?.supporterFourSignatureFileId?.id;
      axiosGet(`upload/files/${fileId}`).then((res) => {
        if (res?.status === 200 && res?.data?.id) {
          axiosDelete(`upload/files/${fileId}`);
        }
      });
    }

    data.supporterFourFellowId = null;
    data.supporterFourSignature = null;
    delete data.supporterFourSignatureFileId;
    delete data.supporterFourSignatureFileName;
    delete data.supporterFourSignatureFilePath;
  }

  // Step 6-9
  data.extendedCitation =
    typeof data?.extendedCitationFileId === 'object'
      ? data.extendedCitationFileId
      : null;
  delete data.extendedCitationFileId;

  data.significantPublications =
    typeof data?.significantPublicationsFileId === 'object'
      ? data.significantPublicationsFileId
      : null;
  delete data.significantPublicationsFileId;

  data.publications =
    typeof data?.publicationsFileId === 'object'
      ? data.publicationsFileId
      : null;
  delete data.publicationsFileId;

  data.curriculumVitae =
    typeof data.curriculumVitaeFileId === 'object'
      ? data.curriculumVitaeFileId
      : null;
  delete data.curriculumVitaeFileId;

  // Step 10
  data.refereeOneTypeId =
    typeof data?.refereeOne === 'number' ? data.refereeOne : null;
  delete data.refereeOne;

  data.refereeTwoTypeId =
    typeof data?.refereeTwo === 'number' ? data.refereeTwo : null;
  delete data.refereeTwo;

  data.refereeThreeTypeId =
    typeof data?.refereeThree === 'number' ? data.refereeThree : null;
  delete data.refereeThree;

  data.refereeFourTypeId =
    typeof data?.refereeFour === 'number' ? data.refereeFour : null;
  delete data.refereeFour;

  const isValidObject = (obj) =>
    obj && typeof obj === 'object' && Object.values(obj).length > 0;

  data.refereeFiveTypeId =
    (typeof data?.refereeFive === 'number' &&
      isValidObject(data?.refereeFiveFellowId)) ||
    isValidObject(data?.nonAasRefereeFiveId)
      ? data.refereeFive
      : null;
  delete data.refereeFive;

  data.refereeSixTypeId =
    (typeof data?.refereeSix === 'number' &&
      isValidObject(data?.refereeSixFellowId)) ||
    isValidObject(data?.nonAasRefereeSixId)
      ? data.refereeSix
      : null;
  delete data.refereeSix;

  const refereeIdentifiers = ['One', 'Two', 'Three', 'Four', 'Five', 'Six'];

  refereeIdentifiers.forEach((identifier) => {
    const refereeTypeId = data[`referee${identifier}TypeId`] ?? null;
    const nonAasRefereeId = data[`nonAasReferee${identifier}Id`] ?? null;

    if (
      typeof refereeTypeId === 'number' &&
      refereeTypeId === 0 &&
      typeof nonAasRefereeId === 'object' &&
      nonAasRefereeId?.value === 0
    ) {
      data[`newNonAasReferee${identifier}`] = {
        titleId: data[`referee${identifier}TitleId`],
        name: data[`referee${identifier}Name`],
        lastName: data[`referee${identifier}LastName`],
        email: data[`referee${identifier}Email`],
        countryId: data[`referee${identifier}CountryId`].value,
        organisation:
          data[`referee${identifier}Organisation`] &&
          data[`referee${identifier}Organisation`] !== ''
            ? data[`referee${identifier}Organisation`]
            : data[`referee${identifier}EmailEducationalInstitution`],
        memberships: data[`referee${identifier}Memberships`],
        label: `${data[`referee${identifier}Name`]} ${data[`referee${identifier}LastName`]} (${data[`referee${identifier}Email`]})`,
        publishedAt: date.toISOString().split('T')[0],
      };
    }
  });

  const assignRefereeId = (
    dataObject,
    refereeIdKey,
    refereeTypeIdKey,
    expectedTypeId,
  ) => {
    data[refereeIdKey] =
      data?.[refereeIdKey]?.value && data[refereeTypeIdKey] === expectedTypeId
        ? data[refereeIdKey].value
        : null;
  };

  assignRefereeId(data, 'refereeOneFellowId', 'refereeOneTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeOneId', 'refereeOneTypeId', 0);
  assignRefereeId(data, 'refereeTwoFellowId', 'refereeTwoTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeTwoId', 'refereeTwoTypeId', 0);
  assignRefereeId(data, 'refereeThreeFellowId', 'refereeThreeTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeThreeId', 'refereeThreeTypeId', 0);
  assignRefereeId(data, 'refereeFourFellowId', 'refereeFourTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeFourId', 'refereeFourTypeId', 0);
  assignRefereeId(data, 'refereeFiveFellowId', 'refereeFiveTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeFiveId', 'refereeFiveTypeId', 0);
  assignRefereeId(data, 'refereeSixFellowId', 'refereeSixTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeSixId', 'refereeSixTypeId', 0);
  assignRefereeId(data, 'refereeSevenFellowId', 'refereeSevenTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeSevenId', 'refereeSevenTypeId', 0);
  assignRefereeId(data, 'refereeEightFellowId', 'refereeEightTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeEightId', 'refereeEightTypeId', 0);
  assignRefereeId(data, 'refereeNineFellowId', 'refereeNineTypeId', 1);
  assignRefereeId(data, 'nonAasRefereeNineId', 'refereeNineTypeId', 0);

  delete data.refereeSeven;
  delete data.refereeEight;
  delete data.refereeNine;

  delete data.refereeSevenFellowId;
  delete data.refereeEightFellowId;
  delete data.refereeNineFellowId;

  delete data.nonAasRefereeSevenId;
  delete data.nonAasRefereeEightId;
  delete data.nonAasRefereeNineId;

  // Files
  delete data.nominationConsentFileName;
  delete data.nominationConsentFilePath;
  delete data.disclosureDeclarationFileName;
  delete data.disclosureDeclarationFilePath;
  delete data.publicationsFileName;
  delete data.publicationsFilePath;
  delete data.curriculumVitaeFileName;
  delete data.curriculumVitaeFilePath;
  delete data.extendedCitationFileName;
  delete data.extendedCitationFilePath;
  delete data.proposerSignatureFileName;
  delete data.proposerSignatureFilePath;
  delete data.seconderSignatureFileName;
  delete data.seconderSignatureFilePath;
  delete data.supporterOneSignatureFileName;
  delete data.supporterOneSignatureFilePath;
  delete data.supporterTwoSignatureFileName;
  delete data.supporterTwoSignatureFilePath;
  delete data.supporterThreeSignatureFileName;
  delete data.supporterThreeSignatureFilePath;
  delete data.significantPublicationsFileName;
  delete data.significantPublicationsFilePath;

  // PUBLICATION DATE
  [data.publishedAt] = date.toISOString().split('T');

  if (
    data.interdisciplinaryCommittee !== null &&
    data.interdisciplinaryCommittee !== undefined &&
    data.interdisciplinaryCommittee.length > 0
  ) {
    data.overlapCommittee = 0;
  }

  return data;
};
