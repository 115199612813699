export const tableColumnNamesNomination = {
  'Nomination Id': 'nominationId',
  Candidate: 'candidate',
  'Election Type': 'electionTypeId',
  Status: 'nominationStatusType',
  nominationStatusTypeId: 'nominationStatusTypeId',
  'Sectional Committee': 'sectionalCommitteeId',
  Actions: 'actions',
  'Created at': 'createdAt',
  'Updated at': 'updatedAt',
  'Published at': 'publishedAt',
};
