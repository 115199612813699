import { axiosGet } from './axiosGet';

const fnsUsers = ['referee', 'proposer', 'seconder', 'supporter'];

const setFormFieldsState = (fetchedArray, setFormFields) => {
  setFormFields(fetchedArray);
};

const setFormStepState = (fetchedArray, setFormFields, formStepNumber) => {
  fetchedArray.forEach((item) => {
    if (item.attributes.formStep === formStepNumber) {
      setFormFields(item.attributes.formField);
    }
  });
};

const setStepHeadingState = (resultData, formStepNumber, setStepHeading) => {
  if (setStepHeading !== undefined) {
    const currentStep = resultData.find(
      (item) => item.attributes.formStep === formStepNumber,
    );

    const headingNumber = resultData.length > 1 ? `${formStepNumber}.` : '';

    setStepHeading(`${headingNumber} ${currentStep.attributes.heading}`);
  }
};

const setNumberOfStepsState = (resultData, setNumberOfSteps) => {
  if (setNumberOfSteps !== undefined) {
    setNumberOfSteps(resultData.length);
  }
};

const getSelectFieldNames = (resultData) =>
  resultData
    .filter(
      (item) =>
        item.attributes.formStep === 5 || item.attributes.formStep === 10,
    )
    .map((item) =>
      item.attributes.formField.filter(
        (formFieldItem) =>
          (formFieldItem.__component === 'select.select' ||
            formFieldItem.__component ===
              'field-collection.field-collection') &&
          !formFieldItem.name.toLowerCase().includes('type') &&
          fnsUsers.some((element) =>
            formFieldItem.name.toLowerCase().includes(element),
          ),
      ),
    )
    .flat(2)
    .map((item) => {
      if (!item.name.includes('Id')) {
        return `${item.name}FellowId`;
      }

      return item.name;
    });

export const fetchData = (
  siteUrl,
  setFormFields,
  setFormFieldSet,
  setStepLabel,
  setStepHeading,
  setNumberOfSteps,
  formStepNumber = 0,
) => {
  if (sessionStorage.getItem(siteUrl)) {
    if (siteUrl.includes('form-steps')) {
      setStepLabel(JSON.parse(sessionStorage.getItem(`${siteUrl}StepLabel`)));
      setFormFieldSet(
        JSON.parse(sessionStorage.getItem(`${siteUrl}formFieldsArray`)),
      );
    }

    const resultData = JSON.parse(sessionStorage.getItem(`${siteUrl}`));

    setNumberOfStepsState(resultData, setNumberOfSteps);

    if (formStepNumber !== 0) {
      setStepHeadingState(resultData, formStepNumber, setStepHeading);
    }

    if (formStepNumber !== 0) {
      setFormStepState(resultData, setFormFields, formStepNumber);
    } else {
      setFormFieldsState(resultData, setFormFields);
    }
  } else {
    axiosGet(siteUrl)
      .then((result) => {
        if (result) {
          const resultData = result.data.data;
          sessionStorage.setItem(`${siteUrl}`, JSON.stringify(resultData));

          if (siteUrl.includes('form-steps')) {
            const formFieldsArray = resultData.map((item) => {
              if (item.attributes.formStep) {
                const filteredFormSteps = item.attributes.formField.filter(
                  (formField) => formField.name !== undefined,
                );

                const filteredStepFields = filteredFormSteps.map(
                  (filteredStep) => filteredStep.name,
                );

                return filteredStepFields.map((stepField) => ({
                  [stepField]: item.attributes.formStep,
                }));
              }

              return '';
            });

            const stepLabel = resultData.map((item) => ({
              [item.attributes.formStep]: item.attributes.heading,
            }));

            setStepLabel(stepLabel);
            sessionStorage.setItem(
              `${siteUrl}StepLabel`,
              JSON.stringify(stepLabel),
            );

            setFormFieldSet(formFieldsArray);
            sessionStorage.setItem(
              `${siteUrl}formFieldsArray`,
              JSON.stringify(formFieldsArray),
            );

            localStorage.setItem(
              'fnsUserFieldNames',
              JSON.stringify(getSelectFieldNames(resultData)),
            );
          }

          setNumberOfStepsState(resultData, setNumberOfSteps);
          setStepHeadingState(resultData, formStepNumber, setStepHeading);

          if (formStepNumber !== 0) {
            setFormStepState(resultData, setFormFields, formStepNumber);
          } else {
            setFormFieldsState(resultData, setFormFields);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
