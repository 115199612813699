import axios from 'axios';
import { strapiApiUrl } from './constants';

export const axiosDelete = (urlParameters) =>
  axios
    .delete(`${strapiApiUrl}${urlParameters}`, {
      withCredentials: true,
    })
    .then((result) => result)
    .catch((err) => {
      console.log(err);
    });
