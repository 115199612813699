import React, { useContext } from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';
import CustomIcon from '../Elements/CustomIcon';
import Label from '../Elements/Label';
import Select from '../Elements/Select';
import Span from '../Elements/Span';
import Markdown from '../Elements/Markdown';

import { Context } from '../Wizard/WizardWrapper';

const SectionalCommittee = ({
  item,
  className,
  classNameMarginTop,
  isSubmitted,
}) => {
  const { setValue, watch, errors, control } = useContext(Context);
  const multiValueFieldDescription =
    'Please select **multiple values** if required';
  const getQueryFilters = (formStateFromChild, elementName) => {
    if (watch('sectionalCommitteeId') !== undefined) {
      if (
        elementName === 'disciplineDescriptor' &&
        watch('sectionalCommitteeId') !== 'none'
      ) {
        return `?filters[sectionalCommitteeId][$eq]=${watch(
          'sectionalCommitteeId',
        )}`;
      }

      if (elementName === 'interdisciplinaryCommittee') {
        return `?filters[id][$ne]=${watch('sectionalCommitteeId')}`;
      }

      if (elementName === 'overlapSectionalCommitteeId') {
        return `?filters[id][$notIn][0]=${watch(
          'sectionalCommitteeId',
        )}&filters[id][$notIn][1]=13`;
      }

      if (
        elementName === 'overlapDisciplineDescriptor' &&
        watch('overlapSectionalCommitteeId') !== 'none'
      ) {
        return `?filters[sectionalCommitteeId][$eq]=${watch(
          'overlapSectionalCommitteeId',
        )}`;
      }

      return '';
    }
  };

  const setResetValues = (
    currentOptions,
    eventTargetName,
    eventTargetValue,
  ) => {
    const itemTest = currentOptions.filter(
      (currentOption) => currentOption.id === eventTargetValue,
    );

    if (eventTargetName === 'interdisciplinaryCommittee') {
      setValue('overlapSectionalCommitteeId', 'none', { shouldValidate: true });
      setValue('overlapCommittee', false, { shouldValidate: true });
      setValue('overlapDisciplineDescriptor', [], { shouldValidate: true });
    }

    if (eventTargetName === 'overlapSectionalCommitteeId') {
      setValue('overlapDisciplineDescriptor', [], { shouldValidate: true });

      if (watch('interdisciplinaryCommittee')) {
        setValue('interdisciplinaryCommittee', [], { shouldValidate: true });
      }

      setValue('overlapCommitteeNotes', itemTest[0].attributes.notes);
    }

    // FormState key according to element name
    let formStateNotesKey = 'overlapCommitteeNotes';

    if (eventTargetName === 'sectionalCommitteeId') {
      formStateNotesKey = 'sectionalCommitteeNotes';

      setValue('sectionalCommitteeNotes', '');
      setValue('overlapCommitteeNotes', '');

      setValue('disciplineDescriptor', [], { shouldValidate: true });
      setValue('overlapCommittee', 'none', { shouldValidate: true });
      setValue('overlapSectionalCommitteeId', 0, { shouldValidate: true });
      setValue('overlapDisciplineDescriptor', [], { shouldValidate: true });

      if (watch('interdisciplinaryCommittee')) {
        setValue('interdisciplinaryCommittee', [], { shouldValidate: true });
      }

      setValue('sectionalCommitteeNotes', itemTest[0].attributes.notes);
    }

    if (eventTargetName === 'overlapCommittee') {
      setValue('overlapSectionalCommitteeId', 'none', { shouldValidate: true });
      setValue('overlapCommitteeNotes', '');
      setValue('overlapDisciplineDescriptor', [], { shouldValidate: true });
    }

    if (watch(formStateNotesKey) && itemTest.length > 0) {
      setValue(formStateNotesKey, itemTest[0].attributes.notes, {
        shouldValidate: true,
      });
    }
  };

  if (parseInt(watch('electionTypeId')) === 2) {
    return (
      <React.Fragment key={`referee-field-collection-${item.name}`}>
        <div className={`${className} ${classNameMarginTop}`}>
          <div className="form-group mt-4">
            <Label
              label="Primary Sectional Committee"
              name="sectionalCommitteeId"
              errors={errors}
              required={item.required}
            />

            <CustomIcon
              isSubmitted={isSubmitted}
              fieldError={errors.sectionalCommitteeId}
              required={item.required}
            />

            <Select
              name="sectionalCommitteeId"
              options="sectional-committees"
              setState={setResetValues}
            />

            {errors.sectionalCommitteeId ? (
              <Span text={errors.sectionalCommitteeId.message} />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="form-group mt-4">
          {watch('sectionalCommitteeNotes') !== undefined ? (
            <Markdown
              text={watch('sectionalCommitteeNotes')}
              className={`${classNameMarginTop}`}
            />
          ) : (
            ''
          )}
        </div>

        {watch('sectionalCommitteeId') !== undefined &&
        parseInt(watch('sectionalCommitteeId')) !== 13 ? (
          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-4">
              <Label
                label="Discipline Descriptors"
                name="disciplineDescriptor"
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors.disciplineDescriptor}
                required={item.required}
              />

              <Select
                name="disciplineDescriptor"
                options="discipline-descriptors"
                getQueryFilters={getQueryFilters}
                setState={setResetValues}
                isMulti
              />

              {errors.disciplineDescriptor ? (
                <Span text={errors.disciplineDescriptor.message} />
              ) : (
                <Markdown
                  key="elements-description-markdown-1"
                  className="col-xl-12 col-lg-12 mt-2"
                  text={multiValueFieldDescription}
                />
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {watch('sectionalCommitteeId') !== undefined &&
        parseInt(watch('sectionalCommitteeId')) === 13 ? (
          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-4">
              <Label
                label="Interdisciplinary Candidates"
                name="interdisciplinaryCommittee"
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors.interdisciplinaryCommittee}
                required={item.required}
              />

              <Select
                name="interdisciplinaryCommittee"
                options="sectional-committees"
                getQueryFilters={getQueryFilters}
                setState={setResetValues}
                isMulti
              />

              {errors.interdisciplinaryCommittee ? (
                <Span text={errors.interdisciplinaryCommittee.message} />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {watch('sectionalCommitteeId') !== undefined &&
        parseInt(watch('sectionalCommitteeId')) !== 13 ? (
          <div className={`${className} ${classNameMarginTop} mb-xl-5`}>
            <div className="form-group mt-4">
              <Label
                label="Does the candidate require an overlap committee?"
                name="overlapCommittee"
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors.overlapCommittee}
                required={item.required}
              />

              <Select
                name="overlapCommittee"
                options="binaries"
                setState={setResetValues}
              />

              {errors.overlapCommittee ? (
                <Span text={errors.overlapCommittee.message} />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {watch('sectionalCommitteeId') !== undefined &&
        parseInt(watch('sectionalCommitteeId')) !== 13 &&
        watch('overlapCommittee') !== undefined &&
        parseInt(watch('overlapCommittee')) === 1 ? (
          <div className={`${className} ${classNameMarginTop} mb-xl-5`}>
            <div className="form-group mt-4">
              <Label
                label="Overlap Sectional Committee"
                name="overlapSectionalCommitteeId"
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors.overlapSectionalCommitteeId}
                required={item.required}
              />

              <Select
                name="overlapSectionalCommitteeId"
                options="sectional-committees"
                getQueryFilters={getQueryFilters}
                setState={setResetValues}
              />

              {errors.overlapSectionalCommitteeId ? (
                <Span text={errors.overlapSectionalCommitteeId.message} />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="form-group mt-4">
          {watch('overlapSectionalCommitteeId') !== undefined &&
          parseInt(watch('sectionalCommitteeId')) !== 13 &&
          watch('overlapCommittee') !== undefined &&
          watch('overlapCommitteeNotes') !== undefined &&
          watch('overlapCommitteeNotes') !== '' &&
          parseInt(watch('overlapCommittee')) === 1 ? (
            <Markdown
              text={watch('overlapCommitteeNotes')}
              className={`${classNameMarginTop}`}
            />
          ) : (
            ''
          )}
        </div>

        {watch('overlapCommittee') !== undefined &&
        parseInt(watch('overlapCommittee')) === 1 &&
        parseInt(watch('sectionalCommitteeId')) !== 13 &&
        watch('overlapSectionalCommitteeId') !== undefined &&
        watch('overlapSectionalCommitteeId') !== '' &&
        parseInt(watch('overlapSectionalCommitteeId')) !== 13 &&
        watch('overlapDisciplineDescriptor') !== undefined ? (
          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-4">
              <Label
                label="Overlap discipline descriptors"
                name="overlapDisciplineDescriptor"
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors.overlapDisciplineDescriptor}
                required={item.required}
              />

              <Select
                name="overlapDisciplineDescriptor"
                options="discipline-descriptors"
                getQueryFilters={getQueryFilters}
                setState={setResetValues}
                isMulti
              />

              {errors.overlapDisciplineDescriptor ? (
                <Span text={errors.overlapDisciplineDescriptor.message} />
              ) : (
                <Markdown
                  key="elements-description-markdown-1"
                  className="col-xl-12 col-lg-12 mt-2"
                  text={multiValueFieldDescription}
                />
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <div className="mb-xl-5" />
      </React.Fragment>
    );
  }

  if (parseInt(watch('electionTypeId')) !== 2) {
    return (
      <React.Fragment key={`referee-field-collection-${item.name}`}>
        <div className={`${className} ${classNameMarginTop}`}>
          <Label
            label="Primary Sectional Committee"
            name="correspondingElection"
            errors={errors}
            required={false}
          />
          <Controller
            name="correspondingElection"
            control={control}
            defaultValue={{
              value: 1,
              label: 'Corresponding Member and Special Election Committee',
            }}
            render={({ field }) => (
              <ReactSelect {...field} options={[]} isDisabled />
            )}
          />
        </div>
        <div className="mb-xl-5" />
      </React.Fragment>
    );
  }

  return '';
};

SectionalCommittee.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  classNameMarginTop: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default SectionalCommittee;
