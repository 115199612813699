import React from 'react';

const Footer = () => {
  const d = new Date();
  return (
    <div className="footer">
      <div className="copyright border-top">
        <p>
          Copyright ©{' '}
          <a
            href="https://www.science.org.au/"
            target="_blank"
            rel="noreferrer"
          >
            Australian Academy of Science
          </a>{' '}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
