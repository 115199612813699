import React, { Fragment, useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';
import Span from '../Elements/Span';
import Label from '../Elements/Label';
import CustomIcon from '../Elements/CustomIcon';
import Select from '../Elements/Select';
import Markdown from '../Elements/Markdown';
import InputDate from '../Elements/InputDate';
import NationalitySelector from '../Elements/NationalitySelector';

const AustralianStatusNationality = ({
  item,
  classNameMarginTop,
  divClassName,
  isSubmitted,
}) => {
  const { watch, resetField, errors } = useContext(Context);
  const electionTypeId = parseInt(watch('electionTypeId'));
  const australianStatusId = parseInt(watch('australianStatusId'));

  useEffect(() => {
    if (
      (electionTypeId === 2 || electionTypeId === 3) &&
      item.name === 'australianStatusId' &&
      australianStatusId !== 3
    ) {
      resetField('arrivalDate');
      resetField('nationalityId');
    }

    if (electionTypeId === 1) {
      resetField('australianStatusId');
      resetField('arrivalDate');
    }
  }, [item.name, australianStatusId, resetField, electionTypeId]);

  if (electionTypeId === 1) {
    const fieldName = 'nationalityId';
    return (
      <div className={`${divClassName} ${classNameMarginTop}`}>
        <div className="form-group">
          <Label
            label="Nationality"
            name={fieldName}
            errors={errors}
            required
          />

          <CustomIcon
            isSubmitted={isSubmitted}
            fieldError={errors[fieldName]}
            required
          />

          <NationalitySelector name={fieldName} />
          <Span text={errors[fieldName] ? errors[fieldName].message : ''} />
        </div>

        <Markdown
          className="col-xl-12 col-lg-12 mt-3"
          text={item.description}
        />
      </div>
    );
  }

  return (
    <div className={`${divClassName} ${classNameMarginTop}`}>
      <div className="form-group">
        <Label
          label={item.label}
          name={item.name}
          errors={errors}
          required={item.required}
        />

        <CustomIcon
          isSubmitted={isSubmitted}
          fieldError={errors[item.name]}
          required={item.required}
        />

        <Select options="australian-statuses" name={item.name} />
        <Span text={errors[item.name] ? errors[item.name].message : ''} />

        {australianStatusId === 3 && (
          <>
            <Label
              label="Date arrived in Australia"
              name="arrivalDate"
              errors={errors}
              required
            />
            <CustomIcon
              isSubmitted={isSubmitted}
              fieldError={errors.arrivalDate}
              required
            />
            <InputDate name="arrivalDate" />
            <Span text={errors.arrivalDate ? errors.arrivalDate.message : ''} />
          </>
        )}
      </div>

      <Markdown className="col-xl-12 col-lg-12 mt-3" text={item.description} />
    </div>
  );
};

AustralianStatusNationality.propTypes = {
  item: PropTypes.object,
  classNameMarginTop: PropTypes.string,
  divClassName: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default AustralianStatusNationality;
