import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { getCollectionRowNames } from '../utils/getCollectionRowNames';
import { useLoggedInUserContextProvider } from './LoggedInUserContext';

const RefereeCollections = createContext();

export const RefereeCollectionsProvider = ({ children }) => {
  const [candidates, setCandidates] = useState([]);
  const [nominationArray, setNominationArray] = useState([]);
  const [refereeReports, setRefereeReports] = useState([]);
  const [nonAdminRefereeReportId, setNonAdminRefereeReportId] = useState(null);
  const [refreshNonAdminRefereeReport, setRefreshNonAdminRefereeReport] =
    useState(false);
  const { userDetails } = useLoggedInUserContextProvider();
  useEffect(() => {
    const fetchData = async () => {
      if (userDetails) {
        try {
          const loggedInUserId = userDetails.id;
          const refereeReportsResponse = await axiosGet(
            `referee-reports?filters[refereeUserId][$eq]=${loggedInUserId}&filters[refereeArchived][$eq]=false&populate=*`,
          );

          setRefereeReports(refereeReportsResponse.data.data);

          const nominationResp = refereeReportsResponse.data.data.map(
            async (referee) => {
              const nominationResponse = await axiosGet(
                `nominations/${referee.attributes.nominationId}`,
              );

              return nominationResponse.data.data;
            },
          );

          const nominations = await Promise.all(nominationResp);
          const uniqueNominations = Array.from(
            new Set(nominations.map((nom) => nom.id)),
          ).map((id) => nominations.find((nom) => nom.id === id));
          setNominationArray(uniqueNominations);

          const candidateResp = nominations.map(async (nomination) => {
            const candidateResponse = await axiosGet(
              `candidates/${nomination.attributes.candidateId}`,
            );
            return candidateResponse.data.data;
          });

          const candidateArray = await Promise.all(candidateResp);

          const uniqueCandidates = Array.from(
            new Set(candidateArray.map((candidate) => candidate.id)),
          ).map((id) =>
            candidateArray.find((candidate) => candidate.id === id),
          );
          setCandidates(getCollectionRowNames(uniqueCandidates));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [userDetails]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        userDetails &&
        nonAdminRefereeReportId &&
        refreshNonAdminRefereeReport
      ) {
        try {
          const loggedInUserId = userDetails.id;
          const response = await axiosGet(
            `referee-reports/${nonAdminRefereeReportId}?filters[refereeUserId][$eq]=${loggedInUserId}&filters[refereeArchived][$eq]=false&populate=*`,
          );
          if (response) {
            const index = refereeReports.findIndex(
              (report) => report.id === response.data.data.id,
            );
            const updatedReports = [...refereeReports];
            updatedReports[index] = response.data.data;
            setRefereeReports(updatedReports);
          } else {
            // Removes deleted report when declining referee
            const updatedReports = refereeReports.filter(
              (report) => report.id !== nonAdminRefereeReportId,
            );

            setRefereeReports(updatedReports);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [
    userDetails,
    nonAdminRefereeReportId,
    refereeReports,
    refreshNonAdminRefereeReport,
  ]);
  return (
    <RefereeCollections.Provider
      value={useMemo(
        () => ({
          nonAdminRefereeReportId,
          setNonAdminRefereeReportId,
          candidates,
          nominationArray,
          refereeReports,
          refreshNonAdminRefereeReport,
          setRefreshNonAdminRefereeReport,
        }),
        [
          nonAdminRefereeReportId,
          setNonAdminRefereeReportId,
          candidates,
          nominationArray,
          refereeReports,
          refreshNonAdminRefereeReport,
          setRefreshNonAdminRefereeReport,
        ],
      )}
    >
      {children}
    </RefereeCollections.Provider>
  );
};

RefereeCollectionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRefereeCollections = () => useContext(RefereeCollections);
