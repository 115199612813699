import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import packageJson from '../../../package.json';

const { version } = packageJson;

const PageTitle = ({ motherMenu, activeMenu, notificationMessage = '' }) => {
  const path = window.location.pathname.split('/');

  return (
    <div className="row page-titles mx-0">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active">
          <Link to={`/${path[path.length - 1]}`}>{motherMenu}</Link>
        </li>
        <li className="breadcrumb-item  ">
          <Link to={`/${path[path.length - 1]}`}>{activeMenu}</Link>
        </li>
        <span>
          <i className="version-span bg-dark">Version {version}</i>
        </span>
      </ol>

      {notificationMessage !== null && notificationMessage !== '' && (
        <span>
          <i className="submit-notification bg-info">{notificationMessage}</i>
        </span>
      )}
    </div>
  );
};

PageTitle.propTypes = {
  motherMenu: PropTypes.string,
  activeMenu: PropTypes.string,
  notificationMessage: PropTypes.string,
};

export default PageTitle;
