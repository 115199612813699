import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw plugin for HTML support
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'react-bootstrap';

const ModalConfirmation = ({
  showModal,
  handleCancelSelect,
  bodyText,
  handleConfirmSelect,
  rowId,
  apiIdentifier = '',
  setRefreshCollections = '',
  refreshCollections = '',
  setShowModal = '',
  inputValue,
  setInputValue,
  showForm = false,
}) => {
  const [validated, setValidated] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (showForm && !inputValue.trim()) {
      setValidated(true);
    } else {
      setValidated(false);
      handleConfirmSelect(rowId);
    }
  };
  return (
    <Modal show={showModal} onHide={handleCancelSelect} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Allow rendering of HTML within the Markdown content */}
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{bodyText}</ReactMarkdown>
        {showForm && (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="inputValue">
              <Form.Label>Please provide a reason</Form.Label>
              <Form.Control
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelSelect}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() =>
            handleConfirmSelect(
              rowId,
              apiIdentifier,
              setRefreshCollections,
              refreshCollections,
              setShowModal,
            )
          }
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirmation.propTypes = {
  showModal: PropTypes.bool,
  handleCancelSelect: PropTypes.func,
  bodyText: PropTypes.string,
  handleConfirmSelect: PropTypes.func,
  apiIdentifier: PropTypes.string,
  rowId: PropTypes.string,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  showForm: PropTypes.bool,
  setRefreshCollections: PropTypes.func,
  refreshCollections: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default ModalConfirmation;
