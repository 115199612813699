export const tableColumnNamesRefereeAdmin = {
  Id: 'id',
  Candidate: 'candidate',
  Referee: 'refereeUser',
  'Sectional Committee': 'sectionalCommittee',
  'Referee Type': 'independentAssessor',
  'Referee Status': 'refereeStatus',
  'Nomination Status': 'nominationStatus',
  'Report email sent': 'requestEmailDate',
  'Reminder email sent': 'reminderEmailDate',
  'Report Uploaded Year': 'reportUploadedYear',
  Actions: 'actions',
  'Created at': 'createdAt',
  'Updated at': 'updatedAt',
  'Published at': 'publishedAt',
};
