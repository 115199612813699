import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Label from '../Elements/Label';
import CustomIcon from '../Elements/CustomIcon';
import Select from '../Elements/Select';

import Span from '../Elements/Span';

import { Context } from '../Wizard/WizardWrapper';

const StateTerritories = ({ item }) => {
  const { watch, errors, isSubmitted } = useContext(Context);
  const electionTypeId = parseInt(watch('electionTypeId'));

  if (
    typeof electionTypeId === 'number' &&
    (electionTypeId === 2 || electionTypeId === 3)
  ) {
    return (
      <React.Fragment key={`gender-select-${item}`}>
        <div className="col-lg-6 mt-5">
          <div className="form-group">
            <Label
              className="mb-3"
              label={item.label}
              name={item.name}
              errors={errors}
              required={item.required}
            />
            <CustomIcon
              isSubmitted={isSubmitted}
              fieldError={errors[item.name]}
              required={item.required}
            />
            <Select options="state-territories" name={item.name} />

            <Span text={errors[item.name] ? errors[item.name].message : ''} />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

StateTerritories.propTypes = {
  item: PropTypes.object.isRequired,
};

export default StateTerritories;
