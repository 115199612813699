import React, { useEffect, useState, useContext } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useFormPersist from 'react-hook-form-persist';

import { formatNominationData } from '../../../../utils/formatNominationData';

import ValidationNomination from '../Validation/ValidationNomination';
import WizardWrapper from './WizardWrapper';
import { axiosGet } from '../../../../utils/axiosGet';
import { axiosDelete } from '../../../../utils/axiosDelete';
import { axiosPost } from '../../../../utils/axiosPost';
import { CommonCollections } from '../../../../App';
import { getNominationFormData } from '../../../../utils/getNominationFormData';
import { objectGetNonEmptyCount } from '../../../../utils/objectGetNonEmptyCount';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { useProposerCollections } from '../../../../context/ProposerCollections';
import { useAdminCollections } from '../../../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { useAdminActionCollections } from '../../../../context/AdminActionCollections';

const WizardNomination = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { organisations, sectionalCommittees, usersArray } =
    useStaticCollections();
  const { userDraftNominationArray } = useProposerCollections();
  const {
    nominationArray,
    draftNominationArray,
    refreshNominations,
    setRefreshNominations,
  } = useAdminCollections();

  const { nominationId, setNominationId } = useContext(CommonCollections);
  const { disableSubmissionEdit } = useAdminActionCollections();

  const [formState, setFormState] = useState({});
  const [strapiCandidateId, setStrapiCandidateId] = useState(0);
  const [nominationStatusId, setNominationStatusId] = useState(0);
  const [originalStatusId, setOriginalStatusId] = useState(0);
  const [formData, setFormData] = useState({});
  const [hideButtons, setHideButtons] = useState(false);
  const {
    register,
    setValue,
    watch,
    control,
    resetField,
    setError,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    defaultValues: {
      phdAwardedYear: '1959',
    },
    mode: 'onChange',
    resolver: yupResolver(ValidationNomination),
  });

  useFormPersist('formNomination', {
    watch,
    setValue,
    control,
    storage: window.sessionStorage,
  });

  const electionTypeId = parseInt(watch('electionTypeId'));

  useEffect(() => {
    setFormData(watch());
  }, [watch]);

  const nonEmptyCount = objectGetNonEmptyCount(formData);

  useEffect(() => {
    try {
      (async () => {
        if (nominationId && Number.isNaN(electionTypeId)) {
          const draftNominationId =
            typeof nominationId === 'string' && nominationId.includes('d')
              ? parseInt(nominationId.replace(/d/g, ''))
              : nominationId;

          const draftNominations = !userDetails?.roles?.includes('admin')
            ? userDraftNominationArray
            : draftNominationArray;

          let nominationFormValues = '';
          const draftNominationById =
            typeof nominationId === 'string' && nominationId.includes('d')
              ? draftNominations.find((item) => item.id === draftNominationId)
              : null;

          if (draftNominationById) {
            nominationFormValues = JSON.parse(
              draftNominationById.attributes.value,
            );

            Object.keys(nominationFormValues).forEach((item) => {
              setValue(item, nominationFormValues[item]);
            });

            trigger();
          } else {
            const nominationById = nominationArray.find(
              (item) => item.id === nominationId,
            );

            nominationFormValues = nominationById?.attributes?.value
              ? JSON.parse(nominationById.attributes.value)
              : null;

            if (!nominationFormValues) {
              nominationFormValues = await getNominationFormData(
                nominationById,
                organisations,
              );
            }

            if (nominationFormValues) {
              Object.keys(nominationFormValues).forEach((item) => {
                setValue(item, nominationFormValues[item]);
              });

              trigger();
            }

            const { candidateId, nominationStatusTypeId } =
              nominationById.attributes;
            setStrapiCandidateId(candidateId);
            setNominationStatusId(nominationStatusTypeId);
            setOriginalStatusId(nominationStatusTypeId);
          }
        }

        if (electionTypeId === 1 || electionTypeId === 3) {
          // 4. Sectional Committees
          if (watch('sectionalCommitteeId')) {
            setValue('sectionalCommitteeId', 'none');
          }
          if (watch('disciplineDescriptor')) {
            setValue('disciplineDescriptor', []);
          }
          if (watch('overlapCommittee')) {
            setValue('overlapCommittee', 'none');
          }
          if (watch('overlapSectionalCommitteeId')) {
            setValue('overlapSectionalCommitteeId', 'none', {
              shouldValidate: true,
            });
          }
          if (watch('overlapDisciplineDescriptor')) {
            setValue('overlapDisciplineDescriptor', []);
          }
          if (watch('interdisciplinaryCommittee')) {
            setValue('interdisciplinaryCommittee', []);
          }
          if (watch('sectionalCommitteeNotes')) {
            setValue('sectionalCommitteeNotes', '');
          }
          if (watch('overlapCommitteeNotes')) {
            setValue('overlapCommitteeNotes', '');
          }

          // 10. Referees
          if (watch('refereeFive')) {
            setValue('refereeFive', 'none');
          }
          if (watch('refereeFiveFellowId')) {
            setValue('refereeFiveFellowId', 'none');
          }
          if (watch('refereeSix')) {
            setValue('refereeSix', 'none');
          }
          if (watch('refereeSixFellowId')) {
            setValue('refereeSixFellowId', 'none');
          }

          if (nonEmptyCount > 35) {
            trigger();
          }
        } else if (electionTypeId === 2) {
          if (watch('nationalityId')) {
            setValue('nationalityId', []);
          }

          if (nonEmptyCount > 35) {
            trigger();
          }
        }
        if (!userDetails?.roles?.includes('admin')) {
          setHideButtons(disableSubmissionEdit);
        }
      })();
    } catch (error) {
      console.error('Error setting nomination form values:', error);
    }
  }, [
    nominationId,
    setValue,
    electionTypeId,
    resetField,
    trigger,
    watch,
    nonEmptyCount,
    organisations,
    nominationArray,
    userDraftNominationArray,
    draftNominationArray,
    userDetails?.roles,
    disableSubmissionEdit,
  ]);

  const onSubmit = (data) => {
    data.originalStatusId = originalStatusId;
    data.strapiRowId = strapiCandidateId;
    data.userId = userDetails.id;
    // todo: to be removed in case it is edited by admin on behalf of a proposer
    data.userEmail = userDetails.email;

    const formattedData = formatNominationData(data, sectionalCommittees);

    axiosPost('nomination-post', {
      data: formattedData,
    }).then((response) => {
      if (response && response?.status === 200) {
        if (response.data.nominationId) {
          setNominationId(response.data.nominationId);
        }

        const proposerUser = usersArray.find(
          (user) => user.fellowId === formattedData.proposerFellowId,
        );

        const proposerUserId = proposerUser?.id ?? formattedData.userId;

        axiosGet(
          `nomination-drafts?filters[userId][$eq]=${proposerUserId}&filters[name][$eq]=${formattedData.name}&filters[lastName][$eq]=${formattedData.lastName}`,
        ).then((nominationDraftResponse) => {
          if (nominationDraftResponse.data.data.length !== 0) {
            const draftId = nominationDraftResponse.data.data[0].id;
            const nominationDraftHistory =
              nominationDraftResponse.data.data[0].attributes;

            axiosDelete(`nomination-drafts/${draftId}`).then(
              (deleteResponse) => {
                if (deleteResponse.status === 200) {
                  setRefreshNominations(!refreshNominations);
                  setNominationStatusId(1);

                  axiosPost('nomination-draft-histories', {
                    data: nominationDraftHistory,
                  }).then((nominationDraftHistoryResponse) => {
                    if (nominationDraftHistoryResponse.status === 200) {
                      setRefreshNominations(!refreshNominations);
                    }
                  });
                }
              },
            );
          } else {
            setNominationStatusId(1);
            // todo: make refresh of drafts only and specific edited nomination
            setRefreshNominations(!refreshNominations);
          }
        });
      }

      const responseData =
        Object.values(response.data).length !== 0
          ? response.data.candidateId
          : 0;

      setStrapiCandidateId(responseData);
    });
  };

  return (
    <WizardWrapper
      activeMenu="Nomination"
      motherMenu="Home"
      formName="nomination"
      register={register}
      setValue={setValue}
      watch={watch}
      resetField={resetField}
      errors={errors}
      setError={setError}
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formState={formState}
      setFormState={setFormState}
      formStatusId={nominationStatusId}
      trigger={trigger}
      hideButtons={hideButtons}
    />
  );
};

export default WizardNomination;
