import React from 'react';
import PropTypes from 'prop-types';

const Span = ({
  name = '',
  text = '',
  cssClass = 'component-span',
  errors = {},
  required = false,
}) => {
  const className =
    text === 'No error' ? `${cssClass} component-hidden-span` : cssClass;

  let requiredText = ' *';

  if (required === true) {
    requiredText = ' *';

    if (Object.keys(errors).length !== 0 && errors[name] === undefined) {
      requiredText = '';
    }
  } else {
    requiredText = text;
  }

  return <span className={className}>{requiredText}</span>;
};

Span.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  cssClass: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
};

export default Span;
