import axios from 'axios';
import { strapiApiUrl } from './constants';

export const axiosPost = (collectionName, formData) =>
  axios
    .post(`${strapiApiUrl}${collectionName}`, formData, {
      withCredentials: true,
    })
    .then((result) => result)
    .catch((err) => {
      console.log(err);
    });
