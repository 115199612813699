import React from 'react';
import PropTypes from 'prop-types';

import AustralianStatusNationality from './AustralianStatusNationality';
import NominationReferee from './NominationReferee';
import PhdAwardedYear from './PhdAwardedYear';
import RefereeActions from './RefereeActions';
import RefereeTitle from './RefereeTitle';
import SectionalCommittee from './SectionalCommittee';
import IndependentAssessor from './IndependentAssessor';
import RefereeHistory from './RefereeHistory';
import Organisations from './Organisations';
import Genders from './Genders';
import StateTerritories from './StateTerritories';

const Collection = ({
  collectionName,
  item,
  descriptionSet = [],
  className,
  classNameMarginTop,
  divClassName,
  isSubmitted,
}) => {
  if (collectionName === 'phdAwardedYear') {
    return (
      <PhdAwardedYear
        item={item}
        classNameMarginTop={classNameMarginTop}
        divClassName={divClassName}
        isSubmitted={isSubmitted}
      />
    );
  }

  if (collectionName === 'australianStatus') {
    return (
      <AustralianStatusNationality
        item={item}
        classNameMarginTop={classNameMarginTop}
        divClassName={divClassName}
        isSubmitted={isSubmitted}
      />
    );
  }

  if (collectionName === 'sectionalCommittee') {
    return (
      <SectionalCommittee
        item={item}
        descriptionSet={descriptionSet}
        className={className}
        classNameMarginTop={classNameMarginTop}
        isSubmitted={isSubmitted}
      />
    );
  }

  if (collectionName === 'nominationReferee') {
    return (
      <NominationReferee
        item={item}
        className={className}
        classNameMarginTop={classNameMarginTop}
        isSubmitted={isSubmitted}
      />
    );
  }

  if (collectionName === 'independentAssessor') {
    return (
      <IndependentAssessor
        item={item}
        className={className}
        classNameMarginTop={classNameMarginTop}
        isSubmitted={isSubmitted}
      />
    );
  }

  if (collectionName === 'refereeActions') {
    return <RefereeActions item={item} className={className} />;
  }

  if (collectionName === 'refereeHistory') {
    return <RefereeHistory item={item} />;
  }

  if (collectionName === 'refereeTitle') {
    return <RefereeTitle item={item} />;
  }

  if (collectionName === 'organisations') {
    return <Organisations item={item} />;
  }

  if (collectionName === 'genders') {
    return <Genders item={item} />;
  }

  if (collectionName === 'stateTerritories') {
    return <StateTerritories item={item} />;
  }

  return null;
};

Collection.propTypes = {
  collectionName: PropTypes.string,
  item: PropTypes.object,
  descriptionSet: PropTypes.array,
  className: PropTypes.string,
  classNameMarginTop: PropTypes.string,
  divClassName: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default Collection;
