import { tableColumnNamesRefereeAdmin } from './tableColumnNamesRefereeAdmin';
import RefereeStatus from '../jsx/components/RefereeStatus';

export const filteringTableGetTableColumns = (
  tableColumnNames,
  ColumnFilter,
  Cell,
) =>
  Object.keys(tableColumnNames).map((item) => {
    if (item === 'Actions') {
      return {
        Header: item,
        Footer: item,
        accessor: tableColumnNames[item],
        Filter: ColumnFilter,
        Cell,
      };
    }

    if (item === 'Referee Status') {
      return {
        Header: item,
        Footer: item,
        accessor: tableColumnNamesRefereeAdmin[item],
        Filter: ColumnFilter,
        Cell: RefereeStatus,
      };
    }

    return {
      Header: item,
      Footer: item,
      accessor: tableColumnNames[item],
      Filter: ColumnFilter,
    };
  });
