import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CommonCollections } from '../../App';
import { useRefereeCollections } from '../../context/RefereeReportCollections';

const ActionButtons = ({ row }) => {
  const { setRefereeReportId } = useContext(CommonCollections);

  const { setNonAdminRefereeReportId } = useRefereeCollections();

  const { id, refereeStatusId } = row.original;

  const isDraftOrExtension =
    refereeStatusId === 1 || refereeStatusId === 6 || refereeStatusId === 5;
  const buttonIcon = isDraftOrExtension
    ? 'bi bi-pen action-button'
    : 'bi bi-eye view-button';
  const buttonToolTipText = isDraftOrExtension ? 'Edit' : 'View';
  const handleClick = () => {
    setRefereeReportId(id);
    setNonAdminRefereeReportId(id);
  };

  return (
    <Link to="/referee-report" onClick={handleClick}>
      <span title={buttonToolTipText}>
        <i className={buttonIcon} />
      </span>
    </Link>
  );
};

ActionButtons.propTypes = {
  row: PropTypes.object,
};

export default ActionButtons;
