import React, { useState } from 'react';
import PageTitle from '../layouts/PageTitle';

import { useAdminCollections } from '../../context/AdminCollections';
import { useStaticCollections } from '../../context/StaticCollections';
import { getAllNominationData } from '../../utils/getAllNominationData';
import { arrayToCSV } from '../../utils/arrayToCsv';

const SecretaryActions = () => {
  const {
    electionTypes,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    organisations,
    titlesTypes,
    stateTerritories,
    genderTypes,
    australianStatus,
  } = useStaticCollections();
  const { nominationArray } = useAdminCollections();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const downloadNomination = async () => {
    setLoading(true);
    setProgress(0);

    const combinedNomination = [];
    for (const [index, nominationData] of nominationArray.entries()) {
      const nominationFormValues = await getAllNominationData(
        nominationData,
        nominationStatusTypes,
        electionTypes,
        organisations,
        titlesTypes,
        stateTerritories,
        genderTypes,
        australianStatus,
        sectionalCommitteesWithCmse,
      );
      combinedNomination.push(nominationFormValues);
      setProgress(((index + 1) / nominationArray.length) * 100);
    }
    const csv = arrayToCSV(combinedNomination);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'nomination_data.csv');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setLoading(false);
  };

  return (
    <>
      <PageTitle activeMenu="Secretary Actions" motherMenu="Home" />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Download nomination</h4>
        </div>
        <div className="card-body text-left col-12">
          <div className="text-right mt-3">
            {loading ? (
              <div className="height50 progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {Math.round(progress)}%
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => downloadNomination()}
              >
                Download
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecretaryActions;
