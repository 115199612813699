import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PageTitle from '../layouts/PageTitle';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { getUserRoles } from '../../utils/getUserRoles';
import Span from './Forms/Elements/Span';
import { useAdminCollections } from '../../context/AdminCollections';
import { useStaticCollections } from '../../context/StaticCollections';
import { getAllNominationData } from '../../utils/getAllNominationData';
import { arrayToCSV } from '../../utils/arrayToCsv';
import { axiosGet } from '../../utils/axiosGet';
import { axiosPut } from '../../utils/axiosPut';
import ModalConfirmation from './ModalConfirmation';
import { formatDraftNominationData } from '../../utils/formatDraftNominationData';

const AdminActions = () => {
  const { userDetails, setUserDetails } = useLoggedInUserContextProvider();
  const {
    electionTypes,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    usersArray,
    organisations,
    titlesTypes,
    stateTerritories,
    genderTypes,
    australianStatus,
  } = useStaticCollections();
  const { nominationArray } = useAdminCollections();

  const [selectedUser, setSelectedUser] = useState(null);
  const [notification, setNotification] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableSubmissionEdit, setDisableSubmissionEdit] = useState(false);
  const [disableNewSubmission, setDisableNewSubmission] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [loading, setLoading] = useState(false); // Loading state for download
  const [progress, setProgress] = useState(0); // Progress state

  const selectOptions = usersArray.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  const handleChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  useEffect(() => {
    axiosGet(`admin-action`).then((response) => {
      setDisableSubmissionEdit(
        response.data.data.attributes.disableSubmissionEdit,
      );
    });
  }, []);

  useEffect(() => {
    axiosGet(`admin-action`).then((response) => {
      if (response?.data?.data?.attributes?.disableNewSubmission) {
        setDisableNewSubmission(
          response.data.data.attributes.disableNewSubmission,
        );
      }
    });
  }, []);

  const masqueradeAsUser = async (selectedUserId) => {
    if (!selectedUserId) {
      return;
    }

    const masqueradeUser = usersArray.find(
      (user) => user.id === selectedUserId,
    );

    if (typeof masqueradeUser === 'object') {
      const userRoles = await getUserRoles(masqueradeUser);
      const hasAdminOrSuperAdmin = userRoles.some(
        (role) => role === 'admin' || role === 'super-admin',
      );

      masqueradeUser.email = userDetails.email;

      if (!hasAdminOrSuperAdmin) {
        setUserDetails({
          ...masqueradeUser,
          roles: userRoles,
        });

        setNotification('Updated');
      } else {
        setErrorMessage('Masquerading as an admin is not allowed');
      }
    }
  };

  const downloadNomination = async () => {
    setLoading(true);
    setProgress(0);
    const combinedNomination = [];
    for (const [index, nominationData] of nominationArray.entries()) {
      const nominationFormValues = await getAllNominationData(
        nominationData,
        nominationStatusTypes,
        electionTypes,
        organisations,
        titlesTypes,
        stateTerritories,
        genderTypes,
        australianStatus,
        sectionalCommitteesWithCmse,
      );
      combinedNomination.push(nominationFormValues);
      setProgress(((index + 1) / nominationArray.length) * 100);
    }

    // Get non validated draft nomination
    const draftNomination = await axiosGet('nomination-drafts');
    for (const draftNom of draftNomination.data.data) {
      const formattedData = await formatDraftNominationData(
        JSON.parse(draftNom.attributes.value),
        nominationStatusTypes,
        electionTypes,
        titlesTypes,
        stateTerritories,
        genderTypes,
        australianStatus,
        sectionalCommitteesWithCmse,
      );
      formattedData.id = draftNom.id;
      formattedData.createdAt = draftNom.attributes.createdAt;
      formattedData.updatedAt = draftNom.attributes.updatedAt;
      combinedNomination.push(formattedData);
    }
    const csv = arrayToCSV(combinedNomination);

    const csvWithBom = `\uFEFF${csv}`;
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'nomination_data.csv');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    setLoading(false);
  };

  const saveAdminAction = async () => {
    try {
      await axiosPut(`admin-action`, {
        data: {
          disableSubmissionEdit,
          disableNewSubmission,
        },
      }).then((response) => {
        if (response.status === 200) {
          setNotification('Admin action saved successfully.');
        }
      });
    } catch (error) {
      console.error('Error saving admin action buttons', error);
    }
  };

  const handleSaveClick = () => {
    setShowModal(true); // Show modal on save click
  };

  const handleConfirmSelect = () => {
    saveAdminAction();
    setShowModal(false); // Close modal after save
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageTitle
        activeMenu="Admin Actions"
        motherMenu="Home"
        notificationMessage={notification}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Masquerade</h4>
        </div>
        <div className="card-body text-left col-6">
          <label htmlFor="user-select">Masquerade as user</label>
          <Select
            id="user-select"
            value={selectedUser}
            onChange={handleChange}
            options={selectOptions}
          />
          <div className="mt-xl-2">
            <Span name="user-select" text={errorMessage} />
          </div>
          <div className="text-right mt-3">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => masqueradeAsUser(selectedUser.value)}
            >
              Masquerade
            </button>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Download nomination</h4>
        </div>
        <div className="card-body text-left col-12">
          <div className="text-right mt-3">
            {loading ? (
              <div className="height50 progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {Math.round(progress)}%
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => downloadNomination()}
              >
                Download
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            Enable/Disable nomination functionality
          </h4>
        </div>
        <div className="card-body text-left col-6">
          <div className="form-check form-switch mt-3">
            <label className="form-check-label" htmlFor="toggleSubmit">
              Disable New Submission
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleSubmit"
              checked={disableNewSubmission}
              onChange={() => setDisableNewSubmission(!disableNewSubmission)}
            />
          </div>
          <div className="form-check form-switch mt-3">
            <label className="form-check-label" htmlFor="toggleSubmit">
              Disable Submission Edits
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="toggleSubmit"
              checked={disableSubmissionEdit}
              onChange={() => setDisableSubmissionEdit(!disableSubmissionEdit)}
            />
          </div>
          <div className="mt-3">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText="Are you sure you want to save?"
        handleConfirmSelect={handleConfirmSelect}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default AdminActions;
