import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../Wizard/WizardWrapper';
import { fetchData } from '../../../../utils/fetchData';

const Radio = ({ name, options, setSelectedValue }) => {
  const { register, setValue, watch, formState } = useContext(Context);
  const selectedValue = parseInt(watch(name));
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    if (options === 'referee-actions') {
      if (options === 'referee-actions') {
        let filterQueryString =
          '?filters[$and][0][name][$ne]=Draft&filters[$and][1][name][$ne]=Use%20Existing%20Report';
        if (formState.attributes) {
          if (formState.attributes.report && formState.attributes.report.data) {
            filterQueryString = '?filters[name][$ne]=Draft';
          }
        }
        fetchData(`${options}${filterQueryString}`, setCurrentOptions);
      } else {
        setCurrentOptions(options);
      }
    }
  }, [options, formState.attributes]);

  const handleChange = (value) => {
    setValue(name, value);
  };
  useEffect(() => {
    setSelectedValue(selectedValue);
  }, [selectedValue, setSelectedValue]);

  return (
    <div className="">
      {currentOptions.map((option) => (
        <div key={option.id} className="form-check">
          <input
            type="radio"
            name={name}
            value={option.id}
            className="form-check-input"
            checked={selectedValue === option.id}
            onChange={() => handleChange(option.id)}
            {...register(name)}
          />
          <label className="form-check-label">{option.attributes.name}</label>
        </div>
      ))}
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.string, // 'referee-actions' or other string identifiers
    PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  ]).isRequired,
  setSelectedValue: PropTypes.func,
};

export default Radio;
