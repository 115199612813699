import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { getUserRoles } from '../utils/getUserRoles';

export const LoggedInUserContext = createContext();

export const LoggedInUserContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [refreshUserDetails, setRefreshUserDetails] = useState(false);

  useEffect(() => {
    axiosGet('jwt-get-user-details').then((response) => {
      if (response && response?.status === 200) {
        if (response.data && Object.values(response.data).length > 0) {
          const responseUserDetails = response.data;
          const fetchUserRoles = async () => {
            const userRoles = await getUserRoles(responseUserDetails);

            setUserDetails({
              ...responseUserDetails,
              roles: userRoles,
            });
          };

          if (responseUserDetails?.id) {
            fetchUserRoles();
          }
        }
      } else {
        setUserDetails(null);
      }
    });
  }, [refreshUserDetails]);

  return (
    <LoggedInUserContext.Provider
      value={useMemo(
        () => ({
          refreshUserDetails,
          setRefreshUserDetails,
          userDetails,
          setUserDetails,
        }),
        [
          refreshUserDetails,
          setRefreshUserDetails,
          userDetails,
          setUserDetails,
        ],
      )}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
};

LoggedInUserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLoggedInUserContextProvider = () =>
  useContext(LoggedInUserContext);
