import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getWordCount } from '../../../../utils/getWordCount';
import { Context } from '../Wizard/WizardWrapper';

import Paragraph from './Paragraph';

const TextArea = ({
  name,
  numberOfWords = 200,
  numberOfRows = 8,
  className = '',
  placeholder = '',
}) => {
  const { register, setValue, watch } = useContext(Context);

  const setFormattedContent = React.useCallback(
    (text, elementName) => {
      let textContent = text;
      if (text === ' ') {
        textContent = text.trim();
      }

      if (getWordCount(textContent) < numberOfWords) {
        const slicedContent = textContent.slice(0, textContent.length);
        setValue(elementName, slicedContent);
        return slicedContent;
      }
      if (getWordCount(textContent) === numberOfWords) {
        const slicedContent = textContent.slice(0, textContent.length).trim();
        setValue(elementName, slicedContent);
        return slicedContent;
      }
      if (numberOfWords === 0) {
        const slicedContent = textContent.slice(0, textContent.length);
        setValue(elementName, slicedContent);
        return slicedContent;
      }

      if (getWordCount(textContent) > numberOfWords) {
        const wordsInText = textContent.split(' ');
        const joinedWords = wordsInText
          .slice(0, numberOfWords - 1)
          .join(' ')
          .trim();

        const slicedContent = joinedWords.slice(0, joinedWords.length);
        setValue(elementName, slicedContent);

        return joinedWords;
      }
    },
    [setValue, numberOfWords],
  );

  return (
    <>
      <textarea
        name={name}
        className={`form-control component-text-area ${className}`}
        rows={numberOfRows}
        placeholder={placeholder}
        {...register(name, {
          onChange: () => setFormattedContent(watch(name), name),
        })}
      />
      {numberOfWords > 0 ? (
        <Paragraph
          textContent={
            watch(name) !== undefined
              ? `${getWordCount(watch(name))}/${numberOfWords} words`
              : ''
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  numberOfWords: PropTypes.number,
  numberOfRows: PropTypes.number,
  placeholder: PropTypes.string,
};

export default TextArea;
