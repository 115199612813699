import * as yup from 'yup';

const defaultMessage = 'Required Field';
yup.setLocale({
  mixed: {
    required: defaultMessage,
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Must be at least ${min} characters',
    email: 'Invalid email',
  },
  number: {
    integer: 'Must be an integer',
  },
});

const trimString = (value) =>
  typeof value === 'string' ? value.trim() : value;

const schema = yup.object().shape({
  refereeSeven: yup.number().required().typeError(defaultMessage),
  refereeSevenFellowId: yup.object().when('refereeSeven', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeSevenId: yup.object().when('refereeSeven', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeSevenTitleId: yup.number().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeSevenName: yup.string().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeSevenLastName: yup.string().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeSevenEmail: yup.string().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeSevenCountryId: yup.object().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeSevenOrganisation: yup
    .string()
    .when(['nonAasRefereeSevenId', 'refereeSevenEmailEducationalInstitution'], {
      is: (nonAasRefereeSevenId, refereeSevenEmailEducationalInstitution) =>
        nonAasRefereeSevenId &&
        typeof nonAasRefereeSevenId.value === 'number' &&
        nonAasRefereeSevenId.value === 0 &&
        !refereeSevenEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeSevenMemberships: yup.string().when('nonAasRefereeSevenId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  // refereeEight: yup.number().required().typeError(defaultMessage),
  refereeEightFellowId: yup.object().when('refereeEight', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeEightId: yup.object().when('refereeEight', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeEightTitleId: yup.number().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeEightName: yup.string().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeEightLastName: yup.string().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeEightEmail: yup.string().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeEightCountryId: yup.object().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeEightOrganisation: yup
    .string()
    .when(['nonAasRefereeEightId', 'refereeEightEmailEducationalInstitution'], {
      is: (nonAasRefereeEightId, refereeEightEmailEducationalInstitution) =>
        nonAasRefereeEightId &&
        typeof nonAasRefereeEightId.value === 'number' &&
        nonAasRefereeEightId.value === 0 &&
        !refereeEightEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeEightMemberships: yup.string().when('nonAasRefereeEightId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),

  // refereeNine: yup.number().required().typeError(defaultMessage),
  refereeNineFellowId: yup.object().when('refereeNine', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 1,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  nonAasRefereeNineId: yup.object().when('refereeNine', {
    is: (val) => val !== undefined && typeof val === 'number' && val === 0,
    then: yup.object().required().nullable(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeNineTitleId: yup.number().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.number().required().typeError(defaultMessage),
    otherwise: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
  }),
  refereeNineName: yup.string().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeNineLastName: yup.string().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).min(2).max(40).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeNineEmail: yup.string().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().max(254).email().required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
  refereeNineCountryId: yup.object().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.object().required(),
    otherwise: yup.object().notRequired().nullable(),
  }),
  refereeNineOrganisation: yup
    .string()
    .when(['nonAasRefereeNineId', 'refereeNineEmailEducationalInstitution'], {
      is: (nonAasRefereeNineId, refereeNineEmailEducationalInstitution) =>
        nonAasRefereeNineId &&
        typeof nonAasRefereeNineId.value === 'number' &&
        nonAasRefereeNineId.value === 0 &&
        !refereeNineEmailEducationalInstitution,
      then: yup.string().transform(trimString).required(),
      otherwise: yup.string().notRequired().nullable(),
    }),
  refereeNineMemberships: yup.string().when('nonAasRefereeNineId', {
    is: (item) =>
      item !== undefined && typeof item.value === 'number' && item.value === 0,
    then: yup.string().transform(trimString).max(1500).required(),
    otherwise: yup.string().notRequired().nullable(),
  }),
});

export default schema;
