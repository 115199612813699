import React from 'react';
import PropTypes from 'prop-types';

const CustomIcon = ({
  isSubmitted,
  className = 'bi bi-check-circle-fill',
  fieldError = {},
  required,
}) => {
  if (isSubmitted && required && Object.keys(fieldError).length === 0) {
    return <i className={className} />;
  }

  return null;
};

CustomIcon.propTypes = {
  isSubmitted: PropTypes.bool,
  className: PropTypes.string,
  fieldError: PropTypes.object,
  required: PropTypes.bool,
};

export default CustomIcon;
