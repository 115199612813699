import React from 'react';
import PropTypes from 'prop-types';

const CardSummary = ({
  title,
  spanText = 'empty',
  progressBarNumber,
  totalNumber = 1,
  description,
  barColorClass = 'bg-orange',
  numberOfRows = 4,
}) => {
  const barPercentage = (progressBarNumber * 100) / totalNumber;

  return (
    <div className={`col-xl-${numberOfRows} col-lg-6 col-sm-6`}>
      <div className="widget-stat card">
        <div className="card-body p-4">
          <h4 className="card-title">{title}</h4>
          <span
            className={`badge badge-info mb-2  ${
              spanText === 'empty' ? 'bg-white' : ''
            }`}
          >
            {spanText}
          </span>
          <h3>
            {progressBarNumber}
            {totalNumber !== 1 ? ` out of ${totalNumber}` : ''}
          </h3>
          <div className="progress mb-2">
            <div
              className={`progress-bar progress-animated ${barColorClass}`}
              style={{ width: `${barPercentage}%` }}
            />
          </div>
          <small>{description}</small>
        </div>
      </div>
    </div>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string,
  spanText: PropTypes.string,
  progressBarNumber: PropTypes.number,
  description: PropTypes.string,
  totalNumber: PropTypes.number,
  barColorClass: PropTypes.string,
  numberOfRows: PropTypes.number,
};

export default CardSummary;
