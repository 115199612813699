import React, { useContext, useMemo } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';
import { Context } from '../Wizard/WizardWrapper';

const nationalities = require('i18n-nationality');
nationalities.registerLocale(require('i18n-nationality/langs/en.json'));

const NationalitySelector = ({ name }) => {
  const { control, setValue, watch } = useContext(Context);

  const options = useMemo(
    () =>
      Object.entries(nationalities.getNames('en')).map(
        ([countryCode, nationality]) => ({
          value: countryCode,
          label: nationality,
        }),
      ),
    [],
  );
  const handleChange = (event, fieldName) => {
    const eventTargetName =
      event.target !== undefined ? event.target.name : fieldName;

    const eventTargetValue =
      event.target !== undefined ? parseInt(event.target.value) : event;

    setValue(eventTargetName, eventTargetValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          options={options}
          onChange={(e) => {
            field.onChange(e);
            // custom change event
            setValue(name, e, { shouldValidate: true });
            handleChange(e, name);
          }}
          value={watch(name)}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              minHeight: '3rem',
              borderRadius: '0.5rem',
              borderColor: '#B3B3B3',
            }),
          }}
          className={`${name} mb-3`}
          isMulti
        />
      )}
    />
  );
};

NationalitySelector.propTypes = {
  name: PropTypes.string,
};

export default NationalitySelector;
