export const filteringTableFormatDraftNomination = (draftNominationArray) =>
  draftNominationArray
    .map((draftNomination) => {
      let draftAttributes;
      try {
        draftAttributes = JSON.parse(draftNomination.attributes.value);

        draftAttributes.value = draftNomination.attributes.value;
        draftAttributes.createdAt = draftNomination.attributes.createdAt;
        draftAttributes.updatedAt = draftNomination.attributes.updatedAt;
        draftAttributes.publishedAt = draftNomination.attributes.publishedAt;
        draftAttributes.nominationStatusTypeId = 1;
        draftAttributes.candidate = `${draftNomination.attributes.name} ${draftNomination.attributes.lastName}`;

        return {
          id: draftNomination.id,
          userId: draftNomination.attributes.userId,
          attributes: draftAttributes,
        };
      } catch (error) {
        console.error(
          `Failed to parse and modify JSON for draft nomination with id ${draftNomination.id}:`,
          error,
        );
        return null;
      }
    })
    .filter(Boolean);
