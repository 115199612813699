import { arrayToString } from './arrayToString';
import { arraySortByUpdatedAt } from './arraySortByUpdatedAt';

export const tableRowsValuesNomination = (
  resultData,
  electionTypes,
  nominationStatusTypes,
  sectionalCommitteesWithCmse,
) =>
  arraySortByUpdatedAt(resultData).map((item) => {
    const disciplineDescriptorLabels = arrayToString(
      item.attributes.disciplineDescriptor,
    );
    const overlapDisciplineDescriptorLabels = arrayToString(
      item.attributes.overlapDisciplineDescriptor,
    );
    const interdisciplinaryCommitteeLabels = arrayToString(
      item.attributes.interdisciplinaryCommittee,
    );

    let sectionalCommitteeKey = item.attributes.sectionalCommitteeId;
    if (
      item.attributes.electionTypeId &&
      parseInt(item.attributes.electionTypeId) !== 2
    ) {
      sectionalCommitteeKey = 14;
    }

    return {
      nominationId: item.id,
      candidateId: item.attributes.candidateId,
      candidate: item.attributes.candidate,
      electionTypeId: electionTypes[item.attributes.electionTypeId],
      nominationStatusType:
        nominationStatusTypes[item.attributes.nominationStatusTypeId],
      nominationStatusTypeId: item.attributes.nominationStatusTypeId,
      sectionalCommitteeId:
        item.attributes.deprecatedSectionalCommitteeLabel ??
        sectionalCommitteesWithCmse[sectionalCommitteeKey] ??
        '',
      overlapSectionalCommitteeId:
        sectionalCommitteesWithCmse[
          item.attributes.overlapSectionalCommitteeId
        ],
      overlapDisciplineDescriptor: overlapDisciplineDescriptorLabels,
      disciplineDescriptor: disciplineDescriptorLabels,
      interdisciplinaryCommittee: interdisciplinaryCommitteeLabels,

      createdAt: item.attributes.createdAt,
      updatedAt: item.attributes.publishedAt,
      publishedAt: item.attributes.updatedAt,
    };
  });
