import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import CustomIcon from '../Elements/CustomIcon';
import Label from '../Elements/Label';
import Select from '../Elements/Select';

import CountrySelector from '../Elements/CountrySelector';
import TextArea from '../Elements/TextArea';
import Span from '../Elements/Span';
import Markdown from '../Elements/Markdown';
import { Context } from '../Wizard/WizardWrapper';
import InputText from '../Elements/InputText';
import InputEmail from '../Elements/InputEmail';
import { stringUcFirst } from '../../../../utils/stringUcFirst';

const NominationReferee = ({
  item,
  className,
  classNameMarginTop,
  isSubmitted,
}) => {
  const { setValue, watch, resetField, errors } = useContext(Context);
  const watchIsFellow = watch(item.name);
  const watchFellowId = watch(`${item.name}FellowId`);
  const watchNonAasRefereeId = watch(`nonAas${stringUcFirst(item.name)}Id`);

  useEffect(() => {
    const nonAasReferee = [
      'TitleId',
      'Name',
      'LastName',
      'Email',
      'CountryId',
      'Memberships',
    ];
    // Sets validation error to first time not listed nonAasReferee is loaded
    if (watchNonAasRefereeId && parseInt(watchNonAasRefereeId.value) === 0) {
      nonAasReferee.forEach((fieldName) => {
        if (!watch(`${item.name}${fieldName}`)) {
          errors[`${item.name}${fieldName}`] = {};
          errors[`${item.name}${fieldName}`].message = 'This field is required';
          errors[`${item.name}${fieldName}`].type = 'required';
          errors[`${item.name}${fieldName}`].ref = {};
          errors[`${item.name}${fieldName}`].ref.name =
            `${item.name}${fieldName}`;
        }
      });
    }

    if (
      (watchNonAasRefereeId && parseInt(watchIsFellow) === 1) ||
      (watchNonAasRefereeId && parseInt(watchNonAasRefereeId.value) > 0)
    ) {
      if (watchFellowId === '') {
        setValue(`${item.name}FellowId`, '', { shouldValidate: true });
      }

      nonAasReferee.forEach((fieldName) => {
        if (watch(`${item.name}${fieldName}`)) {
          resetField(`${item.name}${fieldName}`);
        }
      });

      if (watch(`${item.name}EmailEducationalInstitution`)) {
        resetField(`${item.name}EmailEducationalInstitution`);
      }

      if (watch(`${item.name}Organisation`)) {
        resetField(`${item.name}Organisation`);
      }
    }

    if (parseInt(watchIsFellow) === 0) {
      resetField(`${item.name}FellowId`);
    }

    if (parseInt(watchIsFellow) === 1) {
      resetField(`nonAas${stringUcFirst(item.name)}Id`);
    }
  }, [
    item.name,
    setValue,
    watch,
    resetField,
    errors,
    watchIsFellow,
    watchFellowId,
    watchNonAasRefereeId,
  ]);

  if (
    ['refereeFive', 'refereeSix'].includes(item.name) &&
    (parseInt(watch('electionTypeId')) === 1 ||
      parseInt(watch('electionTypeId')) === 3)
  ) {
    if (watch(item.name) !== null && watch(item.name) !== undefined) {
      resetField(item.name);
      resetField(`${item.name}FellowId`);
    }

    return null;
  }

  const classNameMarginBottom = Number.isInteger(parseInt(watch(item.name)))
    ? ''
    : 'mb-lg-5';

  const nonAasRefereeKey = `nonAas${stringUcFirst(item.name)}Id`;

  return (
    <React.Fragment key={`referee-field-collection-${item.name}`}>
      <Markdown
        className="col-xl-12 col-lg-12 mt-xl-5 card-header"
        text={
          item.label !== null && item.label !== undefined
            ? `**Referee ${item.label.match(/\d+/g)[0]}**`
            : '**Referee**'
        }
      />

      <div
        className={`${className} ${classNameMarginTop} ${classNameMarginBottom}`}
      >
        <div className="form-group">
          <Label
            className="mb-3"
            label={item.label}
            name={item.name}
            errors={errors}
            required={item.required}
          />

          <CustomIcon
            isSubmitted={isSubmitted}
            fieldError={errors[item.name]}
            required={item.required}
          />

          <Select options="binaries" name={item.name} />

          <Span text={errors[item.name] ? errors[item.name].message : ''} />
        </div>

        <Markdown
          className="col-xl-12 col-lg-12 mt-3"
          text={item.description}
        />
      </div>

      <div />

      {parseInt(watch(item.name)) === 0 ? (
        <div
          className={`${className} ${classNameMarginTop} ${watchNonAasRefereeId && watchNonAasRefereeId.value === 0 ? '' : 'mb-lg-5'}`}
        >
          <div className="form-group">
            <Label
              className="mb-3"
              label="Select existing non AAS referee"
              name={nonAasRefereeKey}
              errors={errors}
              required={item.required}
            />

            <CustomIcon
              isSubmitted={isSubmitted}
              fieldError={errors[nonAasRefereeKey]}
              required={item.required}
            />

            <Select options="non-aas-referees" name={nonAasRefereeKey} />

            <div className="col-xl-12 col-lg-12 mt-3">
              <p>
                If not listed, scroll to the bottom to select -- My non AAS
                referee is not listed --
              </p>
            </div>

            {errors[nonAasRefereeKey] ? (
              <Span text={errors[nonAasRefereeKey].message} />
            ) : (
              ''
            )}
          </div>

          <Markdown
            className="col-xl-12 col-lg-12 mt-3"
            text={item.description}
          />
        </div>
      ) : (
        ''
      )}

      {parseInt(watch(`nonAas${stringUcFirst(item.name)}Id`)?.value) === 0 ? (
        <>
          <div className={`${className}  ${classNameMarginBottom}`}>
            <div className="form-group mt-2">
              <Label
                className="mt-5"
                label="Title:"
                name={`${item.name}TitleId`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}TitleId`]}
                required={item.required}
              />

              <Select options="titles" name={`${item.name}TitleId`} />

              {errors[`${item.name}TitleId`] ? (
                <Span text={errors[`${item.name}TitleId`].message} />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-2">
              <Label
                label="Given Name"
                name={`${item.name}Name`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}Name`]}
                required={item.required}
              />

              <InputText name={`${item.name}Name`} />

              {errors[`${item.name}Name`] ? (
                <Span text={errors[`${item.name}Name`].message} />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-2">
              <Label
                label="Last Name"
                name={`${item.name}LastName`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}LastName`]}
                required={item.required}
              />

              <InputText name={`${item.name}LastName`} />

              {errors[`${item.name}LastName`] ? (
                <Span text={errors[`${item.name}LastName`].message} />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-2">
              <Label
                label="Email"
                name={`${item.name}Email`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}Email`]}
                required={item.required}
              />

              <InputEmail name={`${item.name}Email`} />

              {errors[`${item.name}Email`] ? (
                <Span text={errors[`${item.name}Email`].message} />
              ) : (
                ''
              )}

              {!watch(`${item.name}EmailEducationalInstitution`) &&
              watch(`${item.name}Email`) ? (
                <div>
                  <Label
                    className="mt-3"
                    label="Institution / organisation"
                    name={`${item.name}Organisation`}
                    errors={errors}
                    required={item.required}
                  />
                  <InputText name={`${item.name}Organisation`} />
                  {errors[`${item.name}Organisation`] ? (
                    <Span text={errors[`${item.name}Organisation`].message} />
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className={`${className} ${classNameMarginTop}`}>
            <div className="form-group mt-2">
              <Label
                label="Country"
                name={`${item.name}CountryId`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}CountryId`]}
                required={item.required}
              />

              <CountrySelector name={`${item.name}CountryId`} />

              {errors[`${item.name}CountryId`] ? (
                <Span text={errors[`${item.name}CountryId`].message} />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={`col-lg-12 ${classNameMarginTop}`}>
            <div className="form-group mt-3 mb-xl-5">
              <Label
                label="Academy membership/s (or equivalent)"
                name={`${item.name}Memberships`}
                errors={errors}
                required={item.required}
              />

              <CustomIcon
                isSubmitted={isSubmitted}
                fieldError={errors[`${item.name}Memberships`]}
                required={item.required}
              />

              <Markdown
                key="elements-description-markdown-1"
                className="col-xl-12 col-lg-12"
                text="eg., FAA FTSE FASc FMRS"
              />

              <TextArea
                name={`${item.name}Memberships`}
                className={className}
              />

              {errors[`${item.name}Memberships`] ? (
                <Span text={errors[`${item.name}Memberships`].message} />
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      {parseInt(watch(item.name)) === 1 ? (
        <div className={`${className} ${classNameMarginTop} mb-lg-5`}>
          <div className="form-group">
            <Label
              className="mb-3"
              label="Select existing AAS fellow"
              name={`${item.name}FellowId`}
              errors={errors}
              required={item.required}
            />

            <CustomIcon
              isSubmitted={isSubmitted}
              fieldError={errors[`${item.name}FellowId`]}
              required={item.required}
            />

            <Select options="fellows" name={`${item.name}FellowId`} />

            {errors[`${item.name}FellowId`] ? (
              <Span text={errors[`${item.name}FellowId`].message} />
            ) : (
              ''
            )}
          </div>

          <Markdown
            className="col-xl-12 col-lg-12 mt-3"
            text={item.description}
          />
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

NominationReferee.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  classNameMarginTop: PropTypes.string,
  isSubmitted: PropTypes.bool,
};

export default NominationReferee;
