import {
  Suspense,
  useEffect,
  createContext,
  useState,
  useMemo,
  lazy,
} from 'react';
import CacheBuster from 'react-cache-buster';
import ReactLoading from 'react-loading';

/// Components
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import packageJson from '../package.json';
import Index from './jsx';
// action
import { isAuthenticated as authenticatedFunc } from './store/selectors/AuthSelectors';
/// Style
import './vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './css/style.css';
import { loginWithToken } from './store/actions/AuthActions';

// Utils
import { axiosGet } from './utils/axiosGet';

import { AdminCollectionsProvider } from './context/AdminCollections';
import { ProposerCollectionsProvider } from './context/ProposerCollections';
import { RefereeCollectionsProvider } from './context/RefereeReportCollections';
import { StaticCollectionsProvider } from './context/StaticCollections';
import { AdminActionCollectionsProvider } from './context/AdminActionCollections';
import { useLoggedInUserContextProvider } from './context/LoggedInUserContext';

const { version } = packageJson;

const Login = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    }),
);

export const CommonCollections = createContext();
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const App = () => {
  const [refereeReports, setRefereeReports] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const loginToken = urlParams.get('loginToken');

  // Methods
  const [nominationId, setNominationId] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [refereeReportId, setRefereeReportId] = useState(null);
  const [refreshDynamicCollections, setRefreshDynamicCollections] =
    useState(false);
  const [refreshRefereeReports, setRefreshRefereeReports] = useState(false);

  const { userDetails, refreshUserDetails, setRefreshUserDetails } =
    useLoggedInUserContextProvider();

  // api url parameters
  const paginationLimit = '&pagination[limit]=6000';

  useEffect(() => {
    if (loginToken) {
      dispatch(
        loginWithToken(
          loginToken,
          navigate,
          refreshUserDetails,
          setRefreshUserDetails,
        ),
      );
    }
  }, [
    dispatch,
    navigate,
    loginToken,
    refreshUserDetails,
    setRefreshUserDetails,
  ]);

  useEffect(() => {
    if (userDetails && refereeReportId) {
      axiosGet(
        `referee-reports/${refereeReportId}?populate[report][populate][0]=report`,
      ).then((response) => {
        if (response) {
          const index = refereeReports.findIndex(
            (report) => report.id === response.data.data.id,
          );
          const updatedReports = [...refereeReports];
          updatedReports[index] = response.data.data;
          setRefereeReports(updatedReports);
        } else {
          // Removes deleted report when declining referee
          const updatedReports = refereeReports.filter(
            (report) => report.id !== refereeReportId,
          );

          setRefereeReports(updatedReports);
        }
      });
    }
  }, [refreshRefereeReports, refereeReportId, refereeReports, userDetails]);

  useEffect(() => {
    if (userDetails) {
      axiosGet(
        `referee-reports?populate[report][populate][0]=report${paginationLimit}`,
      ).then((response) => {
        setRefereeReports(response.data.data);
      });
    }
  }, [userDetails, refreshRefereeReports]);

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled
      isVerboseMode={false}
      loadingComponent={
        <div className="loading-container">
          <ReactLoading
            className="text-center"
            type="spin"
            color="#3D4152"
            height={70}
            width={70}
          />
        </div>
      }
    >
      <AdminCollectionsProvider>
        <ProposerCollectionsProvider>
          <RefereeCollectionsProvider>
            <StaticCollectionsProvider>
              <AdminActionCollectionsProvider>
                <CommonCollections.Provider
                  value={useMemo(
                    () => ({
                      refereeReports,

                      nominationId,
                      setNominationId,
                      refreshDynamicCollections,
                      setRefreshDynamicCollections,
                      refreshRefereeReports,
                      setRefreshRefereeReports,
                      refereeReportId,
                      setRefereeReportId,
                      isFormSubmitted,
                      setIsFormSubmitted,
                    }),
                    [
                      refereeReports,

                      nominationId,
                      setNominationId,
                      refreshDynamicCollections,
                      setRefreshDynamicCollections,
                      refreshRefereeReports,
                      setRefreshRefereeReports,
                      refereeReportId,
                      setRefereeReportId,
                      isFormSubmitted,
                      setIsFormSubmitted,
                    ],
                  )}
                >
                  <div className="vh-100">
                    <Suspense
                      fallback={
                        <div id="preloader">
                          <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1" />
                            <div className="sk-child sk-bounce2" />
                            <div className="sk-child sk-bounce3" />
                          </div>
                        </div>
                      }
                    >
                      {userDetails ? <Index /> : <Login />}
                    </Suspense>
                  </div>
                </CommonCollections.Provider>
              </AdminActionCollectionsProvider>
            </StaticCollectionsProvider>
          </RefereeCollectionsProvider>
        </ProposerCollectionsProvider>
      </AdminCollectionsProvider>
    </CacheBuster>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: authenticatedFunc(state),
});

// export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
