import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import ButtonIcon from './Forms/Elements/ButtonIcon';

import ModalConfirmation from './ModalConfirmation';
import { handleConfirmSelect } from '../../utils/handleConfirmSelect';
import { CommonCollections } from '../../App';

import { filteringTableGetButtonIcon } from '../../utils/filteringTableGetButtonIcon';
import { filteringTableGetButtonToolTipText } from '../../utils/filteringTableGetButtonToolTipText';
import { useAdminCollections } from '../../context/AdminCollections';

const AdminNominationCell = ({ row }) => {
  const { refreshNominations, setRefreshNominations } = useAdminCollections();
  const { setNominationId } = useContext(CommonCollections);
  const { nominationStatusTypeId, nominationId, candidate } = row.original;
  const [nominationStatusType, setNominationStatusType] = useState('');
  const [showModal, setShowModal] = useState(false);

  const buttonIcon = filteringTableGetButtonIcon(nominationStatusTypeId);
  const buttonToolTipText = filteringTableGetButtonToolTipText(
    nominationStatusTypeId,
    candidate,
  );

  const handleClick = () => {
    setNominationId(nominationId);
  };

  const suspendNomination = () => {
    setNominationStatusType('suspend');
    setShowModal(true);
  };

  const withdrawNomination = () => {
    setNominationStatusType('withdraw');
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return [4, 5, 6, 7].includes(nominationStatusTypeId) ? (
    <Link
      to={
        nominationStatusTypeId === 1
          ? '/application-administration'
          : '/form-view'
      }
      onClick={handleClick}
    >
      <span title={buttonToolTipText}>
        <i className={buttonIcon} />
      </span>
    </Link>
  ) : (
    <>
      <Link to="/application-administration" onClick={handleClick}>
        <span title={`Edit ${candidate}`}>
          <i className="bi bi-pen action-button" />
        </span>
      </Link>

      {nominationStatusTypeId !== 1 && (
        <Link to="/form-view" onClick={handleClick}>
          <span title={`View ${candidate}`}>
            <i className="bi bi-eye view-button" />
          </span>
        </Link>
      )}

      {typeof nominationId === 'string' && (
        <>
          <ButtonIcon
            bootstrapIcon="bi-trash"
            backgroundColor="bg-danger"
            handleClick={suspendNomination}
            handleKeyPress={suspendNomination}
            tooltipText={`Delete ${candidate}`}
          />
          <ModalConfirmation
            showModal={showModal}
            handleCancelSelect={handleCancelSelect}
            bodyText={`Are you sure you want to **delete ${candidate}'s non validated draft** nomination?`}
            handleConfirmSelect={handleConfirmSelect}
            rowId={`${nominationId}`}
            apiIdentifier="nomination-drafts"
            refreshCollections={refreshNominations}
            setRefreshCollections={setRefreshNominations}
            setShowModal={setShowModal}
          />
        </>
      )}

      {typeof nominationId === 'number' && (
        <>
          <ButtonIcon
            bootstrapIcon="bi-archive"
            backgroundColor="bg-warning"
            handleClick={withdrawNomination}
            handleKeyPress={withdrawNomination}
            tooltipText={`Withdraw ${candidate}`}
          />
          <ButtonIcon
            bootstrapIcon="bi-emoji-neutral"
            backgroundColor="bg-danger"
            handleClick={suspendNomination}
            handleKeyPress={suspendNomination}
            tooltipText={`Suspend ${candidate}`}
          />
          <ModalConfirmation
            showModal={showModal}
            handleCancelSelect={handleCancelSelect}
            bodyText={`Are you sure you want to **${nominationStatusType}** ${candidate}'s nomination?`}
            handleConfirmSelect={handleConfirmSelect}
            rowId={`${nominationId}`}
            apiIdentifier={`nomination-${nominationStatusType}`}
            refreshCollections={refreshNominations}
            setRefreshCollections={setRefreshNominations}
            setShowModal={setShowModal}
          />
        </>
      )}
    </>
  );
};

AdminNominationCell.propTypes = {
  row: PropTypes.object,
};

export default AdminNominationCell;
