import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const BarChart6 = ({
  dataFirstRow,
  dataSecondRow,
  labelOne,
  labelTwo,
  colorOne = 'rgba(153, 218, 255, 1)',
  colorTwo = 'rgba(0, 162, 255, 1)',
}) => {
  const data = {
    defaultFontFamily: 'Poppins',
    labels: [
      'SC1: Mathematics',
      'SC2: Physics and astronomy',
      'SC3: Chemistry',
      'SC4: Earth and planetary sciences',
      'SC5: Engineering sciences',
      'SC6: Information and communication sciences',
      'SC7: Plant and animal sciences',
      'SC8: Ecology, environment and evolution',
      'SC9: Molecular and cell biology, and human genetics',
      'SC10: Immunology, microbiology and haematology',
      'SC11: Physiology and neuroscience ',
      'SC12: Medicine, dentistry and health sciences',
      'SC13: Interdisciplinary',
    ],
    datasets: [
      {
        label: labelOne,
        backgroundColor: colorOne,
        hoverBackgroundColor: colorOne,
        data: dataFirstRow,
      },
      {
        label: labelTwo,
        backgroundColor: colorTwo,
        hoverBackgroundColor: colorTwo,
        data: dataSecondRow,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={data} height={150} options={options} />;
};

BarChart6.propTypes = {
  dataFirstRow: PropTypes.array,
  dataSecondRow: PropTypes.array,
  labelOne: PropTypes.string,
  labelTwo: PropTypes.string,
  colorOne: PropTypes.string,
  colorTwo: PropTypes.string,
};

export default BarChart6;
