import React from 'react';
import loadable from '@loadable/component';
import { Row } from 'react-bootstrap';

import pMinDelay from 'p-min-delay';

import PageTitle from '../../layouts/PageTitle';
import ChartPie from '../charts/Chartjs/pie';

import CardSummary from '../CardSummary';
import ApexBarThree from '../ApexBarThree';
import ComponentCardHolder from '../ColumnCardHolder';

const ApexBar2 = loadable(() =>
  pMinDelay(import('../charts/apexcharts/Bar2'), 1000),
);
const ApexBar3 = loadable(() =>
  pMinDelay(import('../charts/apexcharts/Bar3'), 1000),
);

const calculatePercentage = (total, number) =>
  `${Math.round((number * 100) / total)}%`;

const NominationReports = () => {
  const continuingNominations = 115;

  const hundredPercent = 100;
  const newNominations = 45;
  // const lastRoundFemaleNominations = 0;

  const nominations = 160;
  const continuingNominationsNotSubmitted = 27;
  const withdrawnContinuingNominations = 12;
  const lastRoundNominations = 193;

  const femaleNominations = 35;
  const newFemaleNominations = 7;

  const maleNominations = 125;

  return (
    <div className="h-80">
      <PageTitle motherMenu="Nominations" activeMenu="Report 2023" />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Total nominations"
          progressBarNumber={nominations}
          description={`${Math.round(
            (nominations * hundredPercent) / lastRoundNominations -
              hundredPercent,
          )}% from 2022`}
        />
        <CardSummary
          title="Continuing nominations"
          spanText={calculatePercentage(nominations, continuingNominations)}
          progressBarNumber={continuingNominations}
          totalNumber={nominations}
          description={`Total: ${nominations}`}
        />
        <CardSummary
          title="New nominations"
          spanText={calculatePercentage(nominations, newNominations)}
          progressBarNumber={newNominations}
          totalNumber={nominations}
          description={`Total: ${nominations}`}
        />

        <CardSummary
          title="Female - Candidates nominated"
          spanText={calculatePercentage(nominations, femaleNominations)}
          progressBarNumber={femaleNominations}
          totalNumber={nominations}
          description={`Total: ${nominations}`}
          barColorClass="bg-primary-light"
          numberOfRows={6}
        />
        <CardSummary
          title="Female - New nominations"
          spanText={calculatePercentage(
            femaleNominations,
            newFemaleNominations,
          )}
          progressBarNumber={newFemaleNominations}
          totalNumber={femaleNominations}
          description={`${Math.round(
            (7 * hundredPercent) / 10 - hundredPercent,
          )}% from 2022`}
          barColorClass="bg-primary-light"
          numberOfRows={6}
        />
        <CardSummary
          title="Continuing nominations not submitted"
          progressBarNumber={continuingNominationsNotSubmitted}
          description={`Compared to ${nominations} submitted`}
          barColorClass="bg-primary-dark"
          numberOfRows={6}
        />
        <CardSummary
          title="Withdrawn continuing nominations"
          spanText={calculatePercentage(
            continuingNominationsNotSubmitted,
            withdrawnContinuingNominations,
          )}
          progressBarNumber={withdrawnContinuingNominations}
          totalNumber={continuingNominationsNotSubmitted}
          description={`Compared to ${nominations} submitted`}
          barColorClass="bg-primary-dark"
          numberOfRows={6}
        />
        <ComponentCardHolder
          title="Nominations"
          component=<ApexBar2
            title="Nominations"
            colors={['#0071b3']}
            xValues={[78, 98, 90, 130, 110, 100, 140, 130, 120, 193, 160]}
            xLabels={[
              2014, 2015, 2016, 2017, 2018, 2018, 2019, 2020, 2021, 2022, 2023,
            ]}
          />
        />
        <ComponentCardHolder title="Recent years" component=<ApexBar3 /> />
        <ComponentCardHolder
          title="Total nominations by gender"
          component=<ChartPie
            pieData={[maleNominations, femaleNominations]}
            labels={['Male', 'Female']}
          />
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Total nominations - Female candidates"
          component=<ApexBarThree
            values={[57, 55, 60, 52, 35]}
            xLabels={['2019', '2022', '2021', '2022', '2023']}
          />
        />
        <ComponentCardHolder
          title="New nominations - Female candidates"
          component=<ApexBarThree
            values={[15, 20, 27, 10, 7]}
            xLabels={['2019', '2022', '2021', '2022', '2023']}
          />
        />
      </Row>
    </div>
  );
};

export default NominationReports;
