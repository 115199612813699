import { arrayToString } from './arrayToString';
import { formatDateDDMMYYYY } from './formatDateDDMMYYYY';

export const tableRowsValuesNominationAdministration = (
  resultData,
  proposers,
  electionTypes,
  nominationStatusType,
  sectionalCommitteesWithCmse,
  fellows,
  nominationState,
  usersArray,
) =>
  resultData.map((item) => {
    const disciplineDescriptorLabels = arrayToString(
      item.attributes.disciplineDescriptor,
    );
    const overlapDisciplineDescriptorLabels = arrayToString(
      item.attributes.overlapDisciplineDescriptor,
    );
    const interdisciplinaryCommitteeLabels = arrayToString(
      item.attributes.interdisciplinaryCommittee,
    );

    let sectionalCommitteeKey = item.attributes.sectionalCommitteeId;
    if (
      item.attributes.electionTypeId &&
      parseInt(item.attributes.electionTypeId) !== 2
    ) {
      sectionalCommitteeKey = 14;
    }

    const currentNominationState = nominationState.find(
      (nominationStateItem) =>
        nominationStateItem?.attributes?.nominationId === item.id,
    );

    const roundYear = currentNominationState?.attributes?.roundYear ?? '';

    const lastConsidered = formatDateDDMMYYYY(
      currentNominationState?.attributes?.lastConsideredDate || '',
    );

    const currentUser =
      typeof item.id === 'string' && item.id.includes('d')
        ? usersArray.find((userItem) => userItem.id === item?.userId)
        : null;

    return {
      nominationId: item.id,
      candidateId: item.attributes.candidateId,
      candidate: item.attributes.candidate,
      proposerName:
        typeof item.id === 'string' && item.id.includes('d')
          ? fellows[currentUser?.fellowId]
          : fellows[proposers[item.id]],
      electionTypeId: electionTypes[item.attributes.electionTypeId],
      nominationStatusType:
        nominationStatusType[item.attributes.nominationStatusTypeId],
      nominationStatusTypeId: item.attributes.nominationStatusTypeId,
      sectionalCommitteeId:
        item.attributes.deprecatedSectionalCommitteeLabel ??
        sectionalCommitteesWithCmse[sectionalCommitteeKey] ??
        '',
      overlapSectionalCommitteeId:
        sectionalCommitteesWithCmse[
          item.attributes.overlapSectionalCommitteeId
        ],
      roundYear,
      lastConsidered,
      overlapDisciplineDescriptor: overlapDisciplineDescriptorLabels,
      disciplineDescriptor: disciplineDescriptorLabels,
      interdisciplinaryCommittee: interdisciplinaryCommitteeLabels,

      createdAt: item.attributes.createdAt,
      updatedAt: item.attributes.publishedAt,
      publishedAt: item.attributes.updatedAt,
    };
  });
