import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { axiosGet } from '../../utils/axiosGet';
import { windowScrollToTop } from '../../utils/windowScrollToTop';
import { axiosDelete } from '../../utils/axiosDelete';
import { axiosPost } from '../../utils/axiosPost';
import { useStaticCollections } from '../../context/StaticCollections';

const AdminUserSelect = ({ setNotificationMessage, type }) => {
  const { users } = useStaticCollections();
  const [existingUsers, setExistingUsers] = useState([]);

  const { control, setValue, handleSubmit } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosGet(`${type}?populate=*`);

        const { data } = response.data;

        const adminUsers = [];
        data.forEach(({ attributes }) => {
          const adminUser = {
            value: attributes.users_permissions_user.data.id,
            label: attributes.users_permissions_user.data.attributes.username,
          };
          adminUsers.push(adminUser);
          setExistingUsers(adminUsers);
        });
        setValue(type, adminUsers);
      } catch (error) {
        console.error('Error fetching existing values:', error);
      }
    };

    fetchData();
  }, [setValue, type]);

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    try {
      const newUsers = formData[type];
      for (const selectedOption of newUsers) {
        // if the relation doesn't exist create a new one
        if (
          !existingUsers.some(
            (existingUser) =>
              existingUser.value === selectedOption.value &&
              existingUser.label === selectedOption.label,
          )
        ) {
          await axiosPost(type, {
            data: {
              users_permissions_user: [selectedOption.value],
            },
          });
        }
      }

      const removedUsers = existingUsers.filter(
        (existingUser) =>
          // Check if the current option in objectA is not in objectB
          !newUsers.some(
            (newUser) =>
              existingUser.value === newUser.value &&
              existingUser.label === newUser.label,
          ),
      );
      for (const userToRemove of removedUsers) {
        const { data } = await axiosGet(
          `${type}?filters[users_permissions_user][id][$eq]=${userToRemove.value}&populate=*`,
        );
        await axiosDelete(`${type}/${data.data[0].id}`);
      }
      setNotificationMessage('Updated');
      setTimeout(() => {
        setNotificationMessage('');
      }, 5000);
      windowScrollToTop();
    } catch (error) {
      console.error('Error updating admins:', error);
    }
  };

  const selectOptions = Object.entries(users).map(([id, label]) => ({
    value: id,
    label,
  }));

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {type === 'admins' ? 'Admin Users' : 'Super Admin Users'}
          </h4>
        </div>
        <div className="card-body text-left">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="mb-xl-5">
                <label htmlFor={type} className="form-label">
                  {type === 'admins' ? 'Admin Users' : 'Super Admin Users'}
                </label>
                <Controller
                  name={type}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={selectOptions}
                      onChange={(selectedOption) => {
                        setValue(type, selectedOption);
                      }}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: `3rem`,
                          borderRadius: '0.5rem',
                          borderColor: '#B3B3B3',
                        }),
                      }}
                      isMulti
                      className="mt-1"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row justify-content-end pt-3">
              <div className="col-auto">
                <Button variant="success" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

AdminUserSelect.propTypes = {
  setNotificationMessage: PropTypes.func,
  type: PropTypes.string,
};
export default AdminUserSelect;
