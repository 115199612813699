export const formatDraftNominationData = async (
  nominationData,
  nominationStatusTypes,
  electionTypes,
  titlesTypes,
  stateTerritories,
  genderTypes,
  australianStatus,
  sectionalCommitteesWithCmse,
) => {
  try {
    if (nominationData) {
      const getSupporterType = (supporterTypeId) => {
        if (!supporterTypeId) {
          return '';
        }
        return parseInt(supporterTypeId) === 1 ? 'Personal' : 'General';
      };

      const getLabelFromJson = (jsonData) => {
        if (jsonData !== null && jsonData !== undefined) {
          if (Array.isArray(jsonData)) {
            const labelsString = jsonData.map((item) => item.label).join(', ');
            return labelsString;
          }
        }
        return null;
      };

      const getNonAasRefereeLabel = (referee) => {
        const refereeId = nominationData[`nonAasReferee${referee}Id`];
        const titleId = nominationData[`referee${referee}TitleId`] || null;
        const title = titlesTypes[titleId] || '';
        const firstName = nominationData[`referee${referee}Name`];
        const lastName = nominationData[`referee${referee}LastName`];
        const email = nominationData[`referee${referee}Email`];

        if (refereeId?.value === 0) {
          return `${title} ${firstName} ${lastName} (${email})`;
        }
        return refereeId?.label || '';
      };

      const nominationFormData = {
        nominationStatus: 'Draft(Unvalidated)',
        electionType: electionTypes[nominationData.electionTypeId],

        title: titlesTypes[nominationData.titleId],

        name:
          typeof nominationData?.name === 'string' ? nominationData.name : null,

        lastName:
          typeof nominationData?.lastName === 'string'
            ? nominationData.lastName
            : null,

        phone:
          typeof nominationData?.phone === 'string'
            ? nominationData.phone
            : null,

        address:
          typeof nominationData?.address === 'string'
            ? nominationData.address
            : null,

        email:
          typeof nominationData?.email === 'string'
            ? nominationData.email
            : null,

        stateTerritory: stateTerritories[nominationData?.stateTerritoryId],

        gender: genderTypes[nominationData?.genderId],

        otherGender:
          typeof nominationData?.otherGender === 'string'
            ? nominationData.otherGender
            : null,

        dob:
          typeof nominationData?.dob === 'string' ? nominationData.dob : null,

        countryOfBirth: nominationData?.countryOfBirthId?.label,

        australianStatus: australianStatus[nominationData.australianStatusId],

        postNominal:
          typeof nominationData?.postNominal === 'string'
            ? nominationData.postNominal
            : null,

        jobTitle:
          typeof nominationData?.jobTitle === 'string'
            ? nominationData.jobTitle
            : null,

        organisation: nominationData?.organisationId.label,
        otherOrganisation:
          typeof nominationData?.otherOrganisation === 'string'
            ? nominationData.otherOrganisation
            : null,

        phdAwardedYear: nominationData.phdAwardedYear,
        noPhd: !nominationData.phdAwardedYear,

        personalInformation:
          typeof nominationData?.personalInformation === 'string'
            ? nominationData.personalInformation
            : null,

        diverseBackground:
          typeof nominationData?.diverseBackground === 'boolean' &&
          nominationData?.diverseBackground
            ? 1
            : 0,

        nominationConsentFilePath:
          nominationData?.nominationConsentFilePath || '',

        retractedPapers: !!nominationData.retractedPapers,

        correctedPapers: !!nominationData.correctedPapers,

        publicationConcerns: !!nominationData.publicationConcerns,

        disclosureDeclarationFilePath:
          nominationData?.disclosureDeclarationFilePath || '',

        nationalityId: getLabelFromJson(nominationData?.nationalityId),

        emailEducationalInstitution: '',

        arrivalDate: nominationData?.arrivalDate,

        careerInterruption: !!nominationData.careerInterruption,

        scientificExcellence: nominationData.scientificExcellence,

        citation: nominationData?.citation ? nominationData.citation : '',

        sectionalCommittee:
          sectionalCommitteesWithCmse[nominationData.sectionalCommitteeId],

        deprecatedSectionalCommitteeLabel:
          nominationData.deprecatedSectionalCommitteeLabel ?? null,

        disciplineDescriptor: getLabelFromJson(
          nominationData.disciplineDescriptor,
        ),
        overlapCommittee: !!nominationData.overlapCommittee,

        overlapSectionalCommitteeId:
          sectionalCommitteesWithCmse[
            nominationData.overlapSectionalCommitteeId
          ],

        overlapDisciplineDescriptor: getLabelFromJson(
          nominationData.overlapDisciplineDescriptor,
        ),

        interdisciplinaryCommittee: getLabelFromJson(
          nominationData.interdisciplinaryCommittee,
        ),

        proposerFellow: nominationData.proposerFellowId?.label,

        proposerSignatureFilePath: nominationData.proposerSignatureFilePath
          ? nominationData.proposerSignatureFilePath
          : '',

        seconderFellow: nominationData.seconderFellowId?.label,

        seconderSignatureFilePath: nominationData.seconderSignatureFilePath
          ? nominationData.seconderSignatureFilePath
          : '',

        supporterOneTypeId: getSupporterType(
          nominationData?.supporterOneTypeId,
        ),
        supporterOneFellow: nominationData?.supporterOneFellowId?.label,

        supporterOneSignatureFilePath:
          nominationData.supporterOneSignatureFilePath || '',

        supporterTwoTypeId: getSupporterType(
          nominationData?.supporterTwoTypeId,
        ),
        supporterTwoFellow: nominationData?.supporterTwoFellowId?.label,

        supporterTwoSignatureFileName:
          nominationData.supporterTwoSignatureFilePath || '',

        supporterThreeTypeId: getSupporterType(
          nominationData?.supporterTwoTypeId,
        ),
        supporterThreeFellow: nominationData?.supporterThreeFellowId?.label,

        supporterThreeSignatureFileName:
          nominationData.supporterThreeSignatureFilePath || '',

        supporterFourTypeId: getSupporterType(
          nominationData?.supporterFourTypeId,
        ),
        supporterFourFellow: nominationData?.supporterFourFellowId?.label,

        supporterFourSignatureFileName:
          nominationData.supporterFourSignatureFilePath || '',

        meetsConduct: !!nominationData?.meetsConduct,

        extendedCitationFilePath: nominationData.extendedCitationFilePath
          ? nominationData.extendedCitationFilePath
          : '',

        curriculumVitaeFilePath: nominationData.curriculumVitaeFilePath
          ? nominationData.curriculumVitaeFilePath
          : '',

        significantPublicationsFilePath:
          nominationData.significantPublicationsFilePath
            ? nominationData.significantPublicationsFilePath
            : '',

        publicationsFilePath: nominationData.publicationsFilePath
          ? nominationData.publicationsFilePath
          : '',

        refereeOne: nominationData?.refereeOne,
        refereeTwo: nominationData?.refereeTwo,
        refereeThree: nominationData?.refereeThree,
        refereeFour: nominationData?.refereeFour,
        refereeFive: nominationData?.refereeFive,
        refereeSix: nominationData?.refereeSix,
        refereeSeven: nominationData?.refereeSeven,
        refereeEight: nominationData?.refereeEight,
        refereeNine: nominationData?.refereeNine,

        refereeOneFellow: nominationData.refereeOneFellowId?.label,
        refereeTwoFellow: nominationData?.refereeTwoFellowId?.label,
        refereeThreeFellow: nominationData?.refereeThreeFellowId?.label,
        refereeFourFellow: nominationData?.refereeFourFellowId?.label,
        refereeFiveFellow: nominationData?.refereeFiveFellowId?.label,
        refereeSixFellow: nominationData?.refereeSixFellowId?.label,
        refereeSevenFellow: nominationData?.refereeSevenFellowId?.label,
        refereeEightFellow: nominationData?.refereeEightFellowId?.label,
        refereeNineFellow: nominationData?.refereeNineFellowId?.label,

        nonAasRefereeOne: getNonAasRefereeLabel('One'),
        nonAasRefereeTwo: getNonAasRefereeLabel('Two'),
        nonAasRefereeThree: getNonAasRefereeLabel('Three'),
        nonAasRefereeFour: getNonAasRefereeLabel('Four'),
        nonAasRefereeFive: getNonAasRefereeLabel('Five'),
        nonAasRefereeSix: getNonAasRefereeLabel('Six'),
        nonAasRefereeSeven: getNonAasRefereeLabel('Seven'),
        nonAasRefereeEight: getNonAasRefereeLabel('Eight'),
        nonAasRefereeNine: getNonAasRefereeLabel('Nine'),

        nonPreferredReferee: nominationData?.nonPreferredReferee ?? null,
      };
      return nominationFormData;
    }
    console.log('no response');
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
