import React, { useContext, useEffect, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
  useSortBy,
} from 'react-table';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './filtering.css';
import GlobalFilter from './GlobalFilter';
import { fetchData } from '../../../../utils/fetchData';
import { formatDate } from '../../../../utils/formatDate';
import Markdown from '../../Forms/Elements/Markdown';
import { CommonCollections } from '../../../../App';
import ButtonIcon from '../../Forms/Elements/ButtonIcon';

const FilteringTable = ({
  columns,
  data,
  markDownName,
  componentName,
  urlString = '',
  buttonText = '',
  filter = true,
  title = 'Table Filtering',
}) => {
  const { setNominationId } = useContext(CommonCollections);
  const now = new Date();
  const fiveMinutesAgo = new Date(now - 5 * 60 * 1000);

  const [pageFields, setPageFields] = useState([]);
  const [filterChanged, setFilterChanged] = useState(false);

  useEffect(() => {
    fetchData(
      `markdowns?filters[name][$eq]=${markDownName}&populate=*`,
      setPageFields,
    );
  }, [markDownName]);
  const bodyText = pageFields[0] ? pageFields[0].attributes.content : null;

  const [pageSize, setPageSize] = useState(
    () => parseInt(localStorage.getItem(`${componentName}PageSize`)) || 10,
  );
  const [pageIndex, setPageIndex] = useState(
    () => parseInt(localStorage.getItem(`${componentName}PageIndex`)) || 0,
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      enableRowActions: true,
      initialState: {
        pageIndex,
        pageSize,
        filters:
          JSON.parse(localStorage.getItem(`${componentName}Filters`)) || [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    setPageSize: setTablePageSize,
    rows,
  } = tableInstance;

  useEffect(() => {
    setPageIndex(state.pageIndex);
    localStorage.setItem(`${componentName}PageIndex`, state.pageIndex);
  }, [componentName, state.pageIndex]);

  useEffect(() => {
    localStorage.setItem(`${componentName}PageSize`, pageSize);
  }, [componentName, pageSize]);

  useEffect(() => {
    const { filters } = state;
    localStorage.setItem(`${componentName}Filters`, JSON.stringify(filters));
  }, [componentName, state]);

  useEffect(() => {
    setFilterChanged(true);
  }, [state.filters, state.globalFilter]);

  useEffect(() => {
    if (filterChanged) {
      setFilterChanged(false);

      if (state.pageIndex !== 0) {
        gotoPage(0);
      }
    }
  }, [
    filterChanged,
    state.filters,
    state.globalFilter,
    state.pageIndex,
    gotoPage,
  ]);

  const { globalFilter } = state;

  const csvHeaders = columns.map((col) => ({
    label: col.Header,
    key: col.accessor,
  }));
  const csvData = rows.map((row) => {
    const csvRow = {};
    columns.forEach((col) => {
      csvRow[col.accessor] = row.original[col.accessor];
    });
    return csvRow;
  });

  const currentFilters = JSON.parse(
    localStorage.getItem(`${componentName}Filters`),
  );

  const ids = Array.isArray(currentFilters)
    ? currentFilters.map((filterItem) => filterItem.id)
    : [];
  const idsString = ids.join(', ');

  return (
    <>
      {markDownName && (
        <div className="card">
          <div className="card-body">
            <Markdown className="col-xl-12 col-lg-12 mt-3" text={bodyText} />
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
          {urlString !== '' && (
            <Link to={`/${urlString}`} onClick={() => setNominationId(null)}>
              <button type="button" className="btn btn-success">
                <span className="btn-icon-start text-info">
                  <i className="fa fa-plus color-info" />
                </span>
                {buttonText}
              </button>
            </Link>
          )}
        </div>

        <div className="card-body">
          {idsString && (
            <div className="mb-xl-5">
              <h5 className="card-title text-capitalize">
                Filters{' '}
                <ButtonIcon
                  bootstrapIcon="bi-arrow-counterclockwise"
                  backgroundColor="bg-success"
                  handleClick={() => {
                    setGlobalFilter('');
                    tableInstance.setAllFilters([]);
                    localStorage.removeItem(`${componentName}Filters`);
                  }}
                  handleKeyPress={() => {
                    setGlobalFilter('');
                    tableInstance.setAllFilters([]);
                    localStorage.removeItem(`${componentName}Filters`);
                  }}
                  tooltipText="Reset filters"
                />
              </h5>

              <p className="card-text">
                <span>{idsString}</span>
              </p>
            </div>
          )}

          <div className="table-responsive">
            <div className="row mb-xl-3">
              {filter ? (
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              ) : null}

              <div className="col-md-6 d-flex flex-column justify-content-end align-items-end">
                <div className="mb-xl-5">
                  <CSVLink
                    headers={csvHeaders}
                    data={csvData}
                    filename="table_data.csv"
                    className="btn btn-outline-primary"
                  >
                    <i className="bi bi-file-earmark-arrow-down mr-2" /> CSV
                  </CSVLink>
                </div>

                <div className="d-flex align-items-center">
                  <span className="mr-2">Page Size :{'  '}</span>
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      const newSize = Number(e.target.value);
                      setPageSize(newSize);
                      setTablePageSize(newSize);
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <table
              {...getTableProps()}
              className="table table-striped table-bordered dataTable display"
            >
              <thead className="thead-dark">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      let tableHeaderClassName = '';

                      switch (column.id) {
                        case 'createdAt':
                        case 'id':
                        case 'nominationId':
                        case 'nominationStatusTypeId':
                        case 'publishedAt':
                        case 'updatedAt':
                          tableHeaderClassName = 'd-none';
                          break;
                        default:
                          tableHeaderClassName = '';
                          break;
                      }

                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          className={tableHeaderClassName}
                        >
                          {column.render('Header')}
                          {(() => {
                            if (column.isSorted) {
                              return column.isSortedDesc ? ' 🔽' : ' 🔼';
                            }
                            return '';
                          })()}
                          {filter && column.canFilter ? (
                            <div
                              tabIndex="0"
                              role="button"
                              onClick={(e) => e.stopPropagation()}
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              {column.render('Filter')}
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, columnIndex) => {
                        let cellClassName = '';
                        switch (cell.column.id) {
                          case 'actions':
                            cellClassName = 'td-center';
                            break;
                          case 'createdAt':
                          case 'id':
                          case 'nominationId':
                          case 'nominationStatusTypeId':
                          case 'publishedAt':
                          case 'updatedAt':
                            cellClassName = 'd-none';
                            break;
                          default:
                            cellClassName = '';
                            break;
                        }

                        return (
                          <td
                            className={cellClassName}
                            {...cell.getCellProps()}
                          >
                            {Object.values(row.values).length - 1 ===
                              columnIndex &&
                            row.values.publishedAt !== undefined
                              ? formatDate(row.values.publishedAt)
                              : cell.render('Cell')}{' '}
                            {columnIndex === 1 &&
                              new Date(row.values.publishedAt) >
                                fiveMinutesAgo &&
                              new Date(row.values.publishedAt) <= now && (
                                <span className="updated-span">Updated</span>
                              )}
                            {columnIndex === 1 &&
                              row?.original?.refereeArchived && (
                                <span className="archived-span">Archived</span>
                              )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {pageOptions.length > 1 && (
              <>
                <div className="d-flex justify-content-between">
                  <span>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <span className="table-index">
                    Go to page :
                    <input
                      type="number"
                      className="ml-2"
                      defaultValue={pageIndex + 1}
                      onChange={(e) => {
                        const pageNumber = e.target.value
                          ? Number(e.target.value) - 1
                          : 0;
                        gotoPage(pageNumber);
                      }}
                    />
                  </span>
                </div>
                <div className="text-center mb-3">
                  <div className="filter-pagination mt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    >
                      {'<<'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      {'<'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      {'>'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    >
                      {'>>'}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FilteringTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  componentName: PropTypes.string,
  markDownName: PropTypes.string,
  urlString: PropTypes.string,
  buttonText: PropTypes.string,
  filter: PropTypes.bool,
  title: PropTypes.string,
};

FilteringTable.defaultProps = {
  markDownName: '',
  urlString: '',
  buttonText: '',
  filter: true,
  title: 'Table Filtering',
};

export default FilteringTable;
